import React from "react";

export const MiniLogo = () => {
    return (
        <svg
            width="48"
            height="38"
            viewBox="0 0 48 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M46.0138 37.9999H3.81269C2.79958 37.9999 1.97852 37.1689 1.97852 36.1436V11.8999C1.97852 11.8999 32.5773 11.8999 40.1983 11.8999C46.6821 11.8999 47.848 6.33093 47.848 6.33093V36.1451C47.848 37.1689 47.0269 37.9999 46.0138 37.9999Z"
                fill="#F15A27"
            />
            <path
                d="M47.848 10.6343V6.32947C47.848 6.32947 46.6821 11.8984 40.1983 11.8984C32.5773 11.8984 1.97852 11.8984 1.97852 11.8984V15.9584C1.97852 15.9584 32.9704 15.9584 40.351 15.9584C47.73 15.9599 47.848 10.6343 47.848 10.6343Z"
                fill="#E24F29"
            />
            <path
                d="M31.4456 0.113244C31.2187 0.113244 31.0176 0.243325 30.9073 0.44227C29.8337 2.38887 27.9133 3.73863 24.4536 3.73863C20.9939 3.73863 19.0736 2.38734 17.9985 0.44227C17.8881 0.241795 17.6855 0.113244 17.4602 0.113244H1.83417C0.821069 0.113244 0 0.944223 0 1.96955V10.5196C0 10.5196 31.2218 10.5196 39.4975 10.5196C47.7732 10.5196 48 0 48 0V0.113244H31.4456Z"
                fill="#F15A27"
            />
            <path
                d="M35.1833 27.1361C34.4606 27.5095 32.2121 28.5624 29.2756 28.8593C28.9626 28.8899 28.5936 28.9052 28.1823 28.9052C24.5246 28.9052 17.4177 27.7743 15.3991 27.4391L15.3008 29.1332C15.3008 29.1332 15.4142 29.5311 15.5155 29.5051C15.6168 29.4791 16.3396 29.8249 16.3396 29.8249L19.5316 30.056L24.827 30.1968L28.1082 30.2611C28.1082 30.2611 31.1234 29.9795 31.1869 29.9657C31.2504 29.9535 33.9238 29.1577 33.9994 29.1194C34.075 29.0812 34.9369 28.5685 35.0261 28.5165C35.1153 28.4644 35.457 27.7345 35.457 27.7345L35.1833 27.1361Z"
                fill="white"
            />
            <path
                d="M34.8734 26.2362L34.2655 25.5185L30.579 24.9538L30.136 24.7227L29.7171 24.5161L29.1349 24.3753L28.5271 23.7601L28.1717 23.4142L27.8164 23.2597L27.4112 23.324C27.4112 23.324 25.8779 21.8487 25.8144 21.8885C25.7509 21.9267 23.6869 20.9795 23.764 20.9795C23.8396 20.9795 23.5629 19.3466 23.522 19.2594C22.9021 17.9524 22.2428 17.7795 22.2927 17.8071C22.3426 17.8346 21.5079 17.6816 21.4943 17.6938C21.4822 17.7061 19.1128 18.4498 19.2141 18.4498C19.3154 18.4498 16.9596 18.7696 16.9596 18.7696C16.9596 18.7436 16.9596 19.0007 16.9596 19.0007L16.6677 19.1033L16.3517 20.7315L16.2126 20.8341V21.1294L16.0357 21.7829V22.3216L15.8209 22.2451V22.8098L15.7453 23.1434H15.6183C15.6183 23.1434 15.4157 25.1695 15.4278 25.1313C15.4399 25.093 15.3008 25.7985 15.3008 26.4137C15.3008 26.5407 15.3053 26.6525 15.3129 26.7565C17.1622 27.0641 26.1546 28.5134 29.2121 28.2027C32.135 27.9073 34.3955 26.807 34.9671 26.5071L34.8734 26.2362ZM20.885 20.1179C20.5251 20.1179 20.2348 19.8225 20.2348 19.4598C20.2348 19.0956 20.5266 18.8018 20.885 18.8018C21.2433 18.8018 21.5352 19.0971 21.5352 19.4598C21.5352 19.8225 21.2448 20.1179 20.885 20.1179Z"
                fill="white"
            />
        </svg>
    );
};
