export const headList = [
    {
        code: "name",
        title: "Name",
        mobileHide: false,
        isNumeric: false
    },
    {
        code: "eventId",
        title: "Event ID",
        mobileHide: false,
        isNumeric: false
    },
    {
        code: "createdAt",
        title: "Created",
        mobileHide: true,
        isNumeric: true,
        type: "date"
    }
];
