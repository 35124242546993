import React from "react";
import "./TeamMapping.scss";

export const TeamMapping = ({ id, mapping }) => {

    if (!mapping) return <br/>
    
    const list = [];
    for(const name of Object.keys(mapping)) {
        if (mapping[name] === id) 
            list.push(name);
    }

    return (
        <div className={`col`}>        
            <div className={`TeamMapping`}>
                {(list && list.length) ? list.map((item, i) => (
                    <div key={`${i}-${item}`} className="MappingItem">
                        "{item}"
                    </div>
                )) : (
                    <p>Empty</p>
                )}
            </div>
        </div>
    );
};