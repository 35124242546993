import React from "react";
import "./Checkbox.scss";
import { Icon } from "components/common/Icons/Icon";

const Checkbox = ({
    inputData,
    onChange,
    onKeyPress,
    className,
    focus,
    code,
    defaultValue,
    onClick
}) => {
    const { id, name, value, checked } = inputData;

    const handleChange = ({ target: { value, checked } }) => {
        onChange && onChange(value, checked, inputData);
    };

    //useEffect(() => {
    //    if (focus) {
    //        document.getElementById(id)?.focus();
    //    }
    //});

    const isSimpleSelect =
        className && className === "simple-select" ? true : false;

    return (
        <span className={`Checkbox ${className || ""}`}>
            <input
                id={id}
                name={name}
                value={value}
                checked={checked}
                type="checkbox"
                className="Checkbox-input"
                onKeyPress={onKeyPress}
                onChange={handleChange}
                onClick={onClick || null}
                data-key={code || ""}
                data-default={defaultValue || ""}
            />
            <label htmlFor={id} className="Checkbox-label">
                {!isSimpleSelect ? (
                    <Icon name={checked ? "check" : "unchecked"} />
                ) : checked ? (
                    <Icon name="checkMark" />
                ) : null}
                <span className="Checkbox-label-name">{name}</span>
            </label>
        </span>
    );
};

export default Checkbox;
