import React from "react";

export const Calendar = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 5.25H16.75V4C16.75 3.58579 17.0858 3.25 17.5 3.25C17.9142 3.25 18.25 3.58579 18.25 4V5.26121C19.6516 5.38752 20.75 6.56549 20.75 8V9.75V11.25V18C20.75 19.5188 19.5188 20.75 18 20.75H6C4.48122 20.75 3.25 19.5188 3.25 18V11.25V9.75V8C3.25 6.56549 4.34837 5.38752 5.75 5.26121V4C5.75 3.58579 6.08579 3.25 6.5 3.25C6.91421 3.25 7.25 3.58579 7.25 4V5.25ZM6.5 6.75H6C5.30964 6.75 4.75 7.30964 4.75 8V9.75H19.25V8C19.25 7.30964 18.6904 6.75 18 6.75H17.5H6.5ZM4.75 11.25V18C4.75 18.6904 5.30964 19.25 6 19.25H18C18.6904 19.25 19.25 18.6904 19.25 18V11.25H4.75Z"
        fill="white"
      />
    </svg>
  );
};
