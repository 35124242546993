import React from "react";

export const Jig = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 17.125H3.16667C1.90101 17.125 0.875 16.099 0.875 14.8333V6.5C0.875 5.23435 1.90101 4.20833 3.16667 4.20833H4.20833V3.04762C4.20833 1.84771 5.18105 0.875 6.38095 0.875H14C15.7259 0.875 17.125 2.27411 17.125 4V11.619C17.125 12.819 16.1523 13.7917 14.9524 13.7917H13.7917V14.8333C13.7917 16.099 12.7657 17.125 11.5 17.125ZM11.5 4.20833H5.45833V3.04762C5.45833 2.53807 5.8714 2.125 6.38095 2.125H14C15.0355 2.125 15.875 2.96447 15.875 4V11.619C15.875 12.1286 15.4619 12.5417 14.9524 12.5417H13.7917V6.5C13.7917 5.23435 12.7657 4.20833 11.5 4.20833ZM11.5 15.875C12.0753 15.875 12.5417 15.4086 12.5417 14.8333V6.5C12.5417 5.9247 12.0753 5.45833 11.5 5.45833H3.16667C2.59137 5.45833 2.125 5.9247 2.125 6.5V14.8333C2.125 15.4086 2.59137 15.875 3.16667 15.875H11.5ZM8.375 8.58333H6.29167C5.94649 8.58333 5.66667 8.30351 5.66667 7.95833C5.66667 7.61316 5.94649 7.33333 6.29167 7.33333H10.4583C10.8035 7.33333 11.0833 7.61316 11.0833 7.95833C11.0833 8.30351 10.8035 8.58333 10.4583 8.58333H9.625V12.75C9.625 13.5554 8.97208 14.2083 8.16667 14.2083H6.5C5.69458 14.2083 5.04167 13.5554 5.04167 12.75V12.3333C5.04167 11.9882 5.32149 11.7083 5.66667 11.7083C6.01184 11.7083 6.29167 11.9882 6.29167 12.3333V12.75C6.29167 12.8651 6.38494 12.9583 6.5 12.9583H8.16667C8.28173 12.9583 8.375 12.8651 8.375 12.75V8.58333Z"
        fill="white"
      />
    </svg>
  );
};
