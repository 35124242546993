import React from "react";

export const Search = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 16.75C18.5041 16.75 21.75 13.5041 21.75 9.5C21.75 5.49594 18.5041 2.25 14.5 2.25C10.4959 2.25 7.25 5.49594 7.25 9.5C7.25 11.2319 7.85727 12.8219 8.87048 14.0689L3.46967 19.4697C3.17678 19.7626 3.17678 20.2374 3.46967 20.5303C3.76256 20.8232 4.23744 20.8232 4.53033 20.5303L9.93114 15.1295C11.1781 16.1427 12.7681 16.75 14.5 16.75ZM14.5 15.25C11.3244 15.25 8.75 12.6756 8.75 9.5C8.75 6.32436 11.3244 3.75 14.5 3.75C17.6756 3.75 20.25 6.32436 20.25 9.5C20.25 12.6756 17.6756 15.25 14.5 15.25Z" 
        fill="#C8C8C8"
      />
    </svg>
  );
};
