import React from "react";
import { NoResults } from "components/common/NoResults";
//import * as moment from "moment-timezone";
import { Icon } from "components/common/Icons/Icon";
import "./TeamData.scss";

export const TeamData = ({ team, fields, isComparison, handleClick=()=>{} }) => {
    
    const firstColumnCount = ~~(fields.length / 2);
    const col1Keys = fields.slice(0, firstColumnCount);
    const col2Keys = fields.slice(firstColumnCount);

    return (
        <div className="row TeamData">
            {fields.length ? (
                <>
                    <RenderColumn keys={col1Keys} object={team} isComparison={isComparison} />
                    {!!col2Keys.length && (
                        <RenderColumn keys={col2Keys} object={team} isComparison={isComparison} />
                    )}
                    <div className="TeamMergeBtn">
                        <div onClick={handleClick} title="Remove duplicate">
                            <Icon name="delete" />
                        </div>
                    </div>
                </>
            ) : (
                <NoResults label="Empty" height="100px" />
            )}
        </div>
    );
};

const RenderColumn = ({ keys, object, isComparison }) => {
    return (
        <div
            className={`col${isComparison ? "" : " col-sm-6"}`}
            style={{ padding: "0" }}
        >
            {keys.map((key, j) => {
                return (
                    <div key={`${object.id}-misc-${key}-${j}-${isComparison}`}>
                        <div className="TeamData-segment">
                            {!isComparison && (<div>{key}</div>)}
                            <div>{object[key] ? object[key] : "-"}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
