import React from "react";
import "./UserBets.scss";
import { NoResults } from "components/common/NoResults";

export const UserBets = ({ 
    activeBets,
    archiveBets,
    activeParlays,
    archiveParlays,
    activeTeasers,
    archiveTeasers,
    title }) => {


    const RenderBets = ({ bets, label, betType}) => {
        if (!bets) {
            return (
                <div className="row">
                <h4>{label} - 0</h4>
                <NoResults label="Empty" height="100px" />
                </div>
            )
        }
        return (
            <div className="row">
            <h4>{label} ({bets.length})</h4>
            {
                bets.map((bet, j) => {
                    return(
                        <div key={j} className="UserBets-segment">
                            <div>{betType}</div>
                            <div>{bet.id || bet.documentID}</div>                                           
                        </div>
                    );
                })
            }
            <br></br>
            </div> 
        )
    }

    return (
        <div className="row UserBets">
            <h1 className={"adjusted"}>{title}</h1>
            <div className={"col col-sm-6"}>
                <h3>Active</h3>
                <RenderBets bets={activeBets} label="Bets" betType="bet"/>
                <RenderBets bets={activeParlays} label="Parlays" betType="parlay"/>
                <RenderBets bets={activeTeasers} label="Teasers" betType="teaser"/>
            </div>

            <div className={"col col-sm-6"}>
                <h3>Archive</h3>
                <RenderBets bets={archiveBets} label="Bets" betType="bet"/>
                <RenderBets bets={archiveParlays} label="Parlays" betType="parlay"/>
                <RenderBets bets={archiveTeasers} label="Teasers" betType="teaser"/>
            </div>
        </div>
    )
}


