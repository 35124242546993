import React from "react";

export const Address = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3.25H18C19.5188 3.25 20.75 4.48122 20.75 6V10H21C21.5523 10 22 10.4477 22 11V13C22 13.5523 21.5523 14 21 14H20.75V18C20.75 19.5188 19.5188 20.75 18 20.75H6C4.48122 20.75 3.25 19.5188 3.25 18V15.5H2.75C2.33579 15.5 2 15.1642 2 14.75C2 14.3358 2.33579 14 2.75 14H3.25V12.5H2.75C2.33579 12.5 2 12.1642 2 11.75C2 11.3358 2.33579 11 2.75 11H3.25V9.5H2.75C2.33579 9.5 2 9.16421 2 8.75C2 8.33579 2.33579 8 2.75 8H3.25V6C3.25 4.48122 4.48122 3.25 6 3.25ZM4.75 9.5H5.25C5.66421 9.5 6 9.16421 6 8.75C6 8.33579 5.66421 8 5.25 8H4.75V6C4.75 5.30964 5.30964 4.75 6 4.75H8V19.25H6C5.30964 19.25 4.75 18.6904 4.75 18V15.5H5.25C5.66421 15.5 6 15.1642 6 14.75C6 14.3358 5.66421 14 5.25 14H4.75V12.5H5.25C5.66421 12.5 6 12.1642 6 11.75C6 11.3358 5.66421 11 5.25 11H4.75V9.5ZM19.25 10H19C18.4477 10 18 10.4477 18 11V13C18 13.5523 18.4477 14 19 14H19.25V18C19.25 18.6904 18.6904 19.25 18 19.25H9.5V4.75H18C18.6904 4.75 19.25 5.30964 19.25 6V10Z"
        fill="#999999"
      />
    </svg>
  );
};
