import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { LayoutPage } from "layouts/LayoutPage";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Spinner } from "components/common/Spinner/Spinner";
import "./PickemPage.scss";

const PickemPage = ({ history, location }) => {

    const { functions } = useContext(AuthContext);
    const { updateMeta, showSpinner } = useContext(StoreContext);
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        updateMeta({
            title: "Pickem Page",
        });
    }, []);
    
    

    const handleRunTournament = () => {
        setIsLoading(true)
        showSpinner(true);
        functions
            .httpsCallable("pickemRunTournamentSchedule")()
            .then((res) => {
                setIsLoading(false)
                showSpinner(false);
                if (res.data.success === true) {
                    console.log("Successful Tournament Schedule")
                }
            })
            .catch((err) => {
                setIsLoading(false)
                showSpinner(false);
                console.log(err);
            });
    };

    return (
        <LayoutPage
            subtitle={
                <Breadcrumbs
                    location={location}
                    currentPage={
                        <div style={{display: "flex", fontSize: "1em"}}>
                            <h2>Run Tournament Schedule Function</h2>
                            <button style={{fontSize: "1em", marginLeft: ".5em"}}
                                className="btn-primary"
                                onClick={handleRunTournament}
                            >
                                Run
                            </button>
                        </div>
                    }
                />
                
            }
        >

        <div>
            {isLoading ? <Spinner></Spinner> : null}
        </div>  
            
        </LayoutPage>
    );
};

export default PickemPage;
