import "./withDialog.scss";
import { Icon } from "components/common/Icons/Icon";
import React, { Component } from "react";

export const withDialog = WrappedComponent => {
    return class extends Component {
        constructor(props) {
            super(props);

            this.state = {
                modalActive: false,
                modalContent: "",
                options: {}
            };

            this.sizes = {
                xs: "25rem",
                sm: "480px",
                md: "768px",
                lg: "992px",
                xl: "1200px"
            };

            this.openModal = this.openModal.bind(this);
            this.closeModal = this.closeModal.bind(this);
        }

        openModal(content, options = {}) {
            document.body.style.overflow = "hidden";

            const size = options.size || "xs";

            this.setState({
                modalActive: true,
                modalContent: content,
                maxWidth: this.sizes[size]
            });
        }

        closeModal(e) {
            document.body.style.overflow = "initial";

            this.setState({
                modalActive: false
            });
        }

        render() {
            const { modalActive, modalContent, maxWidth } = this.state;

            const classList = ["withDialog"];

            if (modalActive) classList.push("active");

            return (
                <>
                    <div className={classList.join(" ")}>
                        <div
                            className="withDialog-shadow"
                            onClick={this.closeModal}
                        />
                        <div className="content" style={{ maxWidth }}>
                            <span
                                className="closeButton"
                                onClick={this.closeModal}
                            >
                                <Icon name="close" />
                            </span>

                            {modalContent.title && (
                                <h2>{modalContent.title}</h2>
                            )}
                            {modalContent.subtitle && (
                                <div>{modalContent.subtitle}</div>
                            )}

                            {modalContent.content}
                        </div>
                    </div>
                    <WrappedComponent
                        isActive={modalActive}
                        openModal={this.openModal}
                        closeModal={this.closeModal}
                        setModalContent={this.setModalContent}
                        {...this.props}
                    />
                </>
            );
        }
    };
};
