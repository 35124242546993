import React from "react";

export const Rate = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.875 6.66659V4.99992C1.875 4.65474 2.15482 4.37492 2.5 4.37492H4.44781L4.37659 3.37778C4.35075 3.01599 4.63729 2.70825 5 2.70825H15C15.3627 2.70825 15.6493 3.01599 15.6234 3.37778L15.5522 4.37492H17.5001C17.8452 4.37492 18.1251 4.65474 18.1251 4.99992V6.66658C18.1251 8.58735 16.75 10.2112 14.8994 10.5572C14.2257 12.3653 12.5857 13.6871 10.625 13.9212V16.4493L10.6249 16.4583H12.5C12.8452 16.4583 13.125 16.7381 13.125 17.0833C13.125 17.4284 12.8452 17.7083 12.5 17.7083H7.5C7.15482 17.7083 6.875 17.4284 6.875 17.0833C6.875 16.7381 7.15482 16.4583 7.5 16.4583H9.37506L9.375 16.4493V13.9212C7.41431 13.6871 5.77427 12.3653 5.10065 10.5572C3.25048 10.2112 1.875 8.58771 1.875 6.66659ZM15.2095 9.16733C16.1928 8.75718 16.8751 7.78533 16.8751 6.66658V5.62492H15.4629L15.2145 9.10296C15.2129 9.12445 15.2113 9.14591 15.2095 9.16733ZM6.03232 9.0139C6.18101 11.0955 7.91309 12.7083 9.99997 12.7083C12.0869 12.7083 13.8189 11.0955 13.9676 9.0139L14.3287 3.95825H5.67121L6.03232 9.0139ZM3.125 6.66659V5.62492H4.5109L4.75352 9.15154C3.79028 8.73268 3.125 7.7714 3.125 6.66659Z"
        fill="#999999"
      />
    </svg>
  );
};
