import React from "react";
import { Calendar } from "./icons/Calendar";
import { Tasks } from "./icons/Tasks";
import { Logo } from "./icons/Logo";
import { Bell } from "./icons/Bell";
import { Address } from "./icons/Address";
import { Payments } from "./icons/Payments";
import { Proxy } from "./icons/Proxy";
import { Account } from "./icons/Account";
import { Analytics } from "./icons/Analytics";
import { PhantomBot } from "./icons/PhantomBot";
import { TSBbot } from "./icons/TSBbot";
import { NSBbot } from "./icons/NSBbot";
import { Cookout } from "./icons/Cookout";
import { Support } from "./icons/Support";
import { History } from "./icons/History";
import { Rate } from "./icons/Rate";
import { Chevron } from "./icons/Chevron";
import { Jig } from "./icons/Jig";
import { Edit } from "./icons/Edit";
import { Delete } from "./icons/Delete";
import { Dublicate } from "./icons/Dublicate";
import { Stamp } from "./icons/Stamp";
import { Chip } from "./icons/Chip";
import { CreditCardLogo } from "./icons/CreditCardLogo";
import { Search } from "./icons/Search";
import { List } from "./icons/List";
import { Close } from "./icons/Close";
import { Settings } from "./icons/Settings";
import { Visibility } from "./icons/Visibility";
import { Menu } from "./icons/Menu";
import { MiniLogo } from "./icons/MiniLogo";
import { Check } from "./icons/Check";
import { UnChecked } from "./icons/UnChecked";
import { CheckMark } from "./icons/CheckMark";
import { Circle } from "./icons/Circle";
import { CircleChecked } from "./icons/CircleChecked";
import { CheckWhite } from "./icons/CheckWhite";
import { Exit } from "./icons/Exit";


export const iconPack = {
  calendar: <Calendar />,
  tasks: <Tasks />,
  logo: <Logo />,
  bell: <Bell />,
  address: <Address />,
  payments: <Payments />,
  proxy: <Proxy />,
  account: <Account />,
  analytics: <Analytics />,
  cookout: <Cookout />,
  support: <Support />,
  phantomBot: <PhantomBot />,
  history: <History />,
  rate: <Rate />,
  tsbBot: <TSBbot />,
  nsbBot: <NSBbot />,
  chevron: <Chevron />,
  jig: <Jig />,
  edit: <Edit />,
  delete: <Delete />,
  dublicate: <Dublicate />,
  stamp: <Stamp />,
  chip: <Chip />,
  creditCardLogo: <CreditCardLogo />,
  search: <Search />,
  list: <List />,
  close: <Close />,
  settings: <Settings />,
  visibility: <Visibility />,
  menu: <Menu />,
  miniLogo: <MiniLogo />,
  check: <Check />,
  checkWhite: <CheckWhite />,
  unchecked: <UnChecked />,
  checkMark: <CheckMark />,
  circle: <Circle />,
  circleChecked: <CircleChecked />,
  exit: <Exit />
};

export const Icon = ({ name }) => {
  return iconPack[name] || null;
};
