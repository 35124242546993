export const headList = [
    { code: "id", title: "ID", mobileHide: false, isNumeric: false },
    { code: "sdioId", title: "sdio ID", mobileHide: false, isNumeric: true },
    { code: "name", title: "Name", mobileHide: false, isNumeric: false },
    { code: "shortName", title: "Abbreviation", mobileHide: false, isNumeric: false },
    { code: "sport", title: "Sport", mobileHide: true, isNumeric: false },
    { code: "league", title: "League", mobileHide: true, isNumeric: false, transformClass: "uppercase" },
    {
        code: "updatedAt",
        title: "Last Update",
        type: "date",
        format: "L h:mm A",
        mobileHide: true,
        isNumeric: false,
    },
];
