import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { LayoutPage } from "layouts/LayoutPage";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { NoResults } from "components/common/NoResults";
import { withDialog } from "components/common/withDialog/withDialog";
import * as moment from "moment-timezone";
//import { EventHeader } from "components/EventPage/EventHeader";
//import { EventOdds } from "components/EventPage/EventOdds";
//import { UserBets } from "components/UserPage/UserBets";
import { UserHeader } from "components/UserPage/UserHeader";
//import { EventMisc } from "components/EventPage/EventMisc";
import { Spinner } from "components/common/Spinner/Spinner";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import { getParams } from "utils/helpers";
//import moment from "moment-timezone";
import "./UserByIdPage.scss";
import { UserBets } from "components/UserPage/UserBets";
//import { BetsSummary } from "components/UserPage/BetsSummary";
import { UserComparison } from "components/UserPage/UserComparison";
import Confirmation from "modals/Confirmation";
import Dropdown from 'react-dropdown';
import firebase from "firebase/compat/app"

const Config = require("../../utils/localConfig.json");
const gamesMap = {
    0: { sport: "baseball", league: "mlb" },
    1: { sport: "basketball", league: "nba" },
    2: { sport: "basketball", league: "ncaab" },
    3: { sport: "football", league: "ncaaf" },
    4: { sport: "football", league: "nfl" },
    5: { sport: "hockey", league: "nhl" },
    7: { sport: "soccer", league: "soccer" },
    8: { sport: "basketball", league: "wnba" },
    9: { sport: "soccer", league: "epl" },
    10: { sport: "soccer", league: "mls" },
    11: { sport: "mma", league: "ufc" },
    12: { sport: "racing", league: "f1" },
}


const UserByIdPage = ({
    history,
    location,
    closeModal,
    openModal,
    match: {
        params: {
            userId
        }

    } }) => {

    const { app } = useContext(AuthContext)
    const { functions } = useContext(AuthContext);
    const { firestore } = useContext(AuthContext);
    const { updateMeta, updateFlash, showSpinner } = useContext(StoreContext);

    //const [userId, setUserId] = useState(id || "");
    const [user, setUser] = useState(null);
    const [userSummary, setUserSummary] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const [invalidV2Bets, setInvalidV2Bets] = useState({})
    const [v2ActiveBets, setV2ActiveBets] = useState([]);
    const [v2ArchiveBets, setV2ArchiveBets] = useState([]);
    const [betListBets, setBetListBets] = useState({ bets: [], parlays: [], teasers: [] });
    const [missingUserBets, setMissingUserBets] = useState({ bets: [], parlays: [], teasers: [], count: 0 });
    const [missingV2Bets, setMissingV2Bets] = useState({ bets: [], parlays: [], teasers: [], count: 0 });
    const [floatingBetsList, setFloatingBetsList] = useState([])

    const [userLoading, setUserLoading] = useState(true);
    const [betListLoading, setBetListLoading] = useState(true);
    const [v2ActiveLoading, setV2ActiveLoading] = useState(true);
    const [v2ArchiveLoading, setV2ArchiveLoading] = useState(true);
    const [leftOverParlayTeaserBets, setLeftOverParlayTeaserBets] = useState(true);
    const [corruptedParlayTeasersBets, setCorruptedParlayTeasersBets] = useState(true);
    const [validatingBets, setValidatingBets] = useState(true)
    const [analyzingParlaysAndTeasers, setAnalyzingParlaysAndTeasers] = useState(true);
    const [loadingFloatingBets, setLoadingFloatingBets] = useState(true)

    const [isDev, setIsDev] = useState(window.location.hostname === "localhost" ? true : false);
    const [options, setOptions] = useState([])
    //var options = []
    var defaultOption = options[0];

    const confirm = (e, action) => {
        e.preventDefault();
        let message = '';
        switch (action) {
            case 'deleteInvalidBets':
                message = "Deleting invalid bets ?"
                break
            case 'replicateBets':
                message = "Replicate all bets to test user ?"
                break
            case 'fixMissingBets':
                message = "Fix missing bets ?"
                break
            case 'deleteAllBets':
                message = "Delete bets from test user?"
                break
            case 'deleteParlayTeasersMissingBets':
                message = "Delete Parlay Teasers left over bets?"
                break
            case 'deleteFloatingBets':
                message = "Delete floating bets from test user?"
            case 'deleteStubBets':
                message = "Delete bets that are in users_v2 but not in bets collection ?"
                break
            default:
                message = "Not a valid action"
        }
        openModal({
            title: "Confirmation",
            content: (
                <Confirmation
                    message={message}
                    closeModal={closeModal}
                    buttons={true}
                    onConfirm={(e) => {
                        closeModal();
                        switch (action) {
                            case 'deleteInvalidBets':
                                deleteInvalidBets()
                                break
                            case 'deleteAllBets':
                                deleteAllBets()
                                break
                            case 'replicateBets':
                                copyTestUser()
                                break
                            case 'fixMissingBets':
                                fixMissingV2BetsToUsersV2()
                                break
                            case 'deleteParlayTeasersMissingBets':
                                parlayAndTeaserBetsMissingFromBets(true)
                                break
                            case 'deleteFloatingBets':
                                deleteFloatingBets()
                                break
                            case 'deleteStubBets':
                                deleteStubBets();
                                break;
                        }
                    }}
                />
            ),
        });
    };

    useEffect(() => {
        getMissingUserBets()
        getMissingV2Bets()
    }, [v2ActiveBets, v2ArchiveBets, betListBets])

    const fetchMyBetting = async (showArchive, uid) => {
        const archive = showArchive ? "&show_archive=" + showArchive : ""
        const calledUserId = uid ? uid : userId
        const url = isDev ? Config.myBetting.local : Config.myBetting.deployed
        const returnObj = await fetch(url + "?" + "user_id=" + calledUserId + "&limit=100000" + archive)
            .then((res) => res.json())
            .then((data) => {
                if (uid === undefined) {
                    const dataList = []
                    data.data.list.map((bet) => {
                        if (bet.id === "00Ihmfzf9KdenMSkPTa8") {
                            console.log(bet)
                            console.log(showArchive)
                        }
                        const newObj = {
                            ...bet,
                            isActivity: !showArchive
                        }
                        dataList.push(newObj)
                    })
                    showArchive ? setV2ArchiveBets(() => [...dataList]) :
                        setV2ActiveBets(() => [...dataList])
                    showArchive ? setV2ArchiveLoading(false) : setV2ActiveLoading(false)
                }
                else {
                    const label = showArchive ? "archive" : "activity"
                    const updatedObj = {
                        bets: [...data.data.list.filter((bet) => bet.type === "bet")],
                        parlays: [...data.data.list.filter((bet) => bet.type === "parlay")],
                        teasers: [...data.data.list.filter((bet) => bet.type === "teaser")]
                    }
                    return updatedObj
                    //setUserSummary((userSumObj) => ({...userSumObj, [uid]: {...userSummary[uid], ...updatedObj}}))
                }
            })
            .catch((err) => {
                setLoading(false);
                showArchive ? setV2ArchiveLoading(false) : setV2ActiveLoading(false)
                console.log(err)
                return { bets: [], parlays: [], teasers: [] }
            });
        return returnObj
    }

    const fetchMyProfile = async (uid) => {
        const url = isDev ? Config.myProfile.local : Config.myProfile.deployed
        const calledUserId = uid ? uid : userId
        const returnObj = await fetch(url + "?" + "user_id=" + calledUserId)
            .then((res) => {
                if (res.ok) {
                    return res.json()
                } else if (res.status === 404) {
                    return Promise.reject('error 404')
                }
            })
            .then(data => {
                if (uid === undefined) {
                    const updatedObj = {
                        bets: [...data.data.bets],
                        parlays: [...data.data.parlays],
                        teasers: [...data.data.teasers]
                    }
                    setBetListBets(() => ({ ...updatedObj }))
                    const userObj = {
                        firstName: data.data.firstName,
                        lastName: data.data.lastName,
                        isAnonymous: data.data.isAnonymous ? true : false,
                        userExistsInV2: true
                    }
                    console.log(updatedObj)
                    setUser((oldObj) => ({ ...oldObj, ...userObj }))
                    setBetListLoading(false)
                }
                else {
                    const updatedObj = {
                        bets: [...data.data.bets],
                        parlays: [...data.data.parlays],
                        teasers: [...data.data.teasers]
                    }
                    console.log("My Profile Complete", updatedObj)
                    return updatedObj
                    //setUserSummary((userSumObj) => ({...userSumObj, [uid]: {...userSummary[uid], ...updatedObj}}))
                }

            })
            .catch((err) => {
                if (err == "error 404") {
                    console.log("this is 404 error")
                    const userObj = {
                        isAnonymous: true,
                        userExistsInV2: false
                    }
                    setUser((oldObj) => ({ ...oldObj, ...userObj }))
                }

                setBetListLoading(false)
                setLoading(false);
                console.log(err)
                return false
            });
        return returnObj
    }

    const validateV2Bets = async () => {
        if (userId) {
            const betRef = firestore
                .collection("bets")

            const userBets = await betRef.where('userId', '==', userId).where('isCustomBet', '==', true).get()
            if (userBets) {

                let invalidBetsObj = {
                    bets: {
                        count: 0,
                        invalidDocuments: []
                    },
                    parlays: {
                        count: 0,
                        invalidDocuments: []
                    },
                    teasers: {
                        count: 0,
                        invalidDocuments: []
                    }
                }

                userBets.docs.map((doc) => {
                    const betData = doc.data()
                    const validatedBet = validateNewBets(betData)
                    if (validatedBet.bet == null) {
                        if (betData.betType == 'straight') {
                            invalidBetsObj.bets.count++
                            invalidBetsObj.bets.invalidDocuments.push(betData.id)
                        }
                        if (betData.betType == 'parlay') {
                            if (invalidBetsObj.parlays.invalidDocuments.length == 0 || !invalidBetsObj.parlays.invalidDocuments.includes(betData.parentId)) {
                                invalidBetsObj.parlays.count++
                                invalidBetsObj.parlays.invalidDocuments.push(betData.parentId)
                            }
                        }
                        if (betData.betType == 'teaser') {
                            if (invalidBetsObj.teasers.invalidDocuments.length == 0 || !invalidBetsObj.teasers.invalidDocuments.includes(betData.parentId)) {
                                invalidBetsObj.teasers.count++
                                invalidBetsObj.teasers.invalidDocuments.push(betData.parentId)
                            }
                        }

                    }
                }
                )
                console.log("betsssssssssssss error", invalidBetsObj)
                setInvalidV2Bets(() => invalidBetsObj)
                setValidatingBets(false)
            }
        }
    }

    const getSportAndLeagueFromSportId = (sportId) => {
        return gamesMap[sportId];
    }

    const createGuid = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        )
    }

    // Validate bets V2 bets for a user. For now it will only validate custom bets
    const validateNewBets = (newBetData) => {
        let responseObj = {
            bet: null,
        };
        console.log(newBetData)
        if (newBetData) {
            if ((!newBetData.hasOwnProperty('isActive') || typeof newBetData.isActive != 'boolean')
                || (!newBetData.hasOwnProperty('draw') || typeof newBetData.draw != 'boolean')
                || (!newBetData.hasOwnProperty('isCustomBet') || typeof newBetData.draw != 'boolean')) {
                console.log(`${newBetData.id} Bet should have active, isActive, draw, isCustomBet boolean flag`)
                return responseObj
            }
            if (!newBetData.betOdds || newBetData.betOdds === '') {
                console.log(`${newBetData.id} Bet should have betOdds. It should not be null or Empty`)
                return responseObj
            }
            if (!newBetData.betType || newBetData.betType === '') {
                console.log(`${newBetData.id} Bet should have betType. It should not be null or Empty`)
                return responseObj
            }
            if (!newBetData.createdAt || newBetData.createdAt === 0) {
                console.log(`${newBetData.id} Bet should have createdAt. It should not be null or zero`)
                return responseObj
            }
            if (!newBetData.hasOwnProperty("customAwayScore") || !newBetData.hasOwnProperty("customHomeScore")) {
                console.log(`${newBetData.id} Bet should have customAwayScore and customHomeScore.`)
                return responseObj
            }
            if (!newBetData.eventAt || newBetData.eventAt === '' || typeof newBetData.eventAt === "object") {
                console.log(`${newBetData.id} Bet should have eventAt. It should not be null or Empty`)
                return responseObj
            }
            if (!newBetData.eventId || !newBetData.hasOwnProperty('manualPaceStateValue') || !newBetData.hasOwnProperty('wager')) {
                console.log(`${newBetData.id} Bet should have eventId, manualPaceStateValue, wager.`)
                return responseObj
            }
            if (!newBetData.isCustomBet && !newBetData.teamId) {
                console.log(`${newBetData.id} If a bet is not custom bet then it should have a teamId.`)
                return responseObj
            }
            if (!newBetData.hasOwnProperty('parentId') || newBetData.parentId === '') {
                console.log(`${newBetData.id} Bet should have parentId. It can be null but not empty`)
                return responseObj
            }
            if (!newBetData.hasOwnProperty('payout')) {
                console.log(`${newBetData.id} Bet should have payout`)
                return responseObj
            }
            if ((!newBetData.price || newBetData.price === '')
                || (!newBetData.segment || newBetData.segment === '')
                || (!newBetData.team || newBetData.team === '')
                || (!newBetData.userId || newBetData.userId === '')
                || (!newBetData.wagerType || newBetData.wagerType === '')) {
                console.log(`${newBetData.id} Bet should have price, segment, team, userId, wagerType. These properties should not be null or empty`)
                return responseObj
            }

            // Odds data
            if (!newBetData.odds?.moneyLineAway || newBetData.odds?.moneyLineAway === ''
                || !newBetData.odds?.drawLine || newBetData.odds?.drawLine === ''
                || !newBetData.odds?.moneyLineHome || newBetData.odds?.moneyLineHome === ''
                || !newBetData.odds?.overLine || newBetData.odds?.overLine === ''
                || !newBetData.odds?.underLine || newBetData.odds?.underLine === ''
                || !newBetData.odds?.pointSpreadAway || newBetData.odds?.pointSpreadAway === ''
                || !newBetData.odds?.pointSpreadHome || newBetData.odds?.pointSpreadHome === ''
                || !newBetData.odds?.pointSpreadAwayLine || newBetData.odds?.pointSpreadAwayLine === ''
                || !newBetData.odds?.pointSpreadHomeLine || newBetData.odds?.pointSpreadHomeLine === ''
                || !newBetData.odds?.totalNumber || newBetData.odds?.totalNumber === '') {

                console.log(`${newBetData.id} Bet is missing Odd properties`)
                return responseObj
            }
            if (!newBetData.match_object.sport || newBetData.match_object.sport === ''
                || !newBetData.match_object.league || newBetData.match_object.league === ''
                || !newBetData.match_object.MatchTime || newBetData.match_object.MatchTime === 0
                || !newBetData.match_object.ID
                || !newBetData.match_object.AwayTeam || newBetData.match_object.AwayTeam === ''
                || !newBetData.match_object.HomeTeam || newBetData.match_object.HomeTeam === '') {

                console.log(`${newBetData.id} Bet is missing match_object properties`)
                return responseObj
            }

            responseObj.bet = newBetData
        }
        return responseObj
    }

    const deleteInvalidBets = async () => {
        let betDeleteFailedCount = 0
        showSpinner(true)
        setUserLoading(true)
        setLoading(true)

        const invalidV2BetsCount = invalidV2Bets.bets?.count + invalidV2Bets.parlays?.count + invalidV2Bets.teasers?.count

        if (invalidV2Bets && invalidV2BetsCount > 0){

            //Delete V2 Bets
            if (invalidV2Bets.bets?.invalidDocuments?.length > 0) {
                for (const v2Bet of invalidV2Bets.bets.invalidDocuments) {
                    const deleteResponse = await deleteV2Bet(v2Bet, userId)
                    console.log("V2 invalid Bet Delete", deleteResponse)
                    if (!deleteResponse.success) {
                        betDeleteFailedCount++
                    }
                }
            }
            if (invalidV2Bets.parlays?.invalidDocuments?.length > 0) {
                for (const v2Parlay of invalidV2Bets.parlays.invalidDocuments) {
                    const deleteResponse = await deleteV2Parlay(v2Parlay, userId)
                    console.log("V2 invalid Parlay Delete", deleteResponse)
                    if (!deleteResponse.success) {
                        betDeleteFailedCount++
                    }
                }
            }
            if (invalidV2Bets.teasers?.invalidDocuments?.length > 0) {
                for (const v2Teaser of invalidV2Bets.teasers.invalidDocuments) {
                    const deleteResponse = await deleteV2Teaser(v2Teaser, userId)
                    console.log("V2 invalid Teaser Delete", deleteResponse)
                    if (!deleteResponse.success) {
                        betDeleteFailedCount++
                    }
                }
            }
            if (betDeleteFailedCount > 0) {
                updateFlash({
                    message: `Invalid bets failed to delete for user ${userId}`,
                    success: false,
                });
                setTimeout(() => {
                    fetchUser()
                }, 2000)
            }
            else {
                updateFlash({
                    message: `Invalid bets deleted for user ${userId}`,
                    success: true,
                });
                setTimeout(() => {
                    fetchUser()
                }, 2000)
            }
        }
        else {
            updateFlash({
                message: `Bets not found for user ${userId}`,
                success: false,
            });
            setTimeout(() => {
                fetchUser()
            }, 2000)
        }

    }

    const deleteV2Bet = async (betId, userId) => {
        try {
            const betDeleteResponse = await functions.httpsCallable("betDelete")({
                betId: betId,
                userId: userId
            })
            return {
                success: true,
                response: betDeleteResponse
            }
        }
        catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    const deleteV2Parlay = async (parlayId, userId) => {
        try {
            const parlayDeleteResponse = await functions.httpsCallable("parlayDelete")({
                parlayId: parlayId,
                userId: userId
            })
            return {
                success: true,
                response: parlayDeleteResponse
            }
        }
        catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    const deleteV2Teaser = async (teaserId, userId) => {
        try {
            const teaserDeleteResponse = await functions.httpsCallable("teaserDelete")({
                teaserId: teaserId,
                userId: userId
            })
            return {
                success: true,
                response: teaserDeleteResponse
            }
        }
        catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    const fetchUserProfile = async () => {
        functions
            .httpsCallable("getUserById")({
                user_id: userId
            })
            .then(async (res) => {
                if (res.data.success === true) {
                    let userData = { ...res.data.user }
                    const usersV2DocRef = await firestore.collection("users_v2").doc(userId).get()
                    const usersDocRef = await firestore.collection("users").doc(userId).get()
                    const userExists = usersV2DocRef.exists || usersDocRef.exists
                    userData["userExists"] = userExists
                    setUser((old) => ({ ...old, ...userData }))
                    setUserLoading(false)
                }
            })
            .catch((err) => {
                setUserLoading(false)
                console.log(err);
            });
    }

    const copyV2Bets = async () => {
        let batch = firestore.batch()
        let count = 0;
        const data = betListBets?.bets
        while (data?.length) {
            let bet = JSON.parse(JSON.stringify(data?.shift()))
            bet.userId = "oasP1ZCBvrMYW0hdZwBgppRMEWn1"
            const subcollection = bet.isActive === true ? "activity" : "archive"
            const usersV2Ref = await firestore
                .collection("users_v2")
                .doc(userId)
                .collection(subcollection)
                .doc(bet.id)
            const usersV2Doc = await usersV2Ref.get()

            const betRef = await firestore
                .collection("bets")
                .doc()

            bet.id = betRef.id
            batch.set(betRef, { ...bet })
            if (usersV2Doc.exists) {
                const newUsersV2Ref = await firestore
                    .collection("users_v2")
                    .doc("oasP1ZCBvrMYW0hdZwBgppRMEWn1")
                    .collection(subcollection)
                    .doc(betRef.id)
                const usersV2Data = usersV2Doc.data()
                usersV2Data.id = betRef.id
                batch.set(newUsersV2Ref, { ...usersV2Data })
                ++count

            }
            if (++count >= 300 || !data?.length) {
                await batch.commit();
                batch = firestore.batch();
                count = 0;
            }
        }
        return true
    }

    const copyV2Parlays = async () => {
        let batch = firestore.batch()
        let count = 0;
        const data = betListBets?.parlays
        while (data?.length) {
            let parlay = JSON.parse(JSON.stringify(data?.shift()))
            parlay.userId = "oasP1ZCBvrMYW0hdZwBgppRMEWn1";
            const subcollection = parlay.isActive === true ? "activity" : "archive";
            const usersV2Ref = await firestore
                .collection("users_v2")
                .doc(userId)
                .collection(subcollection)
                .doc(parlay.id);
            const usersV2Doc = await usersV2Ref.get();

            const newParlayRef = await firestore
                .collection("parlays")
                .doc();
            parlay.id = newParlayRef.id;

            const newBetIds = await createParlayOrTeaserBets(parlay.bets, newParlayRef.id)

            parlay.bets = newBetIds

            batch.set(newParlayRef, { ...parlay })
            if (usersV2Doc.exists) {
                ++count
                const newUsersV2Ref = await firestore
                    .collection("users_v2")
                    .doc("oasP1ZCBvrMYW0hdZwBgppRMEWn1")
                    .collection(subcollection)
                    .doc(newParlayRef.id)
                const usersV2Data = usersV2Doc.data()
                usersV2Data.id = newParlayRef.id
                batch.set(newUsersV2Ref, { ...usersV2Data })
            }

            if (++count >= 50 || !data?.length) {
                await batch.commit();
                batch = firestore.batch();
                count = 0;
            }
        }
        return true
    }

    const copyV2Teasers = async () => {

        let batch = firestore.batch()
        let count = 0;
        const data = betListBets?.teasers
        while (data?.length) {
            let teaser = JSON.parse(JSON.stringify(data?.shift()))
            teaser.userId = "oasP1ZCBvrMYW0hdZwBgppRMEWn1";
            const subcollection = teaser.isActive === true ? "activity" : "archive";
            const usersV2Ref = await firestore
                .collection("users_v2")
                .doc(userId)
                .collection(subcollection)
                .doc(teaser.id);
            const usersV2Doc = await usersV2Ref.get();

            const newTeaserRef = await firestore
                .collection("teasers")
                .doc();
            teaser.id = newTeaserRef.id;

            const newBetIds = await createParlayOrTeaserBets(teaser.bets, newTeaserRef.id)

            teaser.bets = newBetIds

            batch.set(newTeaserRef, { ...teaser })
            if (usersV2Doc.exists) {
                ++count
                const newUsersV2Ref = await firestore
                    .collection("users_v2")
                    .doc("oasP1ZCBvrMYW0hdZwBgppRMEWn1")
                    .collection(subcollection)
                    .doc(newTeaserRef.id)
                const usersV2Data = usersV2Doc.data()
                usersV2Data.id = newTeaserRef.id
                batch.set(newUsersV2Ref, { ...usersV2Data })
            }

            if (++count >= 100 || !data?.length) {
                await batch.commit();
                batch = firestore.batch();
                count = 0;
            }
        }
        return true
    }

    const parlayAndTeaserBetsMissingFromBets = async (isDelete) => {
        let missingBets = []
        let betsWithMissngParentId = []
        showSpinner(true)
        setUserLoading(true)
        // setLoading(true)
        try {
            let userParlaysRef = await firestore
                .collection("parlays")
                .where('userId', '==', userId)
                .get()
            const userParlays = userParlaysRef.docs

            let userTeasersRef = await firestore
                .collection("teasers")
                .where('userId', '==', userId)
                .get()
            const userTeasers = userTeasersRef.docs

            for (let i = 0; i < userParlays?.length; i++) {
                const parlay = userParlays[i].data()
                let parlayPromises = parlay.bets.map(async (betId) => {
                    const parlayBetInBetTable = await firestore
                        .collection("bets")
                        .doc(betId)
                        .get()
                    if (!parlayBetInBetTable.exists) {
                        missingBets.push(betId)
                        if (isDelete) {
                            await firestore.collection("parlays").doc(parlay.id).update({
                                bets: firebase.firestore.FieldValue.arrayRemove(betId)
                            });
                        }
                    }
                    else {
                        if (parlayBetInBetTable.data()?.parentId == null) {
                            betsWithMissngParentId.push(betId)
                            if (isDelete) {
                                await firestore.collection("bets").doc(betId).update({
                                    parentId: parlay.id
                                });
                            }
                        }
                    }
                })
                await Promise.all(parlayPromises)
            }

            for (let i = 0; i < userTeasers?.length; i++) {
                const teaser = userTeasers[i].data()
                let teaserPromises = teaser.bets.map(async (betId) => {
                    const teaserBetInBetTable = await firestore
                        .collection("bets")
                        .doc(betId)
                        .get()
                    if (!teaserBetInBetTable.exists) {
                        missingBets.push(betId)
                        if (isDelete) {
                            await firestore.collection("teasers").doc(teaser.id).update({
                                bets: firebase.firestore.FieldValue.arrayRemove(betId)
                            });
                        }
                    }
                    else {
                        if (teaserBetInBetTable.data()?.parentId == null) {
                            betsWithMissngParentId.push(betId)
                            if (isDelete) {
                                await firestore.collection("bets").doc(betId).update({
                                    parentId: teaser.id
                                });
                            }
                        }
                    }
                })
                await Promise.all(teaserPromises)
            }
            if (!isDelete) {
                setLeftOverParlayTeaserBets(() => missingBets)
                setCorruptedParlayTeasersBets(() => betsWithMissngParentId)
                showSpinner(false)
                setUserLoading(false)
            }
            else {
                updateFlash({
                    message: `Parlays and teasers fixed for user ${userId}`,
                    success: true,
                });
                setTimeout(() => {
                    fetchUser()
                }, 2000)
            }
            console.log("missing bets with null parentId", betsWithMissngParentId)
            setAnalyzingParlaysAndTeasers(false)
        }
        catch (error) {
            updateFlash({
                message: `Parlays and teasers could not be fixed for user ${userId}. Please check console.`,
                success: false,
            });
            console.log('Fixing parlay and teaser failed: ', error)
            showSpinner(false)
            setUserLoading(false)
        }

    }

    const findFloatingBets = async () => {
        let floatingBets = [];
        const parlayRefs = await firestore
            .collection("bets")
            .where("betType", "==", "parlay")
            .where("userId", "==", userId)
            .get()
        const parlayDocs = parlayRefs.docs

        const teaserRefs = await firestore
            .collection("bets")
            .where("betType", "==", "teaser")
            .where("userId", "==", userId)
            .get()
        const teaserDocs = teaserRefs.docs

        const parlayPromises = parlayDocs.map(async (parlayDoc) => {
            const parlay = parlayDoc.data()
            const parentId = parlay.parentId
            if (parentId) {
                const parentIdRef = await firestore.collection("parlays").doc(parentId).get()

                if (!parentIdRef.exists) {
                    console.log("Parlay:", parentId, parlay.id, parentIdRef.exists)
                    floatingBets.push(parlay.id)
                }
                return true
            }
            else {
                return true
            }

        })

        const teaserPromises = teaserDocs.map(async (teaserDoc) => {
            const teaser = teaserDoc.data()
            const parentId = teaser.parentId
            if (parentId) {
                const parentIdRef = await firestore.collection("teasers").doc(parentId).get()
                if (!parentIdRef.exists) {
                    console.log("Teaser:", parentId, teaser.id, parentIdRef.exists)
                    floatingBets.push(teaser.id)
                }
                return true
            }
            else {
                return true
            }

        })

        await Promise.all(parlayPromises, teaserPromises)
        setFloatingBetsList(() => floatingBets)
        setLoadingFloatingBets(false)
    }

    const deleteFloatingBets = async () => {
        setUserLoading(true)
        setLoading(true)
        for (const id of floatingBetsList) {
            await firestore.collection("bets").doc(id).delete()
        }
        setTimeout(() => {
            setUserLoading(false)
            setLoading(false)
            fetchUser()
        }, 3000)
        showSpinner(false)
        setUserLoading(false)
        setLoading(false)
    }

    const createParlayOrTeaserBets = async (bets, parentId) => {

        const newBetIdsPromises = bets.map(async (b) => {
            let bet = JSON.parse(JSON.stringify(b))
            if (typeof bet === "string") {
                const betData = await firestore
                    .collection("bets")
                    .doc(bet)
                    .get()
                    .then((bet) => {
                        return bet.data()
                    })
                betData.userId = "oasP1ZCBvrMYW0hdZwBgppRMEWn1"
                const betRef = await firestore
                    .collection("bets")
                    .doc()
                betData.parentId = parentId
                betData.id = betRef.id
                betRef.set({ ...betData })
                return betRef.id
            }

            bet.userId = "oasP1ZCBvrMYW0hdZwBgppRMEWn1"

            const betRef = await firestore
                .collection("bets")
                .doc()
            bet.parentId = parentId
            bet.id = betRef.id
            betRef.set({ ...bet })
            return betRef.id
        })
        const newBetIds = await Promise.all(newBetIdsPromises)
            .catch((err) => console.log(err))

        return newBetIds
    }

    const copyV2BetsToUser = async () => {

        const copySuccess = await Promise.all([copyV2Bets(), copyV2Parlays(), copyV2Teasers()])
            .then((response) => {
                console.log("copy response:", response)
                if (response[0] && response[1] && response[2]) {
                    return true
                }
                else {
                    return false
                }
            })
            .catch((error) => {
                console.log("copyV2BetsToUser - Error", error)
                return false
            })
        return copySuccess

    }

    const deleteAllBets = async () => {
        showSpinner(true)
        setUserLoading(true)
        setLoading(true)
        const newURL = window.location.protocol + "//" + window.location.host + "/users/oasP1ZCBvrMYW0hdZwBgppRMEWn1"
        Promise.all([deleteV2FromTestUser()])
            .then(async (response) => {
                if (isDev && response[0] && response[0].allBetsDeleted) {
                    console.log("true response")
                    updateFlash({
                        message: `Bets deleted from user 'oasP1ZCBvrMYW0hdZwBgppRMEWn1' in users table`,
                        success: true,
                    });
                    setTimeout(() => {
                        if (userId === "oasP1ZCBvrMYW0hdZwBgppRMEWn1") {
                            fetchUser()
                        }
                        else {
                            showSpinner(false)
                            setUserLoading(false)
                            setLoading(false)
                            window.open(newURL, '_blank')
                        }

                    }, 2000)

                }
                else if (isDev && response[0].success) {
                    console.log("true response")
                    updateFlash({
                        message: `First 150 bets, 150 parlays, and 150 teasers deleted from user 'oasP1ZCBvrMYW0hdZwBgppRMEWn1' in users table`,
                        success: true,
                    });
                    setTimeout(() => {
                        if (userId === "oasP1ZCBvrMYW0hdZwBgppRMEWn1") {
                            fetchUser()
                        }
                        else {
                            showSpinner(false)
                            setUserLoading(false)
                            setLoading(false)
                            window.open(newURL, '_blank')
                        }
                    }, 2000)

                }
                else if (response[0].success) {
                    console.log("true response")
                    updateFlash({
                        message: `Bets deleted from user 'oasP1ZCBvrMYW0hdZwBgppRMEWn1' in users table`,
                        success: true,
                    });
                    setTimeout(() => {
                        if (userId === "oasP1ZCBvrMYW0hdZwBgppRMEWn1") {
                            fetchUser()
                        }
                        else {
                            showSpinner(false)
                            setUserLoading(false)
                            setLoading(false)
                            window.open(newURL, '_blank')
                        }
                    }, 2000)
                }
                else {
                    updateFlash({
                        message: `Test user oasP1ZCBvrMYW0hdZwBgppRMEWn1 failed to delete`,
                        success: false,
                    });
                    setTimeout(() => {
                        if (userId === "oasP1ZCBvrMYW0hdZwBgppRMEWn1") {
                            fetchUser()
                        }
                        else {
                            showSpinner(false)
                            setUserLoading(false)
                            setLoading(false)
                            window.open(newURL, '_blank')
                        }
                    }, 2000)
                }
            }).catch((err) => {
                console.log("Error in delete:", err)
                updateFlash({
                    message: `Test user oasP1ZCBvrMYW0hdZwBgppRMEWn1 failed to delete`,
                    success: false,
                });
                setTimeout(() => {
                    if (userId === "oasP1ZCBvrMYW0hdZwBgppRMEWn1") {
                        fetchUser()
                    }
                    else {
                        showSpinner(false)
                        setUserLoading(false)
                        setLoading(false)
                        window.open(newURL, '_blank')
                    }
                }, 2000)
            }
            )
    }

    const copyTestUser = async () => {
        showSpinner(true)
        setUserLoading(true)
        setLoading(true)
        const newURL = window.location.protocol + "//" + window.location.host + "/users/oasP1ZCBvrMYW0hdZwBgppRMEWn1"
        const copySuccess = await fullCopyToTestUser()
        console.log("copySuccess:", copySuccess)
        if (copySuccess) {
            updateFlash({
                message: `User ${userId} bets copied from user 'oasP1ZCBvrMYW0hdZwBgppRMEWn1' in users table`,
                success: true,
            });
            setTimeout(() => {
                if (userId === "oasP1ZCBvrMYW0hdZwBgppRMEWn1") {
                    fetchUser()
                }
                else {
                    window.open(newURL, '_blank')
                    fetchUser()
                    showSpinner(false)
                    setUserLoading(false)
                    setLoading(false)
                }

            }, 2000)

        }
        else {
            updateFlash({
                message: `User ${userId} bets failed to copy copied from user 'oasP1ZCBvrMYW0hdZwBgppRMEWn1' in users table`,
                success: false,
            });
            setTimeout(() => {
                if (userId === "oasP1ZCBvrMYW0hdZwBgppRMEWn1") {
                    fetchUser()
                }
                else {
                    window.open(newURL, '_blank')
                    fetchUser()
                    showSpinner(false)
                    setUserLoading(false)
                    setLoading(false)
                }
            }, 2000)


        }
    }

    const fetchTestMyProfile = async () => {
        const url = isDev ? Config.myProfile.local : Config.myProfile.deployed
        const testMyProfileObj = await fetch(url + "?" + "user_id=oasP1ZCBvrMYW0hdZwBgppRMEWn1")
            .then((res) => res.json())
            .then((data) => {
                const profileObj = {
                    bets: [...data.data.bets],
                    parlays: [...data.data.parlays],
                    teasers: [...data.data.teasers]
                }

                return profileObj

            })
            .catch((err) => {
                const profileObj = {
                    bets: [],
                    parlays: [],
                    teasers: []
                }
                console.log(err)
                return profileObj
            });
        return testMyProfileObj
    }



    const deleteV2FromTestUser = async () => {
        const testMyProfileBets = await fetchTestMyProfile()
        const bets = isDev === true ? testMyProfileBets.bets.slice(0, 150) : testMyProfileBets.bets
        const parlays = isDev === true ? testMyProfileBets.parlays.slice(0, 150) : testMyProfileBets.parlays
        const teasers = isDev === true ? testMyProfileBets.teasers.slice(0, 150) : testMyProfileBets.teasers

        let betsDeleted = true;
        let parlaysDeleted = true;
        let teasersDeleted = true;

        if (bets) {
            const betPromises = bets.map(async (bet) => {
                const response = await deleteV2Bet(bet.id, "oasP1ZCBvrMYW0hdZwBgppRMEWn1")
                if (!response.success) {
                    return false
                }
                else {
                    return true
                }
            })
            const betData = await Promise.all(betPromises)
            betsDeleted = betData.every(b => b === true)
        }

        if (parlays) {
            const parlayPromises = parlays.map(async (parlay) => {
                const response = await deleteV2Parlay(parlay.id, "oasP1ZCBvrMYW0hdZwBgppRMEWn1")
                if (!response.success) {
                    return false
                }
                else {
                    return true
                }
            })

            const parlayData = await Promise.all([...parlayPromises])
            parlaysDeleted = parlayData.every(b => b === true)
        }

        if (teasers) {
            const teaserPromises = teasers.map(async (teaser) => {
                const response = await deleteV2Teaser(teaser.id, "oasP1ZCBvrMYW0hdZwBgppRMEWn1")
                if (!response.success) {
                    return false
                }
                else {
                    return true
                }
            })
            const teaserData = await Promise.all([...teaserPromises])
            teasersDeleted = teaserData.every(b => b === true)

        }

        const allBetsDeleted = isDev ? (testMyProfileBets.bets.length <= 150 && betsDeleted) && (testMyProfileBets.parlays.length <= 150 && parlaysDeleted) && (testMyProfileBets.teasers.length <= 150 && teasersDeleted) : betsDeleted && parlaysDeleted && teasersDeleted

        return { success: betsDeleted && parlaysDeleted && teasersDeleted, allBetsDeleted: allBetsDeleted };
    }

    const fullCopyToTestUser = async () => {
        const copyComplete = await Promise.all([copyV2BetsToUser()])
            .then((response) => {
                if (response[0]) {
                    return true
                }
                else {
                    return false
                }
            }).catch((err) => {
                console.log("Error in copy:", err)
                return false
            })
        return copyComplete
    }

    const fetchUser = async () => {
        setAnalyzingParlaysAndTeasers(true)
        setBetListLoading(true)
        setUserLoading(true)
        setV2ActiveLoading(true)
        setV2ArchiveLoading(true)
        setValidatingBets(true)
        setLoadingFloatingBets(true)
        showSpinner(true)
        await fetchUserProfile();
        //fetch active bets
        await fetchMyBetting(false);
        //fetch archive bets
        await fetchMyBetting(true);
        //fetch from bets table
        await fetchMyProfile();
        // validate v2 bets
        await validateV2Bets();
        await parlayAndTeaserBetsMissingFromBets(false)
        await findFloatingBets()
        //copyLegacyBets();
        //setLoading(false)
    };

    useEffect(() => {
        updateMeta({
            title: "Search User by ID",
        });
        console.log("UseEffect 1", userId)
        if (userId) {
            fetchUser();
        }

    }, []);

    useEffect(() => {
        window.addEventListener('keydown', e => {
            if (e.key === 'Enter' || e.code == "Space") {
                const confirmBtn = document.getElementById("confirmBtn")
                if (confirmBtn) {
                    e.preventDefault()
                    confirmBtn.click()
                }
            }
            if (e.code == "Escape") {
                const cancelBtn = document.getElementById("cancelBtn")
                if (cancelBtn) {
                    e.preventDefault()
                    cancelBtn.click()
                }
            }
        })
        const env = process.env.NODE_ENV;
        setIsDev(window.location.hostname === "localhost")
    }, [])

    const getMissingUserBets = (userData) => {

        if (userData) {
            const userV2Bets = [...userData.usersV2Bets.active.bets, ...userData.usersV2Bets.archive.bets]
            const userV2Parlays = [...userData.usersV2Bets.active.parlays, ...userData.usersV2Bets.archive.parlays]
            const userV2Teasers = [...userData.usersV2Bets.active.teasers, ...userData.usersV2Bets.archive.teasers]
            const missingBets = userV2Bets.filter(x => !userData.betListBets.bets.some(function (y) { return y.id === x.id }));
            const missingParlays = userV2Parlays.filter(x => !userData.betListBets.parlays.some(function (y) { return y.id === x.id }));
            const missingTeasers = userV2Teasers.filter(x => !userData.betListBets.teasers.some(function (y) { return y.id === x.id }));

            return {
                bets: missingBets,
                parlays: missingParlays,
                teasers: missingTeasers,
                count: missingBets.length + missingParlays.length + missingTeasers.length
            }
        }
        else {
            const userV2Bets = [...v2ActiveBets.filter((bet) => bet.type === "bet"), ...v2ArchiveBets.filter((bet) => bet.type === "bet")]
            const userV2Parlays = [...v2ActiveBets.filter((parlay) => parlay.type === "parlay"), ...v2ArchiveBets.filter((parlay) => parlay.type === "parlay")]
            const userV2Teasers = [...v2ActiveBets.filter((teaser) => teaser.type === "teaser"), ...v2ArchiveBets.filter((teaser) => teaser.type === "teaser")]
            const missingBets = userV2Bets.filter(x => !betListBets.bets.some(function (y) { return y.id === x.id }));
            const missingParlays = userV2Parlays.filter(x => !betListBets.parlays.some(function (y) { return y.id === x.id }));
            const missingTeasers = userV2Teasers.filter(x => !betListBets.teasers.some(function (y) { return y.id === x.id }));

            const missingUserBetsObj = {
                bets: missingBets,
                parlays: missingParlays,
                teasers: missingTeasers,
                count: missingBets.length + missingParlays.length + missingTeasers.length
            }
            setMissingUserBets(() => ({ ...missingUserBetsObj }))

        }


    }

    const getMissingV2Bets = (userData) => {
        if (userData) {
            const userV2Bets = [...userData.usersV2Bets.active.bets, ...userData.usersV2Bets.archive.bets]
            const userV2Parlays = [...userData.usersV2Bets.active.parlays, ...userData.usersV2Bets.archive.parlays]
            const userV2Teasers = [...userData.usersV2Bets.active.teasers, ...userData.usersV2Bets.archive.teasers]
            const missingBets = userData.betListBets.bets.filter(x => !userV2Bets.some(function (y) { return x.id === y.id }));
            const missingParlays = userData.betListBets.parlays.filter(x => !userV2Parlays.some(function (y) { return x.id === y.id }));
            const missingTeasers = userData.betListBets.teasers.filter(x => !userV2Teasers.some(function (y) { return x.id === y.id }));

            return {
                bets: missingBets,
                parlays: missingParlays,
                teasers: missingTeasers,
                count: missingBets.length + missingParlays.length + missingTeasers.length
            }

        }
        else {
            const userV2Bets = [...v2ActiveBets.filter((bet) => bet.type === "bet"), ...v2ArchiveBets.filter((bet) => bet.type === "bet")]
            const userV2Parlays = [...v2ActiveBets.filter((parlay) => parlay.type === "parlay"), ...v2ArchiveBets.filter((parlay) => parlay.type === "parlay")]
            const userV2Teasers = [...v2ActiveBets.filter((teaser) => teaser.type === "teaser"), ...v2ArchiveBets.filter((teaser) => teaser.type === "teaser")]
            const missingBets = betListBets.bets.filter(x => !userV2Bets.some(function (y) { return x.id === y.id }));
            const missingParlays = betListBets.parlays.filter(x => !userV2Parlays.some(function (y) { return x.id === y.id }));
            const missingTeasers = betListBets.teasers.filter(x => !userV2Teasers.some(function (y) { return x.id === y.id }));

            const missingV2Obj = {
                bets: missingBets,
                parlays: missingParlays,
                teasers: missingTeasers,
                count: missingBets.length + missingParlays.length + missingTeasers.length
            }
            console.log("missingV2:", missingV2Obj)
            setMissingV2Bets(() => ({ ...missingV2Obj }))

        }


    }

    const getParlayTeaserBetsData = async (parlayBets) => {
        let parlayBetsDates = []
        for (let i = 0; i < parlayBets.length; i++) {
            const betRef = await firestore
                .collection("bets")
                .doc(parlayBets[i])
                .get()

            const betData = betRef.data()
            const eventDate = moment.utc(betData.eventAt).unix()
            parlayBetsDates.push(eventDate)
        }
        return parlayBetsDates
    }

    const deleteStubBets = async () => {
        showSpinner(true)
        setUserLoading(true)

        //bets in v2 table not in bets table // Lost Stub bets
        if (missingUserBets?.count > 0) {
            if (missingUserBets.bets?.length > 0) {
                missingUserBets.bets.map(async (bet) => {
                    const subCollection = bet.isActivity === true ? "activity" : "archive"
                    console.log("Subcollection:", subCollection)
                    await firestore
                        .collection("users_v2")
                        .doc(userId)
                        .collection(subCollection)
                        .doc(bet.id)
                        .delete()
                })
            }
            if (missingUserBets.parlays?.length > 0) {
                missingUserBets.parlays.map(async (parlay) => {
                    const subCollection = parlay.isActivity === true ? "activity" : "archive"
                    console.log("Subcollection:", subCollection)
                    console.log("ParlayId:", parlay.id)
                    await firestore.collection("users_v2")
                        .doc(userId)
                        .collection(subCollection)
                        .doc(parlay.id)
                        .delete()
                    await firestore.collection("parlays")
                        .doc(parlay.id)
                        .delete()
                    await firestore.collection("bets")
                        .where("parentId", "==", parlay.id)
                        .where("userId", "==", userId)
                        .get()
                        .then((querySnapshot) => {
                            querySnapshot.docs.map((doc) => {
                                console.log(doc.ref)
                                doc.ref.delete()
                            })
                        })
                })
            }
            if (missingUserBets.teasers?.length > 0) {
                missingUserBets.teasers.map(async (teaser) => {
                    const subCollection = teaser.isActivity === true ? "activity" : "archive"
                    console.log("Subcollection:", subCollection)
                    console.log("TeaserId:", teaser.id)
                    await firestore.collection("users_v2")
                        .doc(userId)
                        .collection(subCollection)
                        .doc(teaser.id)
                        .delete()
                    await firestore.collection("teasers")
                        .doc(teaser.id)
                        .delete()
                    await firestore.collection("bets")
                        .where("parentId", "==", teaser.id)
                        .where("userId", "==", userId)
                        .get()
                        .then((querySnapshot) => {
                            querySnapshot.docs.map((doc) => {
                                doc.ref.delete()
                            })
                        })
                })
            }
            updateFlash({
                message: `missing bets reference deleted in Users_v2 for user ${userId}`,
                success: true,
            });

            setTimeout(() => {
                fetchUser()
            }, 2000)
        }
        else {
            updateFlash({
                message: `No missing bets found for user ${userId}`,
                success: false,
            })

            setTimeout(() => {
                fetchUser()
            }, 2000)
        }

    }

    const fixMissingV2BetsToUsersV2 = async () => {
        showSpinner(true)
        setUserLoading(true)
        //const missingV2Bets = getMissingV2Bets()
        // const missingUserBets = getMissingUserBets()

        //in bets table but not in users v2
        let missingBetPromises;
        let missingParlayPromises;
        let missingTeaserPromises;

        if (missingV2Bets?.count > 0) {
            if (missingV2Bets.bets?.length > 0) {
                console.log('missing bet found', missingV2Bets.bets?.length)
                missingBetPromises = missingV2Bets.bets.map(async (missingBet) => {
                    console.log('missing bet processing', missingBet.id)
                    const userBetType = !missingBet.isActive ? "archive" : "activity"
                    console.log('activity or archive', userBetType)
                    const addBetObj = {
                        createdAt: missingBet.createdAt,
                        id: missingBet.id,
                        timestamp: moment.utc(missingBet.eventAt).unix(),
                        type: 'bet'
                    }
                    console.log('missing bet recreation', addBetObj)
                    const userBetCollection = await firestore
                        .collection("users_v2")
                        .doc(userId)
                        .collection(userBetType)
                        .doc(missingBet.id)
                    await userBetCollection.set({
                        ...addBetObj
                    })
                })
            }
            if (missingV2Bets.parlays?.length > 0) {
                if (missingV2Bets.parlays?.length > 0) {
                    missingParlayPromises = missingV2Bets.parlays.map(async (missingParlay) => {
                        const userBetType = !missingParlay?.isActive ? "archive" : "activity"
                        const parlayref = await firestore
                            .collection("parlays")
                            .doc(missingParlay.id)
                            .get()

                        const parlayData = parlayref.data()
                        const parlayBets = parlayData.bets
                        console.log('before sorting - 1', parlayBets)
                        getParlayTeaserBetsData(parlayBets).then(async (response) => {
                            console.log('before sorting - 2', response)
                            const addParlayObj = {
                                createdAt: missingParlay.createdAt,
                                id: missingParlay.id,
                                timestamp: Math.min(...response),
                                type: 'parlay'
                            }

                            console.log('before sorting - 3', addParlayObj)
                            const userParlayCollection = await firestore
                                .collection("users_v2")
                                .doc(userId)
                                .collection(userBetType)
                                .doc(missingParlay.id)
                            await userParlayCollection.set({
                                ...addParlayObj
                            })

                        })
                    })
                }
            }
            if (missingV2Bets.teasers?.length > 0) {
                if (missingV2Bets.teasers?.length > 0) {
                    missingTeaserPromises = missingV2Bets.teasers.map(async (missingTeaser) => {
                        const userBetType = !missingTeaser?.isActive ? "archive" : "activity"
                        const teaserref = await firestore
                            .collection("teasers")
                            .doc(missingTeaser.id)
                            .get()

                        const teaserData = teaserref.data()
                        const teaserBets = teaserData.bets
                        console.log('before sorting - 1', teaserBets)
                        getParlayTeaserBetsData(teaserBets).then(async (response) => {
                            console.log('before sorting - 2', response)
                            const addTeaserObj = {
                                createdAt: missingTeaser.createdAt,
                                id: missingTeaser.id,
                                timestamp: Math.min(...response),
                                type: 'teaser'
                            }

                            console.log('before sorting - 3', addTeaserObj)
                            const userTeaserCollection = firestore
                                .collection("users_v2")
                                .doc(userId)
                                .collection(userBetType)
                                .doc(missingTeaser.id)
                            await userTeaserCollection.set({
                                ...addTeaserObj
                            })

                        })
                    })
                }
            }

            await Promise.all(missingBetPromises, missingParlayPromises, missingTeaserPromises)

            updateFlash({
                message: `missing bets created in Users_v2 for user ${userId}`,
                success: true,
            });

            setTimeout(() => {
                fetchUser()
            }, 2000)
        }
        else {
            updateFlash({
                message: `No missing bets found for user ${userId}`,
                success: false,
            })

            setTimeout(() => {
                fetchUser()
            }, 500)
        }

    }

    return (
        <LayoutPage
            subtitle={
                <>
                    <Breadcrumbs location={location} currentPage={userId} />
                    {
                        <div style={{ display: "flex", fontSize: "1em", margin: 10 }}>

                            <button style={{ fontSize: "1em", marginLeft: "5em" }}
                                className="btn-primary col-md-3"
                                onClick={e => confirm(e, 'deleteInvalidBets')}
                            >
                                {`Delete Invalid (${Number((invalidV2Bets?.bets?.count || 0) + (invalidV2Bets?.parlays?.count || 0) + (invalidV2Bets?.teasers?.count || 0))})`}
                            </button>
                            <button style={{ fontSize: "1em", marginLeft: ".5em" }}
                                className="btn-primary col-md-3"
                                onClick={e => confirm(e, 'deleteAllBets')}
                            >
                                Clear Test
                            </button>
                            <button style={{ fontSize: "1em", marginLeft: ".5em" }}
                                className="btn-primary col-md-3"
                                onClick={e => confirm(e, 'replicateBets')}
                            >
                                Replicate Bets
                            </button>
                            <button style={{ fontSize: "1em", marginLeft: ".5em" }}
                                className="btn-primary col-md-3"
                                onClick={e => confirm(e, 'fixMissingBets')}
                            >
                                {`Fix Missing (${missingV2Bets.count})`}
                            </button>
                            <button style={{ fontSize: "1em", marginLeft: ".5em" }}
                                className="btn-primary col-md-3"
                                onClick={e => confirm(e, 'deleteStubBets')}
                            >
                                {`Delete Stub Bets (${missingUserBets.count})`}
                            </button>
                            <button style={{ fontSize: "1em", marginLeft: ".5em" }}
                                className="btn-primary col-md-3"
                                onClick={e => confirm(e, 'deleteParlayTeasersMissingBets')}
                            >
                                {`Repair Parlays/Teasers (${(leftOverParlayTeaserBets?.length || 0) + (corruptedParlayTeasersBets?.length || 0)})`}
                            </button>
                            <button style={{ fontSize: "1em", marginLeft: ".5em" }}
                                className="btn-primary col-md-3"
                                onClick={e => confirm(e, 'deleteFloatingBets')}
                            >
                                {`Delete Floating (${floatingBetsList.length})`}
                            </button>
                        </div>
                    }
                </>
            }
        >
            {
                userLoading === false &&
                    betListLoading === false &&
                    v2ActiveLoading === false &&
                    v2ArchiveLoading === false &&
                    validatingBets === false &&
                    analyzingParlaysAndTeasers === false &&
                    loadingFloatingBets === false ?
                    (
                        user?.userExists ?

                            <div className="UserPage">
                                <div className="UserPage-secondaryControl">
                                </div>

                                <UserHeader
                                    user={user}
                                    missingV2Bets={missingV2Bets}
                                    missingUserBets={missingUserBets}
                                    redundantParlayTeaserBetCount={leftOverParlayTeaserBets?.length || 0}
                                    corruptedBetCount={corruptedParlayTeasersBets?.length || 0}
                                    floatingBetCount={floatingBetsList?.length || 0} 
                                    invalidV2BetCount={invalidV2Bets?.bets?.count || 0 + invalidV2Bets?.parlays?.count || 0 + invalidV2Bets?.teasers?.count || 0}
                                />
                                <UserComparison
                                    redundantParlayTeaserBets={leftOverParlayTeaserBets}
                                    corruptedParlayTeasersBets={corruptedParlayTeasersBets}
                                    invalidBets={invalidV2Bets?.bets?.invalidDocuments}
                                    invalidParlays={invalidV2Bets?.parlays?.invalidDocuments}
                                    invalidTeasers={invalidV2Bets?.teasers?.invalidDocuments}
                                    v2BetCount={v2ActiveBets.filter((bet) => bet.type === "bet").length + v2ArchiveBets.filter((bet) => bet.type === "bet").length}
                                    v2ParlayCount={v2ActiveBets.filter((parlay) => parlay.type === "parlay").length + v2ArchiveBets.filter((parlay) => parlay.type === "parlay").length}
                                    v2TeaserCount={v2ActiveBets.filter((teaser) => teaser.type === "teaser").length + v2ArchiveBets.filter((teaser) => teaser.type === "teaser").length}
                                    v2Ways={v2ActiveBets.filter((bet) => bet.type === "parlay" || bet.type === "teaser").reduce((accumulator, object) => { return accumulator + (object.content?.bets.length ? object.content.bets.length : 0) }, 0) + v2ArchiveBets.filter((bet) => bet.type === "parlay" || bet.type === "teaser").reduce((accumulator, object) => { return accumulator + (object.content?.bets.length ? object.content.bets.length : 0) }, 0)}
                                    betListWays={(betListBets.parlays.length > 0 ? betListBets.parlays.reduce((accumulator, object) => { return accumulator + object?.bets.length }, 0) : 0) + (betListBets.teasers.length > 0 ? betListBets.teasers.reduce((accumulator, object) => { return accumulator + object?.bets.length }, 0) : 0)}
                                    betListBetCount={betListBets.bets.length}
                                    betListParlayCount={betListBets.parlays.length}
                                    betListTeaserCount={betListBets.teasers.length}
                                    missingV2Bets={missingV2Bets}
                                    missingUserBets={missingUserBets}
                                    floatingBets={floatingBetsList}
                                />
                                <UserBets
                                    activeBets={v2ActiveBets.filter((bet) => bet.type === "bet")}
                                    archiveBets={v2ArchiveBets.filter((bet) => bet.type === "bet")}
                                    activeParlays={v2ActiveBets.filter((parlay) => parlay.type === "parlay")}
                                    archiveParlays={v2ArchiveBets.filter((parlay) => parlay.type === "parlay")}
                                    activeTeasers={v2ActiveBets.filter((teaser) => teaser.type === "teaser")}
                                    archiveTeasers={v2ArchiveBets.filter((teaser) => teaser.type === "teaser")}
                                    title={"Users Bets"}
                                />
                                <UserBets
                                    activeBets={betListBets.bets.filter((bet) => bet.isActive)}
                                    archiveBets={betListBets.bets.filter((bet) => !bet.isActive)}
                                    activeParlays={betListBets.parlays.filter((parlay) => parlay.isActive)}
                                    archiveParlays={betListBets.parlays.filter((parlay) => !parlay.isActive)}
                                    activeTeasers={betListBets.teasers.filter((teaser) => teaser.isActive)}
                                    archiveTeasers={betListBets.teasers.filter((teaser) => !teaser.isActive)}
                                    title={"Bets Collection"}
                                />
                            </div> :

                            user ?

                                (<NoResults
                                    label={`User ${userId} does not exist`}
                                />) :
                                <Spinner />
                    ) : <Spinner />
            }
        </LayoutPage>
    );
};

export default withDialog(UserByIdPage);
