import React, { useState, useEffect } from "react";
import { Table } from "components/common/Table/Table";
import { tableModal } from "./tableModal";
import MultiSelect from "components/common/Form/MultiSelect/MultiSelect";
import { options } from "utils/helpers";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import { Spinner } from "components/common/Spinner/Spinner";
import { dateToMMDDYYYY } from "pages/events/EventListPage";
import * as moment from "moment";
import "./DuplicateSearch.scss";
import { NoResults } from "components/common/NoResults";


export const DuplicateSearch = ({
    functions,
    event,
    closeModal,
    buttons,
    onSelect,
}) => {
    const [isLoading, setLoading] = useState(true);
    const [sortBy, setSortBy] = useState("name");
    const [sortOrder, setSortOrder] = useState("asc");
    const [from, setFrom] = useState(
        moment.unix(event.startAtTimestamp).subtract(3, "days").format("YYYY-MM-DD")
    );
    const [to, setTo] = useState(
        moment.unix(event.startAtTimestamp).add(3, "days").format("YYYY-MM-DD")
    );
    const [list, setList] = useState([]);
    const [filters, setFilters] = useState(['sport', 'league']);
    const [filteredList, setFilteredList] = useState([]);
    const [inputValue, setInputValue] = useState(
        options(
            [
                {
                    id: 1,
                    name: "sport",
                },
                {
                    id: 2,
                    name: "league",
                },
                {
                    id: 3,
                    name: "awayTeam",
                },
                {
                    id: 4,
                    name: "homeTeam",
                },
                {
                    id: 5,
                    name: "sdioId",
                },
                {
                    id: 6,
                    name: "bovadaId",
                },
                {
                    id: 7,
                    name: "jsonoddsId",
                },
                {
                    id: 8,
                    name: "matchingId",
                }
            ],
            [{id: 1}, {id:2}],
            false
        )
    );

    useEffect(() => {
        if (inputValue.length)
            setFilters(inputValue.filter(item => item.checked).map(item => item.value));
    }, [inputValue]);

    useEffect(() => {
        if (list.length) {
            setFilteredList(applyFilters(list));
        }
    }, [filters]);

    const applyFilters = (list) => {
        if (filters.length) {
            return list.filter(item => {
                let fit = true;
                for(const k of filters) {
                    if (item[k]!==event[k]) {
                        fit = false;
                        break;
                    } 
                }
                return fit;
            });
        } 
        return list;
    }
    
    // on mount
    useEffect(() => {
        fetchEvents();
    }, []);


    const fetchEvents = () => {
        setLoading(true);
        functions.httpsCallable('getGrid')({
            show: "all",
            from: dateToMMDDYYYY(from),
            to: dateToMMDDYYYY(to),
        })
            .then((res) => {
                setLoading(false);
                const newList = res.data.data.filter(item=>item.id !== event.id);
                setList(newList);
                setFilteredList(applySort(applyFilters(newList), sortBy, sortOrder));                
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    }

    const handleSearch = (e) => {
        console.log('did')
        fetchEvents();
    }

    const sortFunc = (by, order) => {
        setSortBy(by);
        setSortOrder(order);
        setFilteredList(applySort(filteredList, by, order));
    };

    const applySort = (arr, by, order) => {
        const orderedList = arr.sort((a, b) => (a[by] > b[by] ? 1 : -1));
        return order === "asc" 
            ? orderedList 
            : orderedList.reverse();
    };

    return (
        <div className="DuplicateSearch">
            <div className="DuplicateSearch-content">
                <div className="row">
                    <div className="col col-sm-5">
                        <TextInput
                            inputData={{
                                title: "Show events starting from this date",
                                type: "date",
                                placeholder: "Events since",
                                value: from || "",
                            }}
                            onChange={setFrom}
                        />
                    </div>
                    <div className="col col-sm-5">
                        <TextInput
                            inputData={{
                                title: "Show events ending this date",
                                type: "date",
                                placeholder: "Events until",
                                value: to || "",
                            }}
                            onChange={setTo}
                        />
                    </div>
                    <div className="col col-sm-2">
                        <button
                            className="btn-primary Search-button"
                            onClick={handleSearch}
                        >
                            Fetch
                        </button>
                    </div>
                </div>
                {!!(list && list.length && !isLoading) && (
                <div className="row">
                    {/**
                     * Filter fields
                     */}
                    <br/>
                    <div className="col" style={{paddingBottom: "0"}}>
                        <div className="DuplicateSearch-Filter">
                            <span>Filter by:</span>
                            <MultiSelect
                                placeholder="Select parameters to use in search"
                                title="Parameters used in search"
                                options={inputValue}
                                code="fields"
                                value={inputValue
                                    .filter((op) => op.checked)
                                    .map((op) => op.id)
                                    .join(",")}
                                onChange={(val, checked) =>
                                    setInputValue(
                                        inputValue.map((o) => {
                                            if (o.value === val) {
                                                o.checked = checked;
                                            }
                                            return o;
                                        })
                                    )
                                }
                                filterBy="value"
                                optionKey="id"
                            />
                        </div>
                    </div>
                    <div className="col">
                        {!!filteredList.length ? (
                            <Table
                                sortBy={sortBy}
                                sortOrder={sortOrder}
                                sortFunc={sortFunc}
                                subject="events"
                                headList={tableModal}
                                selected={[]}
                                list={filteredList}
                                idType="id"
                                isCustomClick={true}
                                handleCustomClick={(e, obj) => onSelect(obj)}
                            />                        
                        ) : <NoResults/>}
                    </div>
                </div>
                )}
                {isLoading && <Spinner />}
            </div>
        </div>
    );
};

/**
 * Set Default Props
 *
 */
DuplicateSearch.defaultProps = {
    buttons: true,
    children: null,
};
