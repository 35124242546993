import React from "react";
import { BlankPage } from "layouts/BlankPage";
import "./LoadingPage.scss";

const LoadingPage = () => (
    <BlankPage>
        <div className="LoadingPage">
            <div className="spinner LoadingPage-spinner"/>
        </div>
    </BlankPage>
);

export default LoadingPage;
