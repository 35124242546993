import React from "react";

export const Payments = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 5H17.75C19.2688 5 20.5 6.07235 20.5 7.39516V16.1048C20.5 17.4276 19.2688 18.5 17.75 18.5H5.75C4.23122 18.5 3 17.4276 3 16.1048V7.39516C3 6.07235 4.23122 5 5.75 5ZM5.75 6.5C5.05964 6.5 4.5 6.9701 4.5 7.55V8.75H19V7.55C19 6.9701 18.4404 6.5 17.75 6.5H5.75ZM19 10.75H4.5V15.95C4.5 16.5299 5.05964 17 5.75 17H17.75C18.4404 17 19 16.5299 19 15.95V10.75ZM6.75 15.25C6.75 14.9739 6.97386 14.75 7.25 14.75H11.25C11.5261 14.75 11.75 14.9739 11.75 15.25C11.75 15.5261 11.5261 15.75 11.25 15.75H7.25C6.97386 15.75 6.75 15.5261 6.75 15.25ZM7.25 12.75C6.97386 12.75 6.75 12.9739 6.75 13.25C6.75 13.5261 6.97386 13.75 7.25 13.75H13.25C13.5261 13.75 13.75 13.5261 13.75 13.25C13.75 12.9739 13.5261 12.75 13.25 12.75H7.25Z"
        fill="#999999"
      />
    </svg>
  );
};
