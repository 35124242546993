import React from "react";

export const Chip = () => {
  return (
    <svg
      width="35"
      height="25"
      viewBox="0 0 35 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.7669 18.5617H34.9996V12.9322H23.3483V16.3696L25.7669 18.5617ZM18.0839 16.3693V25H29.1749C32.3926 25 34.9996 23.0675 34.9996 20.6835V19.4248H25.2113L21.8463 16.3693H18.0839ZM23.3483 12.0681H35V6.43682H25.7669L23.3483 8.62895V12.0681ZM9.23154 18.5617L11.6505 16.3693V12.9322H0V18.562H9.23154V18.5617ZM0 4.3139V5.57467H9.78714L13.1545 8.62924H16.9157V0H5.82506C2.60701 0 0 1.93194 0 4.3139ZM9.23154 6.43682H0V12.0681H11.6505V8.62924L9.23154 6.43682ZM21.8463 8.62924L25.2113 5.57467H35V4.3139C35 1.93222 32.3926 0 29.1753 0H18.0839V8.62895H21.8463V8.62924ZM13.1545 16.3693L9.78714 19.4248H0V20.6835C0 23.0675 2.60701 25 5.82467 25H16.9153V16.3693H13.1545Z"
        fill="#444444"
      />
    </svg>
  );
};
