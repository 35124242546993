import React, { useContext, useState, useEffect } from "react";
//import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { headList } from "./model";
import { LayoutPage } from "layouts/LayoutPage";
import { NoResults } from "components/common/NoResults";
import { Table, SelectModeButton } from "components/common/Table/Table";
import { Spinner } from "components/common/Spinner/Spinner";
import { simpleOptions, getParams, serialize } from "utils/helpers";
import { Select } from "components/common/Form/Select/Select";
import "./TeamListPage.scss";

/**
 * Generate filters options
 *
 */
const filterOptions = simpleOptions([
    "All",
    "NFL",
    "NCAAF",
    "XFL",
    "CFL",
    "NBA",
    "NCAAB",
    "WNBA",
    "MLB",
    "KBO",
    "NPB",
    "NHL",
    "Soccer",
    "UFC",
]);

/**
 *  Teams List
 *
 */
const TeamListPage = ({ history, location }) => {
    //    const { functions } = useContext(AuthContext);
    const { updateMeta, updateFlash, teams, fetchTeams } = useContext(
        StoreContext
    );
    const [list, setList] = useState(teams.list);
    const [isLoading, setLoading] = useState(teams.isFetching || false);
    const [sortBy, setSortBy] = useState("index");
    const [sortOrder, setSortOrder] = useState("asc");

    const [selected, setSelected] = useState([]);
    const [isSelectMode, toggleSelectMode] = useState(false);

    const query = location.search
        ? getParams(location)
        : {
              filter: "nfl",
          };

    const filter = query.filter || "nfl";

    const handleSportChange = (newValue) => {
        if (newValue === filter) return;
        history.push(serialize({ filter: newValue }));
    };

    const sortFunc = (by, order) => {
        setSortBy(by);
        setSortOrder(order);
        sortList(list, by, order);
    };

    const sortList = (arr, by, order) => {
        const orderedList = arr.sort((a, b) => (a[by] > b[by] ? 1 : -1));
        order === "asc" ? setList(orderedList) : setList(orderedList.reverse());
    };

    useEffect(() => {
        updateMeta({
            title: "Teams",
        });
        if (teams.list.length) sortList(teams.list, sortBy, sortOrder);
    }, []);

    useEffect(() => {
        sortList(teams.list, sortBy, sortOrder);
    }, [teams.list]);

    useEffect(() => {
        setLoading(teams.isFetching);
    }, [teams.isFetching]);

    const gotoTeam = (e, obj) => {
        e.preventDefault();
        history.push(`teams/${obj.id || "create"}`);
    };

    const handleSelect = (ids, toggle) => {
        if (toggle) {
            const index = selected.indexOf(ids);
            index === -1
                ? setSelected(selected.concat(ids))
                : setSelected(
                      [].concat(
                          selected.slice(0, index),
                          selected.slice(index + 1)
                      )
                  );
            return;
        } else {
            if (!ids.length) {
                setSelected([]);
                return;
            }
            const newRawSelected = selected.concat(ids);
            // filter out duplicates
            setSelected(
                newRawSelected.filter(
                    (elem, pos, arr) => arr.indexOf(elem) == pos
                )
            );
        }
    };

    const Subtitle = () => (
        <div
            className="TableSubtitle"
            style={{ width: "100%", justifyContent: "flex-end" }}
        >
            {isSelectMode && selected.length === 2 && (
                <button
                    className="btn-secondary"
                    style={{ marginRight: "1rem" }}
                    onClick={(e) =>
                        history.push(
                            `/teams/compare?ids=${selected.join(",")}`
                        )
                    }
                    title={`Compare selected`}
                >
                    Compare
                </button>
            )}
            <button className="btn-primary" onClick={(e) => gotoTeam(e, {})}>
                Create
            </button>
        </div>
    );

    return (
        <LayoutPage title="Teams" subtitle={<Subtitle />}>
            <div className="row FetchSettings">
                <div
                    className="col"
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                    {list && !!list.length && (
                        <SelectModeButton
                            action={() => toggleSelectMode(!isSelectMode)}
                            isActive={isSelectMode}
                        />
                    )}
                    <Select
                        placeholder="Select filter"
                        title="Sport"
                        options={filterOptions}
                        onChange={handleSportChange}
                        optionKey="value"
                        value={filter}
                        code="filter"
                    />
                </div>
            </div>

            {/* list table */}
            {!isLoading && list && list.length && filter ? (
                <Table
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    sortFunc={sortFunc}
                    subject="team"
                    headList={headList}
                    list={
                        filter === "all"
                            ? list
                            : list.filter((item) =>
                                  filter === "soccer"
                                      ? item.sport === filter
                                      : item.league === filter
                              )
                    }
                    pathBase="/teams"
                    idType="id"
                    history={history}
                    isCustomClick={true}
                    handleCustomClick={gotoTeam}
                    isSelectMode={isSelectMode}
                    selectFunc={handleSelect}
                    selected={selected}
                />
            ) : (
                !isLoading && (!list || !list.length) && <NoResults />
            )}

            {isLoading && <Spinner />}
        </LayoutPage>
    );
};

export default TeamListPage;
