import React, { useState, useContext } from "react";
import { AuthContext } from "store/AuthProvider";

import { NavLink } from "react-router-dom";
import { menuItems } from "./model";
import { Icon } from "components/common/Icons/Icon";

import "./SideMenu.scss";

export const SideMenu = ({ isMobileNav, toggleMobileNav }) => {
    const { auth } = useContext(AuthContext);
    const [submenuItem, setSubmenuItem] = useState(null);

    const getSubmenuItem = item => {
        setSubmenuItem(item);
    };

    const classList = ["SideMenu"];
    if (isMobileNav) classList.push("active");

    return (
        <nav className={classList.join(" ")} onClick={e => toggleMobileNav(false)}>
            <NavLink to="/" className="logo">
                <img src="/images/icon.png" alt="HEATR Logo" />
            </NavLink>

            <ul className="SideMenu-list">
                {menuItems.map(item => {
                    const hasSubmenu = item.submenu ? "submenu" : "";

                    const hasSubmenuActive =
                        item.submenu && submenuItem === item.label
                            ? "submenu-active"
                            : "";
                    console.log("Side Menu Item:", item)
                    return (
                        <li
                            key={item.to}
                            className={`SideMenu-item ${hasSubmenu} ${hasSubmenuActive}`}
                            onClick={e => getSubmenuItem(item.label)}
                        >
                            <NavLink
                                className="SideMenu-link"
                                to={item.to}
                                exact={item.to === "/" || item.exact}
                            >
                                <Icon name={item.icon} />
                                {item.label}
                            </NavLink>

                            {item.submenu && submenuItem === item.label && (
                                <ul>
                                    <li className="submenu-item">
                                        {item.submenu.map(submenu => {
                                            return (
                                                <NavLink
                                                    key={submenu.to}
                                                    className="SideMenu-link"
                                                    to={submenu.to}
                                                    exact={submenu.to === "/"}
                                                >
                                                    <Icon name={submenu.icon} />
                                                    {submenu.label}
                                                </NavLink>
                                            );
                                        })}
                                    </li>
                                </ul>
                            )}
                        </li>
                    );
                })}

                <li className="SideMenu-item">
                    <a
                        className="SideMenu-link"
                        href="https://heatr-api-docs.firebaseapp.com/"
                        target="_blank"
                        rel="noreferrer" 
                    >
                        <Icon name="address" />
                        API Docs
                    </a>
                </li>
            </ul>

            <div className="SideMenu-footer">
                <p>Version 1.0.8</p>
                <a onClick={(e) => auth.signOut()}>Sign out</a>
            </div>
        </nav>
    );
};
