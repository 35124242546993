import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { LayoutPage } from "layouts/LayoutPage";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { NoResults } from "components/common/NoResults";
import { EventHeader } from "components/EventPage/EventHeader";
import { EventOdds } from "components/EventPage/EventOdds";
import { EventScores } from "components/EventPage/EventScores";
import { EventMisc } from "components/EventPage/EventMisc";
import { Spinner } from "components/common/Spinner/Spinner";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import { getParams } from "utils/helpers";
import moment from "moment-timezone";
import "./EventPage.scss";

const EventByIdPage = ({ history, location }) => {
    const query = location.search ? getParams(location) : { eventId: null };

    const { functions } = useContext(AuthContext);
    const { updateMeta, updateFlash, showSpinner } = useContext(StoreContext);

    const [eventId, setEventId] = useState(query.id || "");
    const [event, setEvent] = useState(null);
    const [isLoading, setLoading] = useState(!!query.id);

    const fetchEvent = () => {
        setLoading(true);
        functions
            .httpsCallable("getGrid")({ ids: eventId })
            .then((res) => {
                setLoading(false);
                if (res.data && res.data.data && res.data.data.length)
                    setEvent(res.data.data[0]);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        updateMeta({
            title: "Search Event by ID",
        });
        if (eventId)
            fetchEvent();
    }, []);

    useEffect(() => {
        const query = location.search ? getParams(location) : { eventId: "" };
        if (query.id && query.id !== eventId) {
            fetchEvent(eventId);
        }
    }, [location.search]);
    
    const fetchScores = (e) => {
        showSpinner(true);
        let date;
        if (event.sport === 'soccer') {
            date = event.startAt.split('T')[0];
        } else {
            // convert to eastern timezone
            const dateWithZ = event.startAt.indexOf('Z')===-1 
                ? event.startAt+'Z'
                : event.startAt;
            date = moment.utc(dateWithZ).tz('America/New_York').format("YYYY-MM-DD");
        }
        if (!date) {
            updateFlash({ success: false, message: `Invalid event's startAt value` });
            showSpinner(false);
        }
        
        functions
            .httpsCallable("refetchScores")({
                ids: event.id,
                date 
            })
            .then((res) => {
                showSpinner(false);
                if (res.data.success === true) {
                    updateFlash({
                        message: "Scores data updated successfully",
                        success: true,
                    });
                    fetchEvent();
                } else updateFlash(res.data);
            })
            .catch((err) => {
                showSpinner(false);
                updateFlash({
                    success: false,
                    message: `Error occur while requesting event's scores`,
                });
                console.log(err);
            });
    };

    const handleSubmit = (e) => {
        if (!eventId) {
            updateFlash({
                message: `Event Id value is required`,
                success: false,
            });
            return;
        }
        history.push(`/events/search?id=${eventId}`);
        setEvent(null);
        fetchEvent(eventId);
    };

    return (
        <LayoutPage
            subtitle={
                <Breadcrumbs
                    location={location}
                    currentPage={
                        <div style={{display: "flex", fontSize: "1em"}}>
                            <TextInput
                                inputData={{
                                    title: "Enter event id from grid",
                                    placeholder: "Event ID",
                                    value: eventId || "",
                                    type: "text",
                                }}
                                code="name"
                                onChange={setEventId}
                            />
                            <button style={{fontSize: "1em", marginLeft: ".5em"}}
                                className="btn-primary"
                                onClick={handleSubmit}
                            >
                                Search
                            </button>
                        </div>
                    }
                />
            }
        >
            {event ? (
                <div className="EventPage">
                    <div className="EventPage-secondaryControl">
                        <button className="btn-secondary" onClick={fetchScores}>
                            Refetch Scores
                        </button>
                    </div>
                    <EventHeader event={event} />
                    <EventOdds event={event} />
                    <EventScores event={event} />
                    <EventMisc event={event} />
                </div>
            ) : isLoading ? (
                <Spinner />
            ) : (
                <NoResults
                    label={(event && eventId) ? null : "Enter event id and press search"}
                />
            )}
        </LayoutPage>
    );
};

export default EventByIdPage;
