import React from "react";
import "./UserComparison.scss";
import { NoResults } from "components/common/NoResults";

export const UserComparison = ({
    redundantParlayTeaserBets,
    corruptedParlayTeasersBets,
    invalidBets,
    invalidParlays,
    invalidTeasers,
    v2BetCount,
    v2ParlayCount,
    v2TeaserCount,
    v2Ways,
    betListBetCount,
    betListParlayCount,
    betListTeaserCount,
    betListWays,
    missingV2Bets,
    missingUserBets,
    floatingBets
}) => {


    const RenderBets = ({ bets, label, betType }) => {
        if (!bets) {
            return (
                <div className="row">
                    <h4>{label} - 0</h4>
                    <NoResults label="Empty" height="100px" />
                </div>
            )
        }
        return (
            <div className="row">
                <div className="UserComparison-segment"><div>{label}</div><div>{bets.length}</div></div>
                {
                    bets.map((bet, j) => {
                        return (
                            <div key={j} className="UserComparison-segment">
                                <div>&emsp;&emsp;{betType}</div>
                                <div>{bet?.id || bet?.documentID || "-"}</div>
                            </div>
                        );
                    })
                }
                <br></br>
            </div>
        )
    }
    
    return (
        
        <div className={`UserComparison`}>
            <h1 className="adjusted">User Bet Summary</h1>
            <div className="row">
                <div className={"col col-sm-6"}>
                    <div className="UserComparison-segment"><div>User Bet IDs</div> <div>{v2BetCount + v2Ways}</div></div>
                    <div className="UserComparison-segment"><div>User Bets</div><div>{v2BetCount}</div></div>
                    <div className="UserComparison-segment"><div>User Parlays</div> <div>{v2ParlayCount}</div></div>
                    <div className="UserComparison-segment"><div>User Teasers</div> <div>{v2TeaserCount}</div></div>
                    <div className="UserComparison-segment"><div>User Wagers</div> <div>{v2BetCount + v2ParlayCount + v2TeaserCount}</div></div>
                </div>

                <div className={"col col-sm-6"}>
                    <div className="UserComparison-segment"><div>Bet Collection Bet IDs</div> <div>{betListWays + betListBetCount}</div></div>
                    <div className="UserComparison-segment"><div>Bet Collection Bets</div> <div>{betListBetCount}</div></div>
                    <div className="UserComparison-segment"><div>Bet Collection Parlays</div> <div>{betListParlayCount}</div></div>
                    <div className="UserComparison-segment"><div>Bet Collection Teasers</div> <div>{betListTeaserCount}</div></div>
                    <div className="UserComparison-segment"><div>Bet Collection Wagers</div> <div>{betListBetCount + betListParlayCount + betListTeaserCount}</div></div> 
                </div>
            </div>

            <div className="row">
                <div className={"col col-sm-6"}>
                    <RenderBets bets={[...missingUserBets.bets]} label={"Stub Bets"} betType={"bet"} />
                    <RenderBets bets={[...missingUserBets.parlays]} label={"Stub Parlays"} betType={"parlay"} />
                    <RenderBets bets={[...missingUserBets.teasers]} label={"Stub Teasers"} betType={"teaser"} />
                    <div className="UserComparison-segment"><div>Undeleted Bets</div> <div>{redundantParlayTeaserBets?.length}</div></div>
                    <div className="UserComparison-segment"><div>Corrupted Bets</div> <div>{corruptedParlayTeasersBets?.length}</div></div>
                    <div className="UserComparison-segment"><div>Floating Bets</div> <div>{floatingBets.length}</div></div>
                </div>
                <div className={"col col-sm-6"}>
                    <RenderBets bets={[...missingV2Bets.bets]} label={"Missing Bets"} betType={"bet"} />
                    <RenderBets bets={[...missingV2Bets.parlays]} label={"Missing Parlays"} betType={"parlay"} />
                    <RenderBets bets={[...missingV2Bets.teasers]} label={"Missing Teasers"} betType={"teaser"} />
                    
                </div>
            </div>
        </div>
    )
}


