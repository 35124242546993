import React from "react";
import "./UserHeader.scss";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const UserHeader = ({
    user,
    missingV2Bets,
    missingUserBets,
    invalidV2BetCount,
    redundantParlayTeaserBetCount,
    corruptedBetCount,
    floatingBetCount
}) => {
    let userName = "";
    if (user.firstName && user.lastName) {
        userName = user.firstName + " " + user.lastName
    }
    else if (user.firstName || user.lastName) {
        userName = user.firstName ? user.firstName : user.lastName
    }
    else {
        userName = "-"
    }
    if (user.isAnonymous) {
        userName = 'Anonymous'
    }
    if (!user.uid) {
        userName = 'Invalid User'
    }
    return (
        <div>
            <div className={'UserHeader'}>
                <div className="UserHeader-top">
                    <div>{moment(user.createdAt).format("ddd MM/DD/YYYY")}</div>
                    <div>{user.uid}</div>
                    <div>{moment(user.lastLogin).format("ddd MM/DD/YYYY hh:mm")}</div>
                </div>

                <div className="UserHeader-bottom">
                    <h3 className= {user.isAnonymous ? "isAnonymousText" : ""}>{userName}</h3>
                    {
                        missingV2Bets.count === 0 && missingUserBets.count === 0 && invalidV2BetCount === 0 && floatingBetCount === 0 && redundantParlayTeaserBetCount === 0 && corruptedBetCount === 0 ?
                        <div className="UserHeader-checkMarker"><FontAwesomeIcon className="UserHeader-statusIcon" icon={['fas', 'check']} size="lg" /></div> :
                        <div className="UserHeader-xMarker"><FontAwesomeIcon className="UserHeader-statusIcon" icon={['fas', 'xmark']} size="lg" /></div>
                    }
                    <h3>{user.email === "" || !user.email ? "-" : user.email}</h3>
                </div>
            </div>
        </div>
        
    );
};
