import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { LayoutPage } from "layouts/LayoutPage";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { NoResults } from "components/common/NoResults";
import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import { Select } from "components/common/Form/Select/Select";
import Confirmation from "modals/Confirmation";
import { simpleOptions } from "utils/helpers";
import "./LeaguePage.scss";
const CryptoJS = require("crypto-js");


/**
 * Generate sports options
 *
 */
const sportsOptions = simpleOptions([
    "football",
    "basketball",
    "hockey",
    "baseball",
    "soccer",
    "MMA",
]);

const LeaguePage = ({
    history,
    location,
    match: {
        params: { leagueId },
    },
    closeModal,
    openModal,
}) => {
    const { functions, theKey } = useContext(AuthContext);
    const { updateMeta, updateFlash, leagues, fetchLeagues, showSpinner } = useContext(
        StoreContext
    );
    const [isLoading, setLoading] = useState(leagues.isFetching || false);
    const [league, setLeague] = useState(null);

    const [idCode, setIdCode] = useState("");
    const [sport, setSport] = useState("");
    const [name, setName] = useState("");
    const [shortName, setShortName] = useState("");
    const [officialName, setOfficialName] = useState("");
    const [bovadaId, setBovadaId] = useState("");
    const [jsonOddsId, setJsonOddsId] = useState("");
    const [jsonOddsLeague, setJsonOddsLeague] = useState("");
    const [version, setVersion] = useState("");
    const [sdioKey, setSdioKey] = useState("");

    useEffect(() => {
        initValues(league || {});
    }, [league]);

    const initValues = (obj) => {
        setIdCode(obj.idCode || "");
        setSport(obj.sport || "");
        setName(obj.name || "");
        setShortName(obj.shortName || "");
        setOfficialName(obj.officialName || "");
        setBovadaId(obj.bovadaId || "");
        setJsonOddsId(obj.jsonOddsId || "");
        setJsonOddsLeague(obj.jsonOddsLeague || "");
        setVersion(obj.version || "");
        setSdioKey(getSdioKey(obj.sdioKey))
    }

    const getSdioKey = (key) => {
        if (!key)
            return "";
        const bytes  = CryptoJS.AES.decrypt(key, theKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    } 

    useEffect(() => {
        updateMeta({
            title: "League",
        });
    }, []);

    useEffect(() => {
        if (leagues.isFetching) return;
        setLeague(
            leagueId==='create' ? {} : leagues.list.find((item) => item.id === leagueId)
        );
    }, [leagues.isFetching]);

    /**
     *  Confirm func
     *
     */
    const confirm = (e) => {
        e.preventDefault();

        openModal({
            title: "Confirmation",
            content: (
                <Confirmation
                    message={`Do you want to remove this league?`}
                    closeModal={closeModal}
                    buttons={true}
                    onConfirm={(e) => {
                        closeModal();
                        handleDelete(e, league.id);
                    }}
                />
            ),
        });
    };

    /**
     * Handle create/update a league
     *  
     */
    const handleSubmit = (e) => {
        // init body
        const body = {
            sport,
            name,
            shortName,
            officialName,
            bovadaId,
            jsonOddsId,
            jsonOddsLeague,
            version,
            sdioKey: sdioKey ? CryptoJS.AES.encrypt(sdioKey, theKey).toString() : "" 
        };
        // init data 
        let data = {};
        // init endpoint
        let endpointName = `leagueUpdate`;
        if (league.id) {
            // update
            data.leagueId = league.id;
        } else {
            // create
            endpointName = `leagueCreate`;
            body.idCode = idCode;
        }
        // attach body
        data.body = JSON.stringify(body);

        showSpinner(true);
        functions
            .httpsCallable(endpointName)(data)
            .then((res) => {
                showSpinner(false);
                if (res.data.success === true) {
                    updateFlash({ message: res.data.message, success: true });
                    // update cached leagues data 
                    fetchLeagues();
                    if (!league.id) history.push(`/leagues`);
                } else updateFlash(res.data);
            })
            .catch((err) => {
                showSpinner(false);
                updateFlash({ success: false, message: `Error occur while processing the request` });
                console.log(err);
            });
    };

    /**
     * Handle delete a league
     *  
     */
    const handleDelete = (e, id) => {
        showSpinner(true);
        functions
            .httpsCallable("leagueDelete")({ leagueId: id })
            .then((res) => {
                showSpinner(false);
                if (res.data.success === true) {
                    updateFlash({ message: res.data.message, success: true });
                    // update cached leagues data 
                    fetchLeagues();
                    history.push(`/leagues`); 
                } else updateFlash(res.data);
            })
            .catch((err) => {
                showSpinner(false);
                updateFlash({ success: false, message: `Error occur while deleting the league` });
                console.log(err);
            });
    };

    return (
        <LayoutPage
            subtitle={
                <Breadcrumbs location={location} currentPage={leagueId} />
            }
        >
            {(league && sportsOptions && (sport || leagueId==='create')) ? (
                <div className="LeaguePage">
                    <div className="row League">
                        <div className="col col-sm-6">
                            <label>Select sport</label>
                            <Select
                                placeholder="Select sport"
                                title="Sport"
                                options={sportsOptions}
                                onChange={setSport}
                                optionKey="value"
                                value={sport}
                                code="sport"
                            />
                        </div>
                        <div className="col col-sm-6">
                            <label>Any two-symbol-unique-code (used to generate grid id)</label>
                            <TextInput
                                inputData={{
                                    title: "idCode",
                                    placeholder: "idCode",
                                    value: idCode || "",
                                    type: "text",
                                    disabled: !!league.id 
                                }}
                                code="idCode"
                                onChange={setIdCode}
                            />
                        </div>
                        <div className="col col-sm-6">
                            <label>Display Name (LEAGUE-TRACK)</label>
                            <TextInput
                                inputData={{
                                    title: "Name",
                                    placeholder: "Name",
                                    value: name || "",
                                    type: "text",
                                }}
                                code="name"
                                onChange={setName}
                            />
                        </div>
                        <div className="col col-sm-6">
                            <label>League's short name (LEAGUE-HOME)</label>
                            <TextInput
                                inputData={{
                                    title: "Short Name / Abbreviation",
                                    placeholder: "Short Name / Abbreviation",
                                    value: shortName || "",
                                    type: "text",
                                }}
                                className="uppercase"
                                code="name"
                                onChange={setShortName}
                            />
                        </div>
                        <div className="col col-sm-6">
                            <label>Official name (LEAGUE-FULL)</label>
                            <TextInput
                                inputData={{
                                    title: "Official Name",
                                    placeholder: "Official Name",
                                    value: officialName || "",
                                    type: "text",
                                }}
                                code="officialName"
                                onChange={setOfficialName}
                            />
                        </div>
                        <div className="col col-sm-6">
                            <label>Optional version restriction (if not set will affect all app versions)</label>
                            <TextInput
                                inputData={{
                                    title: "Version",
                                    placeholder: "App Version",
                                    value: version || "",
                                    type: "text",
                                }}
                                code="version"
                                onChange={setVersion}
                            />
                        </div>
                    </div>
                    

                    <div className="row">
                        <div className="col">
                            <h2>Providers</h2>
                        </div>
                        <div className="col">
                            <h4>JsonOdds</h4>
                        </div>
                        <div className="col col-sm-6">
                            <label>JsonOdds Game ID (if available will allow pulling odds and scores)</label>
                            <TextInput
                                inputData={{
                                    title: "JsonOdds Game ID",
                                    placeholder: "JsonOdds Game ID",
                                    value: jsonOddsId || "",
                                    type: "text",
                                }}
                                code="jsonOddsId"
                                onChange={setJsonOddsId}
                            />
                        </div>
                        {sport==='soccer' && (
                            <div className="col col-sm-6">
                                <label>JsonOdds League Name (used only with soccer leagues). Ex. Major League Soccer</label>
                                <TextInput
                                    inputData={{
                                        title: "JsonOdds League Name",
                                        placeholder: "JsonOdds League Name",
                                        value: jsonOddsLeague || "",
                                        type: "text",
                                    }}
                                    code="jsonOddsLeague"
                                    onChange={setJsonOddsLeague}
                                />
                            </div>
                        )}
                        <div className="col">
                            <h4>Bovada</h4>
                        </div>
                        <div className="col col-sm-6">
                            <label>Bovada Game ID (comma-separated ex. UFC: 1201,3000000126,23491)</label>
                            <TextInput
                                inputData={{
                                    title: "Bovada Game ID",
                                    placeholder: "Bovada Game ID",
                                    value: bovadaId || "",
                                    type: "text",
                                }}
                                code="bovadaId"
                                onChange={setBovadaId}
                            />
                        </div>
                        <div className="col">
                            <h4>SportsData.io</h4>
                        </div>
                        <div className="col col-sm-6">
                            <label>SportsData.io League API key (value is stored with encryption)</label>
                            <TextInput
                                inputData={{
                                    title: "SportsData.io League API key",
                                    placeholder: "SportsData.io League API key",
                                    value: sdioKey || "",
                                    type: "text",
                                }}
                                code="sdioKey"
                                onChange={setSdioKey}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <button
                                className="btn-primary max50"
                                onClick={handleSubmit}
                            >
                                {league.id ? `Save` : `Submit`}
                            </button>
                        </div>
                        {league.id && (
                            <div className="col">
                                <button
                                    className="btn-secondary max50"
                                    onClick={confirm}
                                >
                                    Delete
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ) : isLoading ? (
                <Spinner />
            ) : (
                <NoResults />
            )}
        </LayoutPage>
    );
};

export default withDialog(LeaguePage);
