import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { LayoutPage } from "layouts/LayoutPage";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { NoResults } from "components/common/NoResults";
import { EventHeader } from "components/EventPage/EventHeader";
import { EventOdds } from "components/EventPage/EventOdds";
import { EventScores } from "components/EventPage/EventScores";
import { EventMisc } from "components/EventPage/EventMisc";
import { Spinner } from "components/common/Spinner/Spinner";
import { getParams } from "utils/helpers";
import { withDialog } from "components/common/withDialog/withDialog";
import Confirmation from "modals/Confirmation";
import { Icon } from "components/common/Icons/Icon";
import "./EventPage.scss";

const EventComparePage = ({ location, history, closeModal, openModal }) => {
    const query = location.search ? getParams(location) : { ids: "" };

    const { functions } = useContext(AuthContext);
    const { updateMeta, updateFlash, showSpinner, gridQuery } = useContext(StoreContext);
    const [event1, setEvent1] = useState(null);
    const [event2, setEvent2] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const fetchEvents = () => {
        functions
            .httpsCallable("getGrid")({ ids: query.ids })
            .then((res) => {
                setLoading(false);
                if (res.data && res.data.data && res.data.data.length > 1)
                    setEvent1(res.data.data[0]);
                setEvent2(res.data.data[1]);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        updateMeta({
            title: "Event",
        });
        fetchEvents();
    }, []);

    useEffect(() => {
        if (!isLoading) fetchEvents();
    }, [location.search]);

    /**
     *  Confirm merge
     *
     */
    const mergeEvents = (e, fromId, toId) => {
        e.preventDefault();

        let message = `Do you want to remove this event and update associated bets?`;

        openModal({
            title: "Confirmation",
            content: (
                <Confirmation
                    message={message}
                    closeModal={closeModal}
                    buttons={true}
                    onConfirm={(e) => {
                        showSpinner(true);

                        runProcess(() => {
                            handleMerge(fromId, toId);
                        });

                        closeModal();
                    }}
                />
            ),
        });
    };

    const runProcess = (callback) => {
        functions
            .httpsCallable("lockProcess")()
            .then((res) => {
                if (res.data.success === true) {
                    callback();
                } else {
                    showSpinner(false);                
                    updateFlash({
                        message: "Database is in use, try again in 20 seconds",
                        success: false,
                    });
                }
            })
            .catch((err) => {
                showSpinner(false);                
                functions.httpsCallable("unlockProcess")();
                updateFlash({
                    success: false,
                    message: `Error occur while locking process`,
                });
                console.log(err);
            });
    };

    const handleMerge = (fromId, toId) => {
        functions
            .httpsCallable("mergeEvents")({ fromId, toId })
            .then((res) => {
                showSpinner(false);
                functions.httpsCallable("unlockProcess")();
                if (res.data.success === true) {
                    updateFlash({
                        message: `Mapping updated successfully`,
                        success: true,
                    });
                    // move to events page
                    history.push(`/events${gridQuery}`);
                } else {
                    updateFlash(res.data);
                }
            })
            .catch((err) => {
                showSpinner(false);
                functions.httpsCallable("unlockProcess")();
                updateFlash({
                    success: false,
                    message: `Error occur while processing teams merge`,
                });
                console.log(err);
            });
    };

    return (
        <LayoutPage
            subtitle={
                <Breadcrumbs location={location} currentPage="Comparison" />
            }
        >
            {event1 && event2 ? (
                <div className="EventComparePage">
                    <div className="row">
                        <div className="Event1">
                            <EventHeader event={event1} isComparison={1} />
                        </div>
                        <div className="Event2">
                            <EventHeader event={event2} isComparison={2} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="Event1">
                            <EventOdds event={event1} isComparison={1} />
                        </div>
                        <div className="Event2">
                            <EventOdds event={event2} isComparison={2} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="Event1">
                            <EventScores event={event1} isComparison={1} />
                        </div>
                        <div className="Event2">
                            <EventScores event={event2} isComparison={2} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="Event1">
                            <EventMisc event={event1} isComparison={1} />
                            <RemoveBtn handleClick={e => mergeEvents(e, event1.id, event2.id)} />
                        </div>
                        <div className="Event2">
                            <EventMisc event={event2} isComparison={2} />
                            <RemoveBtn handleClick={e => mergeEvents(e, event2.id, event1.id)} />
                        </div>
                    </div>
                </div>
            ) : isLoading ? (
                <Spinner />
            ) : (
                <NoResults />
            )}
        </LayoutPage>
    );
};

export default withDialog(EventComparePage);

const RemoveBtn = ({ handleClick }) => (
    <div className="col">
        <div className="EventMergeBtn">
            <div onClick={handleClick} title="Remove duplicate">
                <Icon name="delete" />
            </div>
        </div>
    </div>
);
