import React, { useState, useContext } from "react";
import { BlankPage } from "layouts/BlankPage";
import { AuthContext } from "store/AuthProvider";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import "./LoginPage.scss";

const LoginPage = ({superPathSelect}) => {
    const { auth, error, clearError } = useContext(AuthContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [err, setError] = useState("");

    const login = (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        if (!email || !password) {
            setError("Email and password are required");
            setLoading(false);
            return;
        }
        superPathSelect(null)
        clearError();

        auth.signInWithEmailAndPassword(email, password).catch((error) => {
            setError(error.message);
            setLoading(false);
        });
    };

    return (
        <BlankPage>
            <div className="LoginPage">
                <div className="row">
                    <div className="col">
                        <div className="LoginPage-logo">
                            <img src="/images/icon.png" />
                        </div>
                    </div>
                    <div className="col">
                        <TextInput
                            value={email}
                            onChange={setEmail}
                            inputData={{
                                title: "Email",
                                placeholder: "Email",
                            }}
                        />
                    </div>
                    <div className="col">
                        <TextInput
                            value={password}
                            onChange={setPassword}
                            inputData={{
                                title: "Password",
                                placeholder: "Password",
                                type: "password",
                            }}
                        />
                    </div>
                    <div className="col">
                        <button
                            disabled={(isLoading && !error) || false}
                            onClick={login}
                            type="submit"
                            className={`btn-primary LoginPage-btn${(isLoading && !error) ? " spinning" : ""}`}
                        >
                            Sign In
                        </button>
                    </div>
                    {(err || error) && (
                        <div className="col">
                            <div className="LoginPage-error">{error || err}</div>
                        </div>
                    )}
                </div>
            </div>
        </BlankPage>
    );
};

export default LoginPage;
