import React from "react";
import "./Header.scss";

export const Header = ({ title, subtitle }) => {
  return (
    <div className="Header">
      <div className="Header-title">
        {title}
        {subtitle && (
          <div className="Header-subtitle">{subtitle}</div>
        )}
      </div>
    </div>
  );
};
