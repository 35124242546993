import React from "react";

export const Edit = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6281 4.53393C17.5137 5.4195 17.5137 6.85528 16.6281 7.74084L7.26046 17.1085C7.14325 17.2257 6.98428 17.2916 6.81852 17.2916H3.33398C2.98881 17.2916 2.70898 17.0118 2.70898 16.6666V13.1821C2.70898 13.0163 2.77483 12.8573 2.89204 12.7401L12.2597 3.37242C13.1453 2.48686 14.5811 2.48686 15.4666 3.37242L16.6281 4.53393ZM15.7443 6.85696C16.1417 6.45955 16.1417 5.81523 15.7443 5.41782L14.5828 4.25631C14.1853 3.8589 13.541 3.8589 13.1436 4.25631L11.4339 5.96599L14.0346 8.56663L15.7443 6.85696ZM13.1507 9.45052L10.5501 6.84987L3.95898 13.4409V16.0416H6.55963L13.1507 9.45052Z"
        fill="white"
      />
    </svg>
  );
};
