import React, { useContext, useState, useEffect } from "react";
import * as moment from "moment";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { headList } from "./model";
import { LayoutPage } from "layouts/LayoutPage";
import { withDialog } from "components/common/withDialog/withDialog";
import { NoResults } from "components/common/NoResults";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import GroupAction from "modals/GroupAction/GroupAction";
import { Table, SelectModeButton } from "components/common/Table/Table";
import { Icon } from "components/common/Icons/Icon";
import { Spinner } from "components/common/Spinner/Spinner";
import "./EventListPage.scss";
import { Select } from "components/common/Form/Select/Select";
import { simpleOptions, getParams, serialize } from "utils/helpers";
import Confirmation from "modals/Confirmation";

/**
 * Generate sports options
 *
 */
export const sportsOptions = simpleOptions([
    "football",
    "basketball",
    "hockey",
    "baseball",
    "soccer",
    "MMA",
]);

/**
 * Generate leagues options
 *
 */
export const allLeaguesOptions = {
    football: simpleOptions(["nfl", "ncaaf", "cfl", "xfl"]),
    basketball: simpleOptions(["nba", "ncaab", "wnba"]),
    hockey: simpleOptions(["nhl"]),
    mma: simpleOptions(["ufc", "boxing"]),
    baseball: simpleOptions(["mlb", "npb", "kbo"]),
    soccer: simpleOptions([
        "epl",
        "mls",
        "la liga",
        "serie a",
        "champions league",
    ]),
};
const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
const nextMonth = moment().add(1, "months").format("YYYY-MM-DD");

export const dateToMMDDYYYY = (d) => {
    if (!d) return "";
    const p = d.split("-");
    return [p[1], p[2], p[0]].join("-");
};

export const dateToYYYYMMDD = (d) => {
    if (!d) return "";
    const p = d.split("-");
    return [p[2], p[0], p[1]].join("-");
};

/**
 *  Events List
 *
 */
const EventListPage = ({ history, openModal, closeModal, location }) => {
    const { functions } = useContext(AuthContext);
    const { updateMeta, updateFlash, showSpinner, setGridQuery } = useContext(StoreContext);
    const [list, setList] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [selected, setSelected] = useState([]);
    const [isSelectMode, toggleSelectMode] = useState(false);
    const [sortBy, setSortBy] = useState("startAtTimestamp");
    const [sortOrder, setSortOrder] = useState("asc");

    const query = location.search
        ? getParams(location)
        : {
              games: "mma",
              leagues: "ufc",
              show: "all",
              from: dateToMMDDYYYY(yesterday),
              to: dateToMMDDYYYY(nextMonth),
          };

    const [sport, setSport] = useState(query.games || "mma");
    const [leaguesOptions, setLeaguesOptions] = useState(
        allLeaguesOptions[sport] || allLeaguesOptions.football
    );
    const [league, setLeague] = useState(query.leagues || "");
    const [from, setFrom] = useState(dateToYYYYMMDD(query.from) || "");
    const [to, setTo] = useState(dateToYYYYMMDD(query.to) || "");

    const handleSportChange = (newValue) => {
        if (newValue !== sport) {
            setSport(newValue);
            setLeaguesOptions(allLeaguesOptions[newValue]);
            setLeague("");
        }
    };

    const handleQueryChange = () => {
        const q = {};
        if (sport) q.games = sport;
        if (league) q.leagues = league;
        q.show = "all";
        if (from) q.from = dateToMMDDYYYY(from);
        if (to) q.to = dateToMMDDYYYY(to);
        if (q !== query) {
            const serializedQuery = serialize(q);
            history.push(serializedQuery);
            setGridQuery(serialize(q));
            fetch(q);
        }
    };

    const sortFunc = (by, order) => {
        setSortBy(by);
        setSortOrder(order);
        sortList(list, by, order);
    };

    const sortList = (arr, by, order) => {
        const orderedList = arr.sort((a, b) => (a[by] > b[by] ? 1 : -1));
        order === "asc" ? setList(orderedList) : setList(orderedList.reverse());
    };

    const fetch = (query) => {
        setLoading(true);
        functions
            .httpsCallable("getGrid")(query)
            .then((res) => {
                setLoading(false);
                sortList(res.data.data, sortBy, sortOrder);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        updateMeta({
            title: "Grid",
        });
        fetch(query);
    }, []);

    const handleSelect = (ids, toggle) => {
        if (toggle) {
            const index = selected.indexOf(ids);
            index === -1
                ? setSelected(selected.concat(ids))
                : setSelected(
                      [].concat(
                          selected.slice(0, index),
                          selected.slice(index + 1)
                      )
                  );
            return;
        } else {
            if (!ids.length) {
                setSelected([]);
                return;
            }
            const newRawSelected = selected.concat(ids);
            // filter out duplicates
            setSelected(
                newRawSelected.filter(
                    (elem, pos, arr) => arr.indexOf(elem) == pos
                )
            );
        }
    };

    const handleSubmit = (e, type) => {
        showSpinner(true);
        
        runProcess(() => {
            handleUpdateStatus(type);
        });
        
        closeModal();
    };


    const handleUpdateStatus = (type) => {
        functions
        .httpsCallable("updateStatus")({ 
            ids: selected.join(','), 
            status: type 
        })
        .then((res) => {
            showSpinner(false);
            functions.httpsCallable("unlockProcess")();
            if (res.data.success===true) {
                updateFlash({
                    message: `Submitted ${type} request`,
                    success: true,
                });
                setSelected([]);
                toggleSelectMode(false);    
                    
            } else 
                updateFlash(res.data);                
        })
        .catch((err) => {
            showSpinner(false);
            functions.httpsCallable("unlockProcess")();
            updateFlash({
                success: false,
                message: `Error occur while processing request`,
            });
            console.log(err);
        });
    }

    const showActions = (e) => {
        e.preventDefault();

        openModal({
            title: "Update Status",
            content: (
                <GroupAction
                    options={["postponed", "canceled", "scheduled", "error"]}
                    handleSubmit={handleSubmit}
                    selected={selected}
                />
            ),
        });
    };


    const runProcess = (callback) => {
        functions
        .httpsCallable("lockProcess")()
        .then((res) => {
            if (res.data.success===true) {                
                callback();
            } else {
                showSpinner(false);                
                updateFlash({
                    message: "Database is in use, try again in 20 seconds",
                    success: false,
                });
            } 
        })
        .catch((err) => {
            showSpinner(false);                
            functions.httpsCallable("unlockProcess")();
            updateFlash({
                success: false,
                message: `Error occur while locking process`,
            });
            console.log(err);
        });
    }

    /**
     *  Confirm func
     *
     */
    const markConflict = (e) => {
        e.preventDefault();

        let message = `Do you want to process ${selected.length} selected event(s) as conflicts?`;

        openModal({
            title: "Confirmation",
            content: (
                <Confirmation
                    message={message}
                    closeModal={closeModal}
                    buttons={true}
                    onConfirm={(e) => {
                        showSpinner(true);
                        functions
                            .httpsCallable("createConflicts")({ ids: selected.join(',') })
                            .then((res) => {
                                showSpinner(false);
                                if (res.data.success===true) {
                                    updateFlash({
                                        message: `Marked ${selected.length} event(s) as conflicts`,
                                        success: true,
                                    });
                                    setSelected([]);
                                    toggleSelectMode(false);    
                                } else 
                                    updateFlash(res.data);                                   
                            })
                            .catch((err) => {
                                showSpinner(false);
                                updateFlash({
                                    success: false,
                                    message: `Error occur while creating conflicts`,
                                });
                                console.log(err);
                            });
                        closeModal();
                    }}
                />
            ),
        });
    };

    let numSelected = selected.length;

    const Subtitle = () => {
        return isSelectMode && numSelected ? (
            <div className="TableSubtitle">
                <div>
                    <span onClick={(e) => handleSelect([])}>
                        <Icon name="close" />
                    </span>{" "}
                    {selected.length} event(s) selected
                </div>

                {selected.length === 2 && (
                    <button
                        className="btn-secondary"
                        style={{ marginRight: "1rem" }}
                        onClick={(e) =>
                            history.push(
                                `/events/compare?ids=${selected.join(",")}`
                            )
                        }
                        title={`Compare selected`}
                    >
                        Compare
                    </button>
                )}
                <button
                    className="btn-primary"
                    style={{ marginRight: "1rem" }}
                    onClick={showActions}
                    title={`Modify selected`}
                >
                    Update Status
                </button>
                <button
                    className="btn-primary"
                    onClick={markConflict}
                    title={`Modify selected`}
                >
                    Conflicts
                </button>
            </div>
        ) : null;
    };

    return (
        <LayoutPage
            title={isSelectMode && numSelected ? null : "Grid"}
            subtitle={<Subtitle />}
        >
            {sport && (
                <FetchSettings
                    sport={sport}
                    setSport={handleSportChange}
                    leaguesOptions={leaguesOptions}
                    league={league}
                    setLeague={setLeague}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    handleFetch={handleQueryChange}
                />
            )}

            {/* list control bar */}
            <div className="control-bar">
                <span></span>
                {list && !!list.length && (
                    <SelectModeButton
                        action={() => toggleSelectMode(!isSelectMode)}
                        isActive={isSelectMode}
                    />
                )}
            </div>

            {/* list table */}
            {!isLoading && list && list.length ? (
                <Table
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    sortFunc={sortFunc}
                    subject="event"
                    headList={headList}
                    isSelectMode={isSelectMode}
                    selected={selected}
                    list={list}
                    selectFunc={handleSelect}
                    pathBase="/events"
                    idType="id"
                    history={history}
                />
            ) : (
                !isLoading && (!list || !list.length) && <NoResults />
            )}

            {isLoading && <Spinner />}
        </LayoutPage>
    );
};

export default withDialog(EventListPage);

const FetchSettings = ({
    setSport,
    sport,
    leaguesOptions,
    setLeague,
    league,
    setFrom,
    from,
    setTo,
    to,
    handleFetch,
}) => {
    return (
        <>
        <div className="row FetchSettings">
            <div className="col col-sm-6 col-md-5">
                <Select
                    placeholder="Select sport"
                    title="Sport"
                    options={sportsOptions}
                    onChange={setSport}
                    optionKey="value"
                    value={sport}
                    code="sport"
                />
            </div>
            {sport !== 'soccer' && leaguesOptions && (
                <div className="col col-sm-6 col-md-5 uppercase">
                    <Select
                        placeholder="Select league"
                        title="League"
                        options={leaguesOptions}
                        onChange={setLeague}
                        optionKey="value"
                        value={league}
                        code="league"
                    />
                </div>
            )}
        </div>
        <div className="row FetchSettings">
            <div className="col col-sm-6 col-md-5">
                <TextInput
                    inputData={{
                        title: "Show events starting from this date",
                        type: "date",
                        placeholder: "Events since",
                        value: from || "",
                    }}
                    onChange={setFrom}
                />
            </div>
            <div className="col col-sm-6 col-md-5">
                <TextInput
                    inputData={{
                        title: "Show events ending this date",
                        type: "date",
                        placeholder: "Events until",
                        value: to || "",
                    }}
                    onChange={setTo}
                />
            </div>
            <div className="col col-sm-4 col-md-2">
                <button
                    className="btn-primary Search-button"
                    onClick={(e) => handleFetch()}
                >
                    Refetch
                </button>
            </div>
        </div>
        </>
    );
};
