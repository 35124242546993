import React from "react";
import "./TeamHeader.scss";

export const TeamHeader = ({ team: { sport, league, name }, isComparison }) => {
    return (
        <div
            className={`col${isComparison ? "" : " col-sm-6"}`}
            style={{ padding: "0" }}
        >
            <div className={`TeamHeader${isComparison ? " isComparison" : ""}`}>
                <div className="TeamHeader-top">
                    <div>{!!league ? league : sport}</div>
                </div>
                <div className="TeamHeader-bottom">
                    <h3>{name}</h3>
                </div>
            </div>
        </div>
    );
};
