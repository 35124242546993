import React from "react";

export const Chevron = () => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.53033 0.46967C1.23744 0.176777 0.762564 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L5.46967 6.53033C5.76256 6.82322 6.23744 6.82322 6.53033 6.53033L11.5303 1.53033C11.8232 1.23744 11.8232 0.762563 11.5303 0.46967C11.2374 0.176777 10.7626 0.176777 10.4697 0.46967L6 4.93934L1.53033 0.46967Z"
        fill="#999999"
      />
    </svg>
  );
};
