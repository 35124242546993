import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { LayoutPage } from "layouts/LayoutPage";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { NoResults } from "components/common/NoResults";
import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import { Select } from "components/common/Form/Select/Select";
import Confirmation from "modals/Confirmation";
import { TeamMapping } from "components/TeamPage/TeamMapping";
import { simpleOptions, options } from "utils/helpers";
import "./TeamPage.scss";

/**
 * Generate sports options
 *
 */
const sports = [
    {
        id: 1,
        name: "football",
    },
    {
        id: 2,
        name: "basketball",
    },
    {
        id: 5,
        name: "hockey",
    },
    {
        id: 3,
        name: "baseball",
    },
    {
        id: 4,
        name: "soccer",
    },
];

const sportsOptions = simpleOptions(sports.map((item) => item.name));

const TeamPage = ({
    history,
    location,
    match: {
        params: { teamId },
    },
    closeModal,
    openModal,
}) => {
    const { functions } = useContext(AuthContext);
    const {
        updateMeta,
        updateFlash,
        teams,
        mapping,
        leagues,
        fetchTeams,
        showSpinner,
    } = useContext(StoreContext);
    const [isLoading, setLoading] = useState(teams.isFetching || false);
    const [team, setTeam] = useState(null);

    const [sport, setSport] = useState("");
    const [sportId, setSportId] = useState("");
    const [leagueId, setLeagueId] = useState("");
    const [league, setLeague] = useState("");
    const [name, setName] = useState("");
    const [shortName, setShortName] = useState("");
    const [officialName, setOfficialName] = useState("");
    const [otherName, setOtherName] = useState("");
    const [conference, setConference] = useState("");
    const [leaguesOptions, setLeaguesOptions] = useState([]);
    const [sdioId, setSdioId] = useState("");

    //const [teamName, setTeamName] = useState("");
    //const [names, setNames] = useState({});

    useEffect(() => {
        if (leagues.isFetching) return;
        if (
            ["football", "basketball", "hockey", "baseball"].indexOf(sport) !==
            -1
        ) {
            setLeaguesOptions(
                options(
                    leagues.list
                        .filter((item) => item.sport === sport)
                        .map((item) => {
                            return {
                                name: item.name,
                                id: item.id,
                            };
                        })
                )
            );
        }
    }, [sport, leagues.isFetching]);

    useEffect(() => {
        initValues(team || {});
    }, [team]);

    const initValues = (obj) => {
        setSport(obj.sport || "");
        setLeague(obj.league || "");
        setSportId(obj.sportId || "");
        setLeagueId(obj.leagueId || "");
        setName(obj.name || "");
        setShortName(obj.shortName || "");
        setOfficialName(obj.officialName || "");
        setOtherName(obj.otherName || "");
        setConference(obj.conference || "");
        setSdioId(obj.sdioId || "");
    };

    useEffect(() => {
        updateMeta({
            title: "Team",
        });
    }, []);

    useEffect(() => {
        if (teams.isFetching) return;
        const current =
            teamId === "create"
                ? {}
                : teams.list.find((item) => item.id === teamId);
        setTeam(current);
        //
        //let q = current.sport ? teams.list.filter(item => item.sport === current.sport) : [];
        //if (!!current.league)
        //    q = q.filter(item => item.league === current.league);
        //setNames(q);
    }, [teams.isFetching]);

    /**
     *  Confirm func
     *
     */
    const confirm = (e) => {
        e.preventDefault();

        openModal({
            title: "Confirmation",
            content: (
                <Confirmation
                    message={`Do you want to remove this team?`}
                    closeModal={closeModal}
                    buttons={true}
                    onConfirm={(e) => {
                        closeModal();
                        handleDelete(e, team.id);
                    }}
                />
            ),
        });
    };

    /**
     * Handle create/update a team
     *
     */
    const handleSubmit = (e) => {
        // init body
        const body = {
            sport,
            sportId,
            league,
            leagueId,
            name,
            shortName,
            officialName,
            otherName,
            conference,
            sdioId,
        };
        // init data
        let data = {};
        // init endpoint
        let endpointName = `teamUpdate`;
        if (team.id) {
            // update
            data.teamId = team.id;
        } else {
            // create
            endpointName = `teamCreate`;
        }
        // attach body
        data.body = JSON.stringify(body);

        showSpinner(true);
        functions
            .httpsCallable(endpointName)(data)
            .then((res) => {
                showSpinner(false);
                if (res.data.success === true) {
                    updateFlash({ message: res.data.message, success: true });
                    // update cached teams data
                    fetchTeams();
                    if (!team.id) history.push(`/teams`);
                } else updateFlash(res.data);
            })
            .catch((err) => {
                showSpinner(false);
                updateFlash({
                    success: false,
                    message: `Error occur while processing the request`,
                });
                console.log(err);
            });
    };

    /**
     * Handle delete a team
     *
     */
    const handleDelete = (e, id) => {
        showSpinner(true);
        functions
            .httpsCallable("teamDelete")({ teamId: id })
            .then((res) => {
                showSpinner(false);
                if (res.data.success === true) {
                    updateFlash({ message: res.data.message, success: true });
                    // update cached teams data
                    fetchTeams();
                    history.push(`/teams`);
                } else updateFlash(res.data);
            })
            .catch((err) => {
                showSpinner(false);
                updateFlash({
                    success: false,
                    message: `Error occur while deleting the team`,
                });
                console.log(err);
            });
    };

    return (
        <LayoutPage
            subtitle={
                <Breadcrumbs
                    location={location}
                    currentPage={
                        teamId === "create" ? "Create" : `ID:${teamId}`
                    }
                />
            }
        >
            {team && sportsOptions && (sport || teamId === "create") ? (
                <div className="TeamPage">
                    <div className="row">
                        <div className="col col-sm-6">
                            <label>Select sport</label>
                            <Select
                                placeholder="Select sport"
                                title="Sport"
                                options={sportsOptions}
                                onChange={(value) => {
                                    setSport(value);
                                    setSportId(
                                        sports.find(
                                            (item) => item.name === value
                                        ).id
                                    );
                                    setLeague("");
                                    setLeagueId("");
                                }}
                                optionKey="value"
                                value={sport}
                                code="sport"
                            />
                        </div>
                        {sport !== "soccer" &&
                        leaguesOptions &&
                        (sport || teamId === "create") ? (
                            <div className="col col-sm-6">
                                <label>Select league</label>
                                <Select
                                    placeholder="Select league"
                                    title="Sport"
                                    options={leaguesOptions}
                                    onChange={(value) => {
                                        setLeague(value);
                                        setLeagueId(
                                            leaguesOptions.find(
                                                (item) => item.name === value
                                            ).id
                                        );
                                    }}
                                    optionKey="value"
                                    value={league}
                                    code="league"
                                    className="uppercase"
                                />
                            </div>
                        ) : null}
                        <div className="col col-sm-6">
                            <label>SportsData.io TeamID</label>
                            <TextInput
                                inputData={{
                                    title: "SportsData.io TeamID",
                                    placeholder: "SportsData.io TeamID",
                                    value: sdioId || "",
                                    type: "text",
                                }}
                                code="sdioId"
                                onChange={setSdioId}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-sm-6">
                            <label>Display Name</label>
                            <TextInput
                                inputData={{
                                    title: "Name",
                                    placeholder: "Name",
                                    value: name || "",
                                    type: "text",
                                }}
                                code="name"
                                onChange={setName}
                            />
                        </div>
                        <div className="col col-sm-6">
                            <label>
                                Abbreviation (from sportsdata.io | msf)
                                [optional]
                            </label>
                            <TextInput
                                inputData={{
                                    title: "Abbreviation",
                                    placeholder: "Abbreviation",
                                    value: shortName || "",
                                    type: "text",
                                }}
                                code="shortName"
                                onChange={setShortName}
                            />
                        </div>
                        <div className="col col-sm-6">
                            <label>Official Name</label>
                            <TextInput
                                inputData={{
                                    title: "Official Name",
                                    placeholder: "Official Name",
                                    value: officialName || "",
                                    type: "text",
                                }}
                                code="officialName"
                                onChange={setOfficialName}
                            />
                        </div>
                        <div className="col col-sm-6">
                            <label>Other Name [optional]</label>
                            <TextInput
                                inputData={{
                                    title: "Other Name",
                                    placeholder: "Other Name",
                                    value: otherName || "",
                                    type: "text",
                                }}
                                code="otherName"
                                onChange={setOtherName}
                            />
                        </div>
                        {league && ["ncaab", "ncaaf"].indexOf(league) !== -1 && (
                            <div className="col col-sm-6">
                                <label>Conference</label>
                                <TextInput
                                    inputData={{
                                        title: "Conference",
                                        placeholder: "Conference",
                                        value: conference || "",
                                        type: "text",
                                    }}
                                    code="conference"
                                    onChange={setConference}
                                />
                            </div>
                        )}
                    </div>

                    {mapping.list && team.id && (
                        <TeamMapping
                            id={team.id}
                            mapping={
                                sport === "soccer"
                                    ? mapping.list.soccer
                                    : mapping.list[league]
                            }
                        />
                    )}

                    <div className="row">
                        <div className="col">
                            <button
                                className="btn-primary max50"
                                onClick={handleSubmit}
                            >
                                {team.id ? `Save` : `Submit`}
                            </button>
                        </div>
                        {team.id && (
                            <div className="col">
                                <button
                                    className="btn-secondary max50"
                                    onClick={confirm}
                                >
                                    Delete
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ) : isLoading ? (
                <Spinner />
            ) : (
                <NoResults />
            )}
        </LayoutPage>
    );
};

export default withDialog(TeamPage);
