import React from "react";

export const History = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99994 18.125C14.4873 18.125 18.1249 14.4873 18.1249 10C18.1249 5.51269 14.4873 1.875 9.99994 1.875C5.51263 1.875 1.87494 5.51269 1.87494 10C1.87494 11.9628 2.57567 13.8121 3.80572 15.2589H1.93164C1.58646 15.2589 1.30664 15.5387 1.30664 15.8839C1.30664 16.2291 1.58646 16.5089 1.93164 16.5089H5.46717C5.81235 16.5089 6.09217 16.2291 6.09217 15.8839V12.3484C6.09217 12.0032 5.81235 11.7234 5.46717 11.7234C5.122 11.7234 4.84217 12.0032 4.84217 12.3484V14.5463C3.74915 13.3082 3.12494 11.7055 3.12494 10C3.12494 6.20304 6.20298 3.125 9.99994 3.125C13.7969 3.125 16.8749 6.20304 16.8749 10C16.8749 13.797 13.7969 16.875 9.99994 16.875C9.65476 16.875 9.37494 17.1548 9.37494 17.5C9.37494 17.8452 9.65476 18.125 9.99994 18.125ZM9.99994 5.87559C10.3451 5.87559 10.6249 6.15541 10.6249 6.50059V10.5744L12.6547 12.6042C12.8988 12.8483 12.8988 13.244 12.6547 13.4881C12.4107 13.7322 12.0149 13.7322 11.7708 13.4881L9.558 11.2753C9.44079 11.1581 9.37494 10.9991 9.37494 10.8333V6.50059C9.37494 6.15541 9.65476 5.87559 9.99994 5.87559Z"
        fill="#F86D4C"
      />
    </svg>
  );
};
