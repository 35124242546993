import React, { useState, useContext } from "react";
import { AuthContext } from "store/AuthProvider";
import { useLocation } from 'react-router-dom';

import { NavLink } from "react-router-dom";
import { menuItems } from "./pickemModel";
import { Icon } from "components/common/Icons/Icon";

import "./SideMenu.scss";

export const PickemSideMenu = ({ isMobileNav, toggleMobileNav }) => {
    const { auth, user } = useContext(AuthContext);
    const [submenuItem, setSubmenuItem] = useState(null);

    const getSubmenuItem = item => {
        setSubmenuItem(item);
    };

    const classList = ["SideMenu"];
    if (isMobileNav) classList.push("active");

    const location = useLocation()
    

    return (
        <nav className={classList.join(" ")} onClick={e => toggleMobileNav(false)}>
            <NavLink to="/" className="logo">
                <img src="/images/icon.png" />
            </NavLink>

            <ul className="SideMenu-list">
                {menuItems.map(item => {
                    const hasSubmenu = item.submenu ? "submenu" : "";
                    const hasSubmenuActive =
                        item.submenu && submenuItem === item.label
                            ? "submenu-active"
                            : "";
                    const hideFullMenu = window.location.pathname === "/" || window.location.pathname === "/pickem-rules" || window.location.pathname === "/pickem-account"
                    
                    if (hideFullMenu && (item.to === "" || item.to === "/pickem-rules" || item.to === "/pickem-account")) {
                        return (
                            <li
                                key={item.to}
                                className={`SideMenu-item ${hasSubmenu} ${hasSubmenuActive}`}
                                onClick={e => getSubmenuItem(item.label)}
                            >
                                <NavLink
                                    className="SideMenu-link"
                                    to={item.to}
                                    exact={item.to === "/" || item.exact}
                                >
                                    <Icon name={item.icon} />
                                    {item.label}
                                </NavLink>
    
                                {item.submenu && submenuItem === item.label && (
                                    <ul>
                                        <li className="submenu-item">
                                            {item.submenu.map(submenu => {
                                                return (
                                                    <NavLink
                                                        key={submenu.to}
                                                        className="SideMenu-link"
                                                        to={submenu.to}
                                                        exact={submenu.to === "/"}
                                                    >
                                                        <Icon name={submenu.icon} />
                                                        {submenu.label}
                                                    </NavLink>
                                                );
                                            })}
                                        </li>
                                    </ul>
                                )}
                            </li>
                        );
                    }
                    else if (hideFullMenu) {
                        return null
                    }
                    const competitionId = window.location.pathname.split("/")[2]
                    let toLink = item.to
                    if (competitionId) {
                        if (toLink === "" || toLink === "/pickem-account" || toLink === "/pickem-rules") {
                            toLink = toLink
                        }
                        else {
                            toLink = toLink + "/" + competitionId
                        }
                        
                    }
                    return (
                        <li
                            key={item.to}
                            className={`SideMenu-item ${hasSubmenu} ${hasSubmenuActive}`}
                            onClick={e => getSubmenuItem(item.label)}
                        >
                            <NavLink
                                className="SideMenu-link"
                                to={toLink}
                                exact={item.to === "/" || item.exact}
                            >
                                <Icon name={item.icon} />
                                {item.label}
                            </NavLink>

                            {item.submenu && submenuItem === item.label && (
                                <ul>
                                    <li className="submenu-item">
                                        {item.submenu.map(submenu => {
                                            return (
                                                <NavLink
                                                    key={submenu.to}
                                                    className="SideMenu-link"
                                                    to={submenu.to}
                                                    exact={submenu.to === "/"}
                                                >
                                                    <Icon name={submenu.icon} />
                                                    {submenu.label}
                                                </NavLink>
                                            );
                                        })}
                                    </li>
                                </ul>
                            )}
                        </li>
                    );
                })}

            </ul>

            <div className="SideMenu-footer">
                <p>Version 1.0.8</p>
                <a onClick={(e) => auth.signOut()}>Sign out</a>
            </div>
        </nav>
    );
};


