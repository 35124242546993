import React from "react";
import "./LayoutPage.scss";
import { Header } from "components/Headers/Header";

export const LayoutPage = ({ children, title, subtitle }) => {
  return (
    <div className="LayoutPage">
      <Header 
        title={title}
        subtitle={subtitle}
      />

      <div className="LayoutPage-content">
        <div className="LayoutPage-contentWrap">
          <div className="LayoutPage-contentInnerWrap">{children}</div>
        </div>
      </div>
    </div>
  );
};
