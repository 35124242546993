import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import cuid from "cuid";
import { Icon } from "components/common/Icons/Icon";
import "./UnsavedChangesDialog.scss";

class UnsavedChangesDialog extends Component {
    constructor() {
        super();
        this.state = {
            open: false
        };
        // define trigger reference
        this.__trigger = Symbol.for(`__UnsavedChangesDialog_${cuid()}`);

        this.show = this.show.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleTransition = this.handleTransition.bind(this);
        this.forceTransition = this.forceTransition.bind(this);
        this.callback = null;
    }

    componentDidMount() {
        window[this.__trigger] = this.show;
    }

    componentWillUnmount() {
        delete window[this.__trigger];
    }

    doNothing(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * Show Modal
     */
    show(allowTransitionCallback) {
        // save callback
        this.callback = allowTransitionCallback;

        // by default don't allow
        this.setState({ open: true }, () => allowTransitionCallback(false));
    }

    /**
     * Close modal
     */
    handleClose() {
        this.setState({ open: false });
    }

    /**
     * Force transition
     */
    forceTransition() {
        this.callback(true);
    }

    /**
     * Handle Router transition
     */
    handleTransition(location) {
        // whitelist routes
        // if (location.pathname === "/...") {
        //   return true;
        // }
        return Symbol.keyFor(this.__trigger);
    }

    /**
     * Render
     */
    render() {
        const { when, title, message } = this.props;

        const classList = ["UnsavedChanges"];

        if (this.state.open) classList.push("active");

        return (
            <div className={classList.join(" ")} onClick={this.handleClose}>
                <Prompt when={!!when} message={this.handleTransition} />

                <div>
                    <div className="UnsavedChanges-modal" onClick={this.doNothing}>
                        <span className="closeButton" onClick={this.closeModal}>
                            <Icon name="close" />
                        </span>
                        {title && <h2>{title}</h2>}
                        <div className="UnsavedChanges-content">
                            <div>
                                <p>{message}</p>
                                <div className="UnsavedChanges-buttons">
                                    <button onClick={this.handleClose}>
                                        Cancel
                                    </button>
                                    <button
                                        className="btn-primary"
                                        onClick={this.forceTransition}
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UnsavedChangesDialog;

UnsavedChangesDialog.defaultProps = {
    title: "Changes",
    message:
        "You have unsaved changes, the new data will be lost. Do you want to continue?"
};





export const getUserConfirmation = (dialogKey, callback) => {
    // use "message" as Symbol-based key
    const dialogTrigger = window[Symbol.for(dialogKey)];
  
    if (dialogTrigger) {
      // pass the callback to delegate
      // to the invoked dialog
      return dialogTrigger(callback);
    }
  
    // Fallback to allowing navigation
    callback(true);
};