import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { LayoutPage } from "layouts/LayoutPage";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { NoResults } from "components/common/NoResults";
// import { TestingHeader } from "components/TestingPage/TestingHeader";
import { EventOdds } from "components/EventPage/EventOdds";
import { EventScores } from "components/EventPage/EventScores";
import { EventMisc } from "components/EventPage/EventMisc";
import { Spinner } from "components/common/Spinner/Spinner";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import { getParams } from "utils/helpers";
import moment from "moment-timezone";
import "./TestingPage.scss";

const TestingPage = ({ history, location }) => {
    const query = location.search ? getParams(location) : { betIds : null };

    const { functions } = useContext(AuthContext);
    const { updateMeta, updateFlash, showSpinner } = useContext(StoreContext);

    const [betId, setBetId] = useState(query.id || "");
    const [event, setEvent] = useState(null);
    const [isLoading, setLoading] = useState(!!query.id);

    const fetchEvent = async () => {
        setLoading(true);
        const betArray = await splitBetIds(betId)
        functions
            .httpsCallable("getHomeBetCellData")({ betIds: betArray })
            .then((res) => {
                setLoading(false);
                if (res.data && res.data.data && res.data.data)
                    console.log('res.data.data: ', res.data.data);
                    setEvent(res.data.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const splitBetIds = (betId) => {
        let betArray = betId.split(",")
        betArray = betArray.map((item) => item.trim()) 
        return betArray
    }

    useEffect(() => {
        updateMeta({
            title: "Calculate bet win probability",
        });
        if (betId)
            fetchEvent();
    }, []);

    useEffect(() => {
        const query = location.search ? getParams(location) : { betId: "" };
        if (query.id && query.id !== betId) {
            fetchEvent(betId);
        }
    }, [location.search]);
    
    const handleSubmit = (e) => {
        if (!betId) {
            updateFlash({
                message: `Bet Id value is required`,
                success: false,
            });
            return;
        }
        history.push(`/testing?id=${betId}`);
        // setBetId(null);
        fetchEvent(betId);
    };

    return (
        <LayoutPage
            subtitle={
                <Breadcrumbs
                    location={location}
                    currentPage={
                        <div style={{display: "flex", fontSize: "1em"}}>
                            <TextInput
                                inputData={{
                                    title: "Enter bet ids separated by comma",
                                    placeholder: "Bet ID",
                                    value: betId || "",
                                    type: "text",
                                }}
                                code="name"
                                onChange={setBetId}
                            />
                            <button style={{fontSize: "1em", marginLeft: ".5em"}}
                                className="btn-primary"
                                onClick={handleSubmit}
                            >
                                Search
                            </button>
                        </div>
                    }
                />
            }
        >
            {event ? (
                <div className="EventPage">
                    <pre id="json">
                        {JSON.stringify(event, undefined, 2)}
                    </pre>
                </div>
            ) : isLoading ? (
                <Spinner />
            ) : (
                <NoResults
                    label={(event && betId) ? null : "Enter bet id and press search"}
                />
            )}
        </LayoutPage>
    );
};

export default TestingPage;
