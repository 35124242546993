import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "components/common/Icons/Icon";
import "./Breadcrumbs.scss";

export const Breadcrumbs = ({ location, currentPage }) => {
    const parts = location.pathname.split("/").slice(1);
    return (
        <div className="Breadcrumbs">
            {parts.map((item, i) => {
                const lastItem = i === parts.length - 1 ? true : false;

                return (
                    <React.Fragment key={i}>
                        {lastItem ? (
                            <h5>{currentPage}</h5>
                        ) : (
                            <>
                                <Link
                                    to={`/${parts.slice(0, i + 1).join("/")}`}
                                >
                                    {item}
                                </Link>
                                    <Icon name="chevron" />
                                
                            </>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};
