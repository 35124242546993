import React from "react";
import "./BetsSummary.scss";
import { NoResults } from "components/common/NoResults";

export const BetsSummary = ({
    history,
    betsSummary,
    usersWithMissingBets,
    usersWithInvalidBets,
    usersWithCorruptedBets,
    usersWithUndeletedBets,
    usersWithStubBets,
    usersWithFloatingBets
}) => {

    const RenderUsers = ({ users, label,userType }) => {
        if (!users) {
            return (
                <div className="row">
                <h4>{label} - 0</h4>
                <NoResults label="Empty" height="100px" />
                </div>
            )
        }
        return (
            <div className="row">
            <div className="BetsSummary-segment"><div>{label}</div><div>{users.length}</div></div>
            {
                users.map((userId, j) => {
                    return (
                        <div key={j} className="BetsSummary-segment">
                            <div>&emsp;&emsp;{userType}</div>
                            <div><span className="BetsSummary-userSpan" onClick={handleClick}>{userId}</span></div>                                           
                        </div>
                    );
                })
            }
            </div> 
        )
    }

    const handleClick = (e) => {
        console.log(window.location.protocol + "//" + window.location.host + "/users/" + e.target.textContent)
        const newURL = window.location.protocol + "//" + window.location.host + "/users/" + e.target.textContent
                    window.open(newURL, "_blank");
        
    }

    return (
        <div className={`BetsSummary`}>
            <div className="row">     
                
                <div className={"col col-sm-6"}>
                    <div className="BetsSummary-segment"><div>Users</div> <div>{betsSummary.totalUsersV2}</div></div>
                    <br></br>
                    <RenderUsers users={usersWithMissingBets} label={"Users With Missing Bets"} userType={"user"}/>
                    <br></br>
                    <br></br>
                    <RenderUsers users={usersWithStubBets} label={"Users With Stub Bets"} userType={"user"}/>
                    <br></br>
                    <br></br>
                    <RenderUsers users={usersWithInvalidBets} label={"Users With Invalid Bets"} userType={"user"}/>
                    <br></br>
                    <br></br>
                    <RenderUsers users={usersWithUndeletedBets} label={"Users With Undeleted Bets"} userType={"user"}/>
                    <br></br>
                    <br></br>
                    <RenderUsers users={usersWithCorruptedBets} label={"Users With Corrupted Bets"} userType={"user"}/>
                    <br></br>
                    <br></br>
                    <RenderUsers users={usersWithFloatingBets} label={"Users With Floating Bets"} userType={"user"}/>
                </div>
                <div className={"col col-sm-6"}>
                    <div className="BetsSummary-segment"><div>Bet IDs</div> <div>{betsSummary.totalAllWays}</div></div>
                    <div className="BetsSummary-segment"><div>Bets</div><div>{betsSummary.totalBets}</div></div>
                    <div className="BetsSummary-segment"><div>Parlays</div><div>{betsSummary.totalParlays}</div></div>
                    <div className="BetsSummary-segment"><div>Teasers</div><div>{betsSummary.totalTeasers}</div></div>
                    <div className="BetsSummary-segment"><div>Total Wagers</div><div>{betsSummary.totalBets + betsSummary.totalParlays + betsSummary.totalTeasers}</div></div>
                    <br></br>
                    <div className="BetsSummary-segment"><div>Missing Bets</div> <div>{betsSummary.totalMissingBets}</div></div>
                    <div className="BetsSummary-segment"><div>Missing Parlays</div> <div>{betsSummary.totalMissingParlays}</div></div>
                    <div className="BetsSummary-segment"><div>Missing Teasers</div> <div>{betsSummary.totalMissingTeasers}</div></div>
                    <div className="BetsSummary-segment"><div>Total Missing</div> <div>{betsSummary.totalMissingBets + betsSummary.totalMissingParlays + betsSummary.totalMissingTeasers}</div></div>
                    <br></br>
                    <br></br>
                    <div className="BetsSummary-segment"><div>Stub Bets</div> <div>{betsSummary.totalStubBets}</div></div>
                    <div className="BetsSummary-segment"><div>Stub Parlays</div> <div>{betsSummary.totalStubParlays}</div></div>
                    <div className="BetsSummary-segment"><div>Stub Teasers</div> <div>{betsSummary.totalStubTeasers}</div></div>
                    <div className="BetsSummary-segment"><div>Total Stub</div> <div>{betsSummary.totalStubBets + betsSummary.totalStubParlays + betsSummary.totalStubTeasers}</div></div>
                    <br></br>
                    <div className="BetsSummary-segment"><div>Invalid Bets</div> <div>{betsSummary.totalInvalidBets}</div></div>
                    <div className="BetsSummary-segment"><div>Invalid Parlays</div> <div>{betsSummary.totalInvalidParlays}</div></div>
                    <div className="BetsSummary-segment"><div>Invalid Teasers</div> <div>{betsSummary.totalInvalidTeasers}</div></div>
                    <div className="BetsSummary-segment"><div>Total Invalid</div> <div>{betsSummary.totalInvalidBets + betsSummary.totalInvalidParlays + betsSummary.totalInvalidTeasers}</div></div>
                    <br></br>
                    <div className="BetsSummary-segment"><div>Undeleted Bets</div> <div>{betsSummary.totalUndeleted}</div></div>
                    <br></br>
                    <div className="BetsSummary-segment"><div>Corrupted Bets</div> <div>{betsSummary.totalCorrupted}</div></div>
                    <br></br>
                    <div className="BetsSummary-segment"><div>Floating Bets</div> <div>{betsSummary.totalFloating}</div></div>
                </div>
            </div>    
        </div>
    )
}


