import React from "react";
import "./PickemLayoutPage.scss";
import { PickemHeader } from "components/Headers/PickemHeader";

export const PickemLayoutPage = ({ children, title, subtitle, name, competitionName, competitionDescription, isDifferentUser=false }) => {

  return (
    <div className="LayoutPage">
      <PickemHeader 
        title={title}
        subtitle={subtitle}
        name={name}
        isDifferentUser={isDifferentUser}
        competitionName={competitionName}
        competitionDescription={competitionDescription}
      />

      <div className="LayoutPage-content">
        <div className="LayoutPage-contentWrap">
          <div className="LayoutPage-contentInnerWrap">{children}</div>
        </div>
      </div>
    </div>
  );
};
