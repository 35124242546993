export const headList = [
    { code: "favorite", title: "Favorite", mobileHide: false, isNumeric: false },
    { code: "favoritePicked", title: "", mobileHide: false, isNumeric: false },
    { code: "vs", title: "", mobileHide: false, isNumeric: false },
    { code: "underdogPicked", title: "", mobileHide: false, isNumeric: false },
    { code: "underdog", title: "Underdog", mobileHide: false, isNumeric: false },
    { code: "opening", title: "Opening", mobileHide: false, isNumeric: false },
    { code: "closing", title: "Closing", mobileHide: false, isNumeric: false },
    { code: "final", title: "Final", mobileHide: false, isNumeric: false },
    { code: "startAt", title: "Status", mobileHide: false, isNumeric: false,  type: "dateString", format: "L h:mm A", },
];