import React, { useContext, useEffect, useState } from "react";
import { LayoutPage } from "layouts/LayoutPage";
import { StoreContext } from "store/StoreProvider";
import { AuthContext } from "store/AuthProvider";
import "./HomePage.scss";

const HomePage = () => {
    const { updateMeta } = useContext(StoreContext);
    const { functions } = useContext(AuthContext);
    const [ image, setImage ] = useState("");
    const [ extension, setExtension ] = useState("jpg");

    useEffect(() => {
        updateMeta({
            title: "Home",
        });
    }, []);

    const extensions = ['jpeg', 'jpg', 'png', 'bmp'];

    const handleUpdate = (e) => {
        if (!e.target.files || !e.target.files[0]) {
            console.log('No files found');
            return;
        }
        const file = e.target.files[0];

        const ext = file.name.split('.').pop().toLowerCase();
        setExtension(ext);

        if (extensions.indexOf(ext) === -1){
            console.log('Invalid file type');
            return;
        }

        const reader = new FileReader();
        
        reader.onload = upload => {
            setImage(upload.target.result);
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    // via web sdk
    /*const handleSubmit2 = (e) => {
        if (!image) {
            console.log('No image found');
            return;
        }
        const ref = storage.child(`users/1.${extension}`);
        ref.putString(image, 'data_url').then(snapshot => {
            snapshot.ref.getDownloadURL().then(downloadURL => setImage(downloadURL));
        });
    }*/
    // via functions (admin sdk handling)
    const handleSubmit = (e) => {
        if (!image) {
            console.log('No image found');
            return;
        }        
        functions.httpsCallable('updateUser')({ body: JSON.stringify({ image, username: "kain" }) })
        .then(function(result) {
            if (result.data.success)
                setImage(result.data.data.image);
            else console.log('error', result.data);
        })
    }

    return (
        <LayoutPage title="Home">
            {/*
            <img src={image} />
            <input type="file" onChange={handleUpdate} /> 
            <div onClick={handleSubmit}>Submit</div>
            */}
            <div className="HomePage">
                <h1>Introduction</h1>
                <p>For now this will be the place for listing information about features</p>
                <div>
                    <h4>Home page</h4>
                    <ul>
                        <li>To get back to home page click on heatr icon</li>
                    </ul>
                </div>
                <div>
                    <h4>Grid tab notes</h4>
                    <ul>
                        <li>Filters on the top reduce requested data from firebase, most effect has the time gap</li>
                        <li>Once filters are changed Refetch button will set address bar query and will fetch data</li>
                        <li>Address bar query could be adjustem manually if needed</li>
                        <li>Clicking on field type (Sport, League etc) will trigger sort function, and will toggle order with next clicks</li>
                        <li>Icon below Refetch button will switch to select mode with 3 options (Compare, Update Status, Conflicts)</li>
                        <li>Compare option is available only if selected only 2 events</li>
                        <li>Conflicts option is needed for cases when system did not detect duplicate events automatically. When noticed two almost identical events, select either one and use Conflict option to generate conflict record</li>
                        <li>Update Status option allow to select postpone, cancel or fake status. "fake" status is vital, because simply delete upcoming fake event won't be enough for as long as a provider has that data it will regenerate it on the next cycle. Instead all events with status "fake" will not be returned to the app</li>
                    </ul>
                </div>
                <div>
                    <h4>Conflicts tab notes</h4>
                    <ul>
                        <li>Also has select mode with 2 options (Updte Status, Ignore)</li>
                        <li>Update Status works same as on Grid tab</li>
                        <li>Ignore option allows to ignore conflicts for selected events and remove only conflict records without touching associated events</li>
                        <li>On conflict page, click on the right block will open Duplicate search window</li>
                        <li>Duplicate search window by default will request all events for time gap of 3 days before the conflict event and 3 days after</li>
                        <li>Bottom filter on Duplicate search window does not send additional request to database, but instead filters records received by time gap filter</li>
                        <li>Duplicate search window also allow sorting by clicling on field type (Sport, League etc)</li>
                        <li>After duplicate found and selected you can merge to either side (whichever event data is right)</li>
                    </ul>
                </div>
                <div>
                    <h4>Leagues and Teams tab notes</h4>
                    <ul>
                        <li>Both pages request all records right away instead of based on top filters to reduce requests number, until total records numbers are large to be split</li>
                        <li>Top filter does not send addition request to database</li>
                    </ul>
                </div>
            </div>
        </LayoutPage>
    );
};

export default HomePage;
