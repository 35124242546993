import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "components/common/Icons/Icon";
import "./MobileHeader.scss";

export const MobileHeader = ({ toggleMobileNav, isMobileNav }) => {
    return (
        <>
            <div className="MobileHeader">
                <div className="MobileHeader-logo">
                    <NavLink to="/admin" className="logo">
                        <img src="/images/icon.png" />
                    </NavLink>
                </div>
                <div
                    className={`MobileHeader-hamburger${
                        isMobileNav ? " active" : ""
                    }`}
                    onClick={e => toggleMobileNav()}
                >
                    <Icon name="menu" />
                </div>
            </div>
            {isMobileNav && (
                <div
                    className="MobileHeader-shadow"
                    onClick={e => toggleMobileNav(false)}
                ></div>
            )}
        </>
    );
};
