import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { LayoutPage } from "layouts/LayoutPage";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { NoResults } from "components/common/NoResults";
import { Spinner } from "components/common/Spinner/Spinner";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import { getParams } from "utils/helpers";
import "./UsersPage.scss";
import { headList } from "./model";
import { BetsSummary } from "components/UserPage/BetsSummary";
import { UserTable } from "components/common/Table/UserTable";

const Config = require("../../utils/localConfig.json");


const UsersPage = ({ history, location }) => {
    const query = location.search ? getParams(location) : { userId: null };
    const { app } = useContext(AuthContext)
    const { functions } = useContext(AuthContext);
    const { firestore } = useContext(AuthContext);
    const { updateMeta, updateFlash, showSpinner } = useContext(StoreContext);

    const [userId, setUserId] = useState(query.id || "");
    const [userIndex, setUserIndex] = useState(0);
    const [userCount, setUserCount] = useState(0);
    const [userSummary, setUserSummary] = useState([]);
    const [betsSummary, setBetsSummary] = useState({
        totalBets: 0,
        totalParlays: 0,
        totalTeasers: 0,
        totalInvalidBets: 0,
        totalInvalidParlays: 0,
        totalInvalidTeasers: 0,
        totalMissingBets: 0,
        totalMissingParlays: 0,
        totalMissingTeasers: 0,
        totalStubBets: 0,
        totalStubParlays: 0,
        totalStubTeasers: 0,
        totalAllWays: 0,
        totalUsers: 0,
        totalUsersV2: 0,
        totalCorrupted: 0,
        totalUndeleted: 0,
        totalFloating: 0
    });

    const [usersWithMissingBets, setUsersWithMissingBets] = useState([]);
    const [usersWithInvalidBets, setUsersWithInvalidBets] = useState([]);
    const [usersWithCorruptedBets, setUsersWithCorruptedBets] = useState([]);
    const [usersWithUndeletedBets, setUsersWithUndeletedBets] = useState([]);
    const [usersWithStubBets, setUsersWithStubBets] = useState([]);
    const [usersWithFloatingBets, setUsersWithFloatingBets] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const [isDev, setIsDev] = useState(window.location.hostname === "localhost" ? true : false);
    const [options, setOptions] = useState([])

    const [sortBy, setSortBy] = useState("userId");
    const [sortOrder, setSortOrder] = useState("asc");

    const fetchMyBetting = async (showArchive, uid) => {
        const archive = showArchive ? "&show_archive=" + showArchive : ""
        const calledUserId = uid ? uid : userId
        const url = isDev ? Config.myBetting.local : Config.myBetting.deployed
        const returnObj = await fetch(url + "?" + "user_id=" + calledUserId + "&limit=100000" + archive)
            .then((res) => res.json())
            .then((data) => {
                    const label = showArchive ? "archive" : "activity"
                    const updatedObj = {
                        bets: [...data.data.list.filter((bet) => bet.type === "bet")],
                        parlays: [...data.data.list.filter((bet) => bet.type === "parlay")],
                        teasers: [...data.data.list.filter((bet) => bet.type === "teaser")]
                    }
                    return updatedObj
            })
            .catch((err) => {
                console.log(err)
                return { bets: [], parlays: [], teasers: [], inV2: false }
            });
        return returnObj
    }

    const fetchMyProfile = async (uid) => {
        const url = isDev ? Config.myProfile.local : Config.myProfile.deployed
        const calledUserId = uid ? uid : userId
        const returnObj = await fetch(url + "?" + "user_id=" + calledUserId)
            .then((res) => res.json())
            .then((data) => {
                if (data.success = false) {
                    const updatedObj = {
                        bets: [],
                        parlays: [],
                        teasers: [], 
                        inV2: false
                    }
                    return updatedObj
                }
                
                const updatedObj = {
                    bets: [...data.data.bets],
                    parlays: [...data.data.parlays],
                    teasers: [...data.data.teasers],
                    inV2: true
                }
                return updatedObj
            })
            .catch((err) => {
                if (err == "error 404") {
                    const updatedObj = {
                        bets: [],
                        parlays: [],
                        teasers: [], 
                        inV2: false
                    }
                    return updatedObj
                }
                const updatedObj = {
                    bets: [],
                    parlays: [],
                    teasers: [], 
                    inV2: false
                }
                return updatedObj
            });
        return returnObj
    }


// Validate bets V2 bets for a user. For now it will only validate custom bets
// Validate bets V2 bets for a user. For now it will only validate custom bets
const validateNewBets = (newBetData) => {
    let responseObj = {
        bet: null,
    };
    if (newBetData) {
        if ((!newBetData.hasOwnProperty('isActive') || typeof newBetData.isActive != 'boolean')
            || (!newBetData.hasOwnProperty('draw') || typeof newBetData.draw != 'boolean')
            || (!newBetData.hasOwnProperty('isCustomBet') || typeof newBetData.draw != 'boolean')) {
            //console.log(`${newBetData.id} Bet should have active, isActive, draw, isCustomBet boolean flag`)
            return responseObj
        }
        if (!newBetData.betOdds || newBetData.betOdds === '') {
            //console.log(`${newBetData.id} Bet should have betOdds. It should not be null or Empty`)
            return responseObj
        }
        if (!newBetData.betType || newBetData.betType === '') {
            //console.log(`${newBetData.id} Bet should have betType. It should not be null or Empty`)
            return responseObj
        }
        if (!newBetData.createdAt || newBetData.createdAt === 0) {
            //console.log(`${newBetData.id} Bet should have createdAt. It should not be null or zero`)
            return responseObj
        }
        if (!newBetData.hasOwnProperty("customAwayScore") || !newBetData.hasOwnProperty("customHomeScore")) {
            //console.log(`${newBetData.id} Bet should have customAwayScore and customHomeScore.`)
            return responseObj
        }
        if (!newBetData.eventAt || newBetData.eventAt === '') {
            //console.log(`${newBetData.id} Bet should have eventAt. It should not be null or Empty`)
            return responseObj
        }
        if (!newBetData.eventId || !newBetData.hasOwnProperty('manualPaceStateValue') || !newBetData.hasOwnProperty('wager')) {
            //console.log(`${newBetData.id} Bet should have eventId, manualPaceStateValue, wager.`)
            return responseObj
        }
        if (!newBetData.isCustomBet && !newBetData.teamId) {
            //console.log(`${newBetData.id} If a bet is not custom bet then it should have a teamId.`)
            return responseObj
        }
        if (!newBetData.hasOwnProperty('parentId') || newBetData.parentId === '') {
            //console.log(`${newBetData.id} Bet should have parentId. It can be null but not empty`)
            return responseObj
        }
        if (!newBetData.hasOwnProperty('payout')) {
            //console.log(`${newBetData.id} Bet should have payout`)
            return responseObj
        }
        if ((!newBetData.price || newBetData.price === '')
            || (!newBetData.segment || newBetData.segment === '')
            || (!newBetData.team || newBetData.team === '')
            || (!newBetData.userId || newBetData.userId === '')
            || (!newBetData.wagerType || newBetData.wagerType === '')) {
            //console.log(`${newBetData.id} Bet should have price, segment, team, userId, wagerType. These properties should not be null or empty`)
            return responseObj
        }

        // Odds data
        if (!newBetData.odds?.moneyLineAway || newBetData.odds?.moneyLineAway === ''
            || !newBetData.odds?.drawLine || newBetData.odds?.drawLine === ''
            || !newBetData.odds?.moneyLineHome || newBetData.odds?.moneyLineHome === ''
            || !newBetData.odds?.overLine || newBetData.odds?.overLine === ''
            || !newBetData.odds?.underLine || newBetData.odds?.underLine === ''
            || !newBetData.odds?.pointSpreadAway || newBetData.odds?.pointSpreadAway === ''
            || !newBetData.odds?.pointSpreadHome || newBetData.odds?.pointSpreadHome === ''
            || !newBetData.odds?.pointSpreadAwayLine || newBetData.odds?.pointSpreadAwayLine === ''
            || !newBetData.odds?.pointSpreadHomeLine || newBetData.odds?.pointSpreadHomeLine === ''
            || !newBetData.odds?.totalNumber || newBetData.odds?.totalNumber === '') {

            //console.log(`${newBetData.id} Bet is missing Odd properties`)
            return responseObj
        }
        if (!newBetData.match_object.sport || newBetData.match_object.sport === ''
            || !newBetData.match_object.league || newBetData.match_object.league === ''
            || !newBetData.match_object.MatchTime || newBetData.match_object.MatchTime === 0
            || !newBetData.match_object.ID
            || !newBetData.match_object.AwayTeam || newBetData.match_object.AwayTeam === ''
            || !newBetData.match_object.HomeTeam || newBetData.match_object.HomeTeam === '') {

            //console.log(`${newBetData.id} Bet is missing match_object properties`)
            return responseObj
        }

        responseObj.bet = newBetData
    }
    return responseObj
}

const validateV2Bets = async (userId) => {
    if (userId) {
        const betRef = firestore
            .collection("bets")

        const userBets = await betRef.where('userId', '==', userId).where('isCustomBet', '==', true).get()
        if (userBets) {

            let invalidBetsObj = {
                bets: {
                    count: 0,
                    invalidDocuments: []
                },
                parlays: {
                    count: 0,
                    invalidDocuments: []
                },
                teasers: {
                    count: 0,
                    invalidDocuments: []
                }
            }

            userBets.docs.map((doc) => {
                const betData = doc.data()
                const validatedBet = validateNewBets(betData)
                if (validatedBet.bet == null) {
                    if (betData.betType == 'straight') {
                        invalidBetsObj.bets.count++
                        invalidBetsObj.bets.invalidDocuments.push(betData.id)
                    }
                    if (betData.betType == 'parlay') {
                        if (invalidBetsObj.parlays.invalidDocuments.length == 0 || !invalidBetsObj.parlays.invalidDocuments.includes(betData.parentId)) {
                            invalidBetsObj.parlays.count++
                            invalidBetsObj.parlays.invalidDocuments.push(betData.parentId)
                        }
                    }
                    if (betData.betType == 'teaser') {
                        if (invalidBetsObj.teasers.invalidDocuments.length == 0 || !invalidBetsObj.teasers.invalidDocuments.includes(betData.parentId)) {
                            invalidBetsObj.teasers.count++
                            invalidBetsObj.teasers.invalidDocuments.push(betData.parentId)
                        }
                    }

                }
            }
            )
            return invalidBetsObj
        }
    }
}


    const parlayAndTeaserBetsMissingFromBets = async (userId) => {
        let missingBets = []
        let betsWithMissingParentId = []
        showSpinner(true)
        setLoading(true)

        let userParlaysRef = await firestore
            .collection("parlays")
            .where('userId', '==', userId)
            .get()
        const userParlays = userParlaysRef.docs

        let userTeasersRef = await firestore
            .collection("teasers")
            .where('userId', '==', userId)
            .get()
        const userTeasers = userTeasersRef.docs

        for (let i = 0; i < userParlays?.length; i++) {
            const parlay = userParlays[i].data()
            let parlayPromises = parlay.bets.map(async (betId) => {
                const parlayBetInBetTable = await firestore
                    .collection("bets")
                    .doc(betId)
                    .get()
                if (!parlayBetInBetTable.exists) {
                    missingBets.push(betId)
                }
                else {
                    if (parlayBetInBetTable.data()?.parentId == null) {
                        betsWithMissingParentId.push(betId)
                    }
                }
            })
            await Promise.all(parlayPromises)
        }

        for (let i = 0; i < userTeasers?.length; i++) {
            const teaser = userTeasers[i].data()
            let teaserPromises = teaser.bets.map(async (betId) => {
                const teaserBetInBetTable = await firestore
                    .collection("bets")
                    .doc(betId)
                    .get()
                if (!teaserBetInBetTable.exists) {
                    missingBets.push(betId)
                }
                else {
                    if (teaserBetInBetTable.data()?.parentId == null) {
                        betsWithMissingParentId.push(betId)
                    }
                }
            })
            await Promise.all(teaserPromises)
        }
        return {undeletedBetsCount: missingBets.length, corruptedBetsCount: betsWithMissingParentId.length}
        
    }

    const loadUserList = async () => {
        const usersLoaded = await firestore
            .collection("users_v2")
            .get()
            .then((querySnapshot) => {
                let userOptions = []
                querySnapshot.docs.map((doc) => {
                    userOptions.push(doc.id)
                }
                )
                
                return userOptions
            })
            .catch((err) => {
                console.log(err)
                return []
            }
            )
        return usersLoaded
    }

    const findFloatingBets = async (userId) => {
        let floatingBets = [];
        const parlayRefs = await firestore
            .collection("bets")
            .where("betType", "==", "parlay")
            .where("userId", "==", userId)
            .get()
        const parlayDocs = parlayRefs.docs

        const teaserRefs = await firestore
            .collection("bets")
            .where("betType", "==", "teaser")
            .where("userId", "==", userId)
            .get()
        const teaserDocs = teaserRefs.docs

        const parlayPromises = parlayDocs.map(async (parlayDoc) => {
            const parlay = parlayDoc.data()
            if (parlay.parentId) {
                const parentId = parlay.parentId
                const parentIdRef = await firestore.collection("parlays").doc(parentId).get()
                
                if (!parentIdRef.exists) {
                    //console.log("Parlay:", parentId, parlay.id, parentIdRef.exists)
                    floatingBets.push(parlay.id)
                }
            }
            
            return true
        })

        const teaserPromises = teaserDocs.map(async (teaserDoc) => {
            const teaser = teaserDoc.data()
            if (teaser.parentId) {
                const parentId = teaser.parentId
                const parentIdRef = await firestore.collection("teasers").doc(parentId).get()
                if (!parentIdRef.exists) {
                    //console.log("Teaser:", parentId, teaser.id, parentIdRef.exists)
                    floatingBets.push(teaser.id)
                }
            }
            
            return true
        })

        await Promise.all(parlayPromises, teaserPromises)
        return floatingBets.length
    }

    const collectUserSummary = async (uid) => {
        
        let userSumObj = await Promise.all([fetchMyProfile(uid), fetchMyBetting(false, uid), fetchMyBetting(true, uid), validateV2Bets(uid), parlayAndTeaserBetsMissingFromBets(uid), findFloatingBets(uid)])
            .then((response) => {
                const [myProfileObj, activeObj, archiveObj, invalidV2Obj, problemBetsObj, floatingBetsCount] = response

                const userSummaryObj = {
                    userId: uid,
                    betListBets: { ...myProfileObj },
                    usersV2Bets: {
                        active: { ...activeObj },
                        archive: { ...archiveObj }
                    },
                    invalidV2Bets: { ...invalidV2Obj},
                    problemBets: { ...problemBetsObj},
                    floatingBetsCount: floatingBetsCount
                }
                return userSummaryObj
            })

        return userSumObj
        
    }
    const processTestSummary = async () => {
        let userSummaryList = []
        let usersWithMissingBets = []
        let usersWithStubBets = []
        let usersWithCorruptedBets = []
        let usersWithInvalidBets = []
        let usersWithUndeletedBets = []
        let usersWithFloatingBets = []


        let totalBets = 0
        let totalParlays = 0
        let totalTeasers = 0
        let totalCorrupted = 0
        let totalUndeleted = 0
        let totalFloating = 0
        let totalAllWays = 0
        let totalInvalidBets = 0
        let totalInvalidParlays = 0
        let totalInvalidTeasers = 0
        let totalMissingBets = 0
        let totalMissingParlays = 0
        let totalMissingTeasers = 0
        let totalStubBets = 0
        let totalStubParlays = 0
        let totalStubTeasers = 0
        let totalUsersV2 = 0

        
        let testUserList = [
            "eP8xzLYtMRONaqEXn33tocGLK8A2", 
            "wjZhKZJe0PSMZgXgpHcqcEyTjSt1", 
            "iCHJX2sOqfRO1GqE4XV7MWc8lpS2", 
            "MjRXkrZzlPNyLP0s3UREqZ1dbgx1", 
            "IcU7twEOmsSxKnn2N7aFH0Lh7JG2", 
            "H6f3gQNpVpgh8DsIeJbXgGoLwea2", 
            "0dAKcUUH8XNRtHIvKnnTv8Yd2l03", 
            "oasP1ZCBvrMYW0hdZwBgppRMEWn1", 
            "BRaR17nr7mhD6yqR33NTrXpoTAq2", 
            "a8pyXnGLNUVaRLNnzINlRa8hfSQ2", 
            "UnEUM3h8ZGON7iwjfkEPn2QeBTE3", 
            "BJlASQbRjyWQljw2xey0yle09sG3", 
            "6VhgdDa6DTg0TpPrszqlsgBSiKI3", 
            "7aFJsOKzJPed6P7kPXgA8YEjd3j1", 
            "8CZavMdoRNgrBETi1c2HHW4fF8Y2",
            "8KcjjPqNoihA17Yju6vkIujv3I22", 
            "8Nlm8UkrOjZgx7uhgUudGzMjwSu2", 
            "Vx3l84ou1MUHyTFwxNmDzTbAiSw1", 
            "JldsRGTmzgS4tYmTSPgmmY1u7PI2", 
            "6VhgdDa6DTg0TpPrszqlsgBSiKI3", 
            "iCHJX2sOqfRO1GqE4XV7MWc8lpS2", 
            "02lUXJC2FThsHHWITPKUOhMctLC3", 
            "0yMqmdNvJuSx6f2oLdeA4JMAkmJ3",
            "FebCzg2oN6fDsg5LPETku5xPkZ52",
            "JpoxSa8plzWeZ2qtIT2tICrlADQ2",
            "0SZN1VFtpGcs0y8fIOlI5TgObj62",
            "3jjDpPiJpUbvE0onu3vkk0BV9903",
            "5hsgehJZskRpe6tK3MbRJIDa3Yf2",
            "7rCSBpHpH9VrH4Z1zK7HV8SyWw73",
            "AriQ5cVhKZSWIX350YQMkfg5ojA2",
            "FVTzlwZ2KMR00WD4p88esLBi1WL2",
            "H6f3gQNpVpgh8DsIeJbXgGoLwea2",
            "HAx7N1WbbtZxSFMw32Q2xtbEaIZ2",
            "IbUjyWV1vvOlGKTx7VIdD2kDhpv2",
            "KQ5Kt1n4uGP04nfsMqlH2Nu7JdA2",
            "BhU8kbHKkyb7NhiAo4Dcoom8T7a2",
            "EeMZJnU1ZbbURPZ7wWFgC1civHx1",
            "H6f3gQNpVpgh8DsIeJbXgGoLwea2",
            "I0PNGL8XJIZacvNtgiRUoVbqLFD3",
            "IcU7twEOmsSxKnn2N7aFH0Lh7JG2",
            "WoJ0SoiWzGNikSecHuLuL2jWJrI3",
            "XI5XRESIvDTh0rPvX5eYtKJvFrH3",
            "XgOLPoyk2VNsFoMSzwHRRklQZyz2",
            "XoWhlSSdVqW0YjX9B8yxRnZ94vE3",
            "Y6CN2beewnX3ytjy5DwFhxCjbBN2",
            "YYpqpr9MY0aSsh8UhY5u8T18zRb2",
            "ek00AVSmI3P1T3ATuQ4zA9rmdoi1",
            "fLofxAi8ISfG3m48tR2G1ruTytf1",
            "L7Hz1rmjhLdoTjLAD2vTR9gtHeQ2",
            "LMjzfk1em1ZyZjXRG9MDRqWpmOY2",
            "MKLJTZSfPsfDkDjEmqNtLhVefgo1",
            "N4n8DCpIa4X4QhAAxacibzIKSIo2",
            "OIG8TQRu0Bgp5UsKKLEFhkKY2wy1",
            "P8WjQVZWE4W7sPZXt3QReu5fxj13",
            "P7tY093QTPTRLQDGFv6UO2jtBpn2",
            "PBMV8A2P0LQR9c0bdfHXOGCyzWf2",
            "PCVN2LmFLEWStTB7i4kisb8dhSw1",
            "PU8huIRxIoOovnG6nSauZRSedhv2",
            "Pn1Eg2Hie0YtDn7mn9zurBnCwb62",
            "Quo1Z8VJrZgkZmhfTWRvhLAPZSJ2",
            "R4jrYwD459SvyKQyvRxYBZWHnQE2",
            "sXgju1jo92TmtdUPacFud7Skztt1",
            "ugTpo01PXyRfddeLbTwJaOVrPk03",
            "vZiqj6pHqzYO37qhc35EgLwzpZH2",
            "xHJvRpR2aDabBVJpLB8oFH2vuWK2",
            "yS3aadyu3VU68J9UncQGa1XmPRk1",
            "z93QGqPPiwSuryWfNzenv4l3y5A2",
            "zIJX7ZnMNtbqnOtFV7oq5MNRb5y1",
            "qOJbPfwZGwO2VlCaSTmVQDl8FOM2",
            "ivz5IP4QqlN2xAg4ExqpJfWz6ud2",
            "C8GrWJHgRuR7UPdbWFUeiN84G383",
            "5fS6mIC3hVfi8u3Nnl4PNiMSoQt1", 
            "Af21TSCcwqOVC6eLqbgsaw92C723",
            "uUDR7LCz3IbeqSty1zkmOwYjYBL2",
            "FcBmcimM30ZqJSSqaxgV6IgIlPO2"
        ]
        
        testUserList = [ ...new Set(testUserList)]
        
        for (const uid of testUserList) {
            await Promise.all([collectUserSummary(uid)]).then(async (response) => {
                const userData = response[0]
                
                await Promise.all([analyzeUserData(userData)]).then((row) => {
                    const userDataRow = row[0]
                    totalBets += userDataRow.totalBets
                    totalParlays += userDataRow.totalParlays
                    totalTeasers += userDataRow.totalTeasers
                    totalCorrupted += userDataRow.totalCorrupted
                    totalUndeleted += userDataRow.totalUndeleted
                    totalFloating += userDataRow.totalFloating
                    totalAllWays += userDataRow.totalAllWays
                    totalInvalidBets += userDataRow.totalInvalidBets
                    totalInvalidParlays += userDataRow.totalInvalidParlays
                    totalInvalidTeasers += userDataRow.totalInvalidTeasers
                    totalMissingBets += userDataRow.missingBetCount
                    totalMissingParlays += userDataRow.missingParlayCount
                    totalMissingTeasers += userDataRow.missingTeaserCount
                    totalStubBets += userDataRow.stubBetCount
                    totalStubParlays += userDataRow.stubParlayCount
                    totalStubTeasers += userDataRow.stubTeaserCount
                    //totalUsersV2 += userDataRow.inV2
                    
                    if (userDataRow.totalMissingBetCount > 0) {
                        usersWithMissingBets.push(userDataRow.userId)
                    }
            
                    if (userDataRow.totalStubBetCount > 0) {
                        usersWithStubBets.push(userDataRow.userId)
                    }
            
                    if (userDataRow.invalidBetCount > 0) {
                        usersWithInvalidBets.push(userDataRow.userId)
                    }
            
                    if (userDataRow.corruptedBetCount > 0) {
                        usersWithCorruptedBets.push(userDataRow.userId)
                    }
            
                    if (userDataRow.undeletedBetCount > 0) {
                        usersWithUndeletedBets.push(userDataRow.userId)
                    }
            
                    if (userDataRow.floatingBetCount > 0) {
                        usersWithFloatingBets.push(userDataRow.userId)
                    }
                    userSummaryList.push(userDataRow)

                })
            })
            
            
        }
        
        setUsersWithMissingBets(() => ([...usersWithMissingBets]))
        setUsersWithStubBets(() => ([...usersWithStubBets]))
        setUsersWithInvalidBets(() => ([...usersWithInvalidBets]))
        setUsersWithCorruptedBets(() => ([...usersWithCorruptedBets]))
        setUsersWithUndeletedBets(() => ([...usersWithUndeletedBets]))
        setUsersWithFloatingBets(() => ([...usersWithFloatingBets]))

        const betsSummaryObj = {
            totalBets: totalBets,
            totalParlays: totalParlays,
            totalTeasers: totalTeasers,
            totalInvalidBets: totalInvalidBets,
            totalInvalidParlays: totalInvalidParlays,
            totalInvalidTeasers: totalInvalidTeasers,
            totalMissingBets: totalMissingBets,
            totalMissingParlays: totalMissingParlays,
            totalMissingTeasers: totalMissingTeasers,
            totalStubBets: totalStubBets,
            totalStubParlays: totalStubParlays,
            totalStubTeasers: totalStubTeasers,
            totalAllWays: totalAllWays,
            totalUsers: testUserList.length,
            totalUsersV2: testUserList.length,
            totalCorrupted: totalCorrupted,
            totalUndeleted: totalUndeleted,
            totalFloating: totalFloating
        }
        setUserSummary((userSum) => [...userSummaryList])
        setBetsSummary(() => ({...betsSummaryObj}))
        
        setLoading(false)
    }

    const processUserSummary = async (usersList) => {
        let userSummaryList = []
        let usersWithMissingBets = []
        let usersWithStubBets = []
        let usersWithCorruptedBets = []
        let usersWithInvalidBets = []
        let usersWithUndeletedBets = []
        let usersWithFloatingBets = []


        let totalBets = 0
        let totalParlays = 0
        let totalTeasers = 0
        let totalCorrupted = 0
        let totalUndeleted = 0
        let totalFloating = 0
        let totalAllWays = 0
        let totalInvalidBets = 0
        let totalInvalidParlays = 0
        let totalInvalidTeasers = 0
        let totalMissingBets = 0
        let totalMissingParlays = 0
        let totalMissingTeasers = 0
        let totalStubBets = 0
        let totalStubParlays = 0
        let totalStubTeasers = 0
        let totalUsersV2 = 0
        
        console.log("START:", new Date().toLocaleString())
        let userPromises = []
        for (const uid of usersList) {
            await Promise.all([collectUserSummary(uid)]).then(async (response) => {
                const userData = response[0]
                
                await Promise.all([analyzeUserData(userData)]).then((row) => {
                    const userDataRow = row[0]
                    totalBets += userDataRow.totalBets
                    totalParlays += userDataRow.totalParlays
                    totalTeasers += userDataRow.totalTeasers
                    totalCorrupted += userDataRow.totalCorrupted
                    totalUndeleted += userDataRow.totalUndeleted
                    totalFloating += userDataRow.totalFloating
                    totalAllWays += userDataRow.totalAllWays
                    totalInvalidBets += userDataRow.totalInvalidBets
                    totalInvalidParlays += userDataRow.totalInvalidParlays
                    totalInvalidTeasers += userDataRow.totalInvalidTeasers
                    totalMissingBets += userDataRow.missingBetCount
                    totalMissingParlays += userDataRow.missingParlayCount
                    totalMissingTeasers += userDataRow.missingTeaserCount
                    totalStubBets += userDataRow.stubBetCount
                    totalStubParlays += userDataRow.stubParlayCount
                    totalStubTeasers += userDataRow.stubTeaserCount
                    //totalUsersV2 += userDataRow.inV2

                    if (userDataRow.totalMissingBetCount > 0) {
                        usersWithMissingBets.push(userDataRow.userId)
                    }
            
                    if (userDataRow.totalStubBetCount > 0) {
                        usersWithStubBets.push(userDataRow.userId)
                    }
            
                    if (userDataRow.invalidBetCount > 0) {
                        usersWithInvalidBets.push(userDataRow.userId)
                    }
            
                    if (userDataRow.corruptedBetCount > 0) {
                        usersWithCorruptedBets.push(userDataRow.userId)
                    }
            
                    if (userDataRow.undeletedBetCount > 0) {
                        usersWithUndeletedBets.push(userDataRow.userId)
                    }
            
                    if (userDataRow.floatingBetCount > 0) {
                        usersWithFloatingBets.push(userDataRow.userId)
                    }
                    userSummaryList.push(userDataRow)

                })
            })
        }
        setUsersWithMissingBets(() => ([...usersWithMissingBets]))
        setUsersWithStubBets(() => ([...usersWithStubBets]))
        setUsersWithInvalidBets(() => ([...usersWithInvalidBets]))
        setUsersWithCorruptedBets(() => ([...usersWithCorruptedBets]))
        setUsersWithUndeletedBets(() => ([...usersWithUndeletedBets]))
        setUsersWithFloatingBets(() => ([...usersWithFloatingBets]))

        const betsSummaryObj = {
            totalBets: totalBets,
            totalParlays: totalParlays,
            totalTeasers: totalTeasers,
            totalInvalidBets: totalInvalidBets,
            totalInvalidParlays: totalInvalidParlays,
            totalInvalidTeasers: totalInvalidTeasers,
            totalMissingBets: totalMissingBets,
            totalMissingParlays: totalMissingParlays,
            totalMissingTeasers: totalMissingTeasers,
            totalStubBets: totalStubBets,
            totalStubParlays: totalStubParlays,
            totalStubTeasers: totalStubTeasers,
            totalAllWays: totalAllWays,
            totalUsers: usersList.length,
            totalUsersV2: totalUsersV2,
            totalCorrupted: totalCorrupted,
            totalUndeleted: totalUndeleted,
            totalFloating: totalFloating,
            totalUsersV2: usersList.length
        }
        setUserSummary((userSum) => [...userSummaryList])
        setBetsSummary(() => ({...betsSummaryObj}))
        console.log("END:", new Date().toLocaleString())
        console.log("User Count", userCount)
        console.log("Missing Bets", usersWithMissingBets)
        console.log("Stub Bets", usersWithStubBets)
        console.log("Invalid Bets", usersWithInvalidBets)
        console.log("Corrupted Bets", usersWithCorruptedBets)
        console.log("Undeleted Bets", usersWithUndeletedBets)
        console.log("Floating Bets", usersWithFloatingBets)
        setLoading(false)
    }

    const analyzeUserData = async (userData) => {
        let userDataRowObj = await Promise.all([getStubBets(userData), getMissingV2Bets(userData)])
            .then((response) => {
                
                let userDataRow = {}
                userDataRow.userId = userData.userId
                userDataRow.invalidBetCount = (userData.invalidV2Bets.bets.count + userData.invalidV2Bets.parlays.count + userData.invalidV2Bets.teasers.count)
                userDataRow.totalMissingBetCount = response[1].betCount + response[1].parlayCount + response[1].teaserCount
                userDataRow.missingBetCount = response[1].betCount
                userDataRow.missingParlayCount = response[1].parlayCount
                userDataRow.missingTeaserCount = response[1].teaserCount
                userDataRow.totalStubBetCount = response[0].betCount + response[0].parlayCount + response[0].teaserCount
                userDataRow.stubBetCount = response[0].betCount
                userDataRow.stubParlayCount = response[0].parlayCount
                userDataRow.stubTeaserCount = response[0].teaserCount
                userDataRow.inV2 = userData.betListBets.inV2 === true ? 1 : 0
                userDataRow.corruptedBetCount = userData.problemBets.corruptedBetsCount
                userDataRow.undeletedBetCount = userData.problemBets.undeletedBetsCount
                userDataRow.floatingBetCount = userData.floatingBetsCount
                userDataRow.totalBets = userData.betListBets.bets.length
                userDataRow.totalParlays = userData.betListBets.parlays.length
                userDataRow.totalTeasers = userData.betListBets.teasers.length
                userDataRow.totalCorrupted = userData.problemBets.corruptedBetsCount
                userDataRow.totalUndeleted = userData.problemBets.undeletedBetsCount
                userDataRow.totalFloating = userData.floatingBetsCount
                userDataRow.totalAllWays =  
                (userData.betListBets.bets.length) + 
                (userData.betListBets.parlays.length > 0 ? userData.betListBets.parlays.reduce((accumulator, object) => { return accumulator + object.bets.length }, 0) : 0) + 
                (userData.betListBets.teasers.length > 0 ? userData.betListBets.teasers.reduce((accumulator, object) => { return accumulator + object.bets.length }, 0) : 0)
                userDataRow.totalInvalidBets = userData.invalidV2Bets.bets.count
                userDataRow.totalInvalidParlays = userData.invalidV2Bets.parlays.count
                userDataRow.totalInvalidTeasers = userData.invalidV2Bets.teasers.count
                return userDataRow
                })
        
        return userDataRowObj
    }

    useEffect(() => {
        const env = process.env.NODE_ENV;
        setIsDev(window.location.hostname === "localhost")
        updateMeta({
            title: "Users Page",
        });
    }, [])

    const handleSubmit = async (e) => {
        if (!userId) {
            updateFlash({
                message: `User Id value is required`,
                success: false,
            });
            return;
        }
        history.push(`/users/${userId}`);
    };

    const getStubBets = (userData) => {
        if (userData) {
            const userV2Bets = [...userData.usersV2Bets.active.bets, ...userData.usersV2Bets.archive.bets]
            const userV2Parlays = [...userData.usersV2Bets.active.parlays, ...userData.usersV2Bets.archive.parlays]
            const userV2Teasers = [...userData.usersV2Bets.active.teasers, ...userData.usersV2Bets.archive.teasers]
            const missingBets = userV2Bets.filter(x => !userData.betListBets.bets.some(function (y) { return y.id === x.id }));
            const missingParlays = userV2Parlays.filter(x => !userData.betListBets.parlays.some(function (y) { return y.id === x.id }));
            const missingTeasers = userV2Teasers.filter(x => !userData.betListBets.teasers.some(function (y) { return y.id === x.id }));

            return {
                bets: missingBets,
                parlays: missingParlays,
                teasers: missingTeasers,
                betCount: missingBets.length,
                parlayCount: missingParlays.length,
                teaserCount: missingTeasers.length
            }
        }

    }

    const getMissingV2Bets = (userData) => {
        if (userData) {
            const userV2Bets = [...userData.usersV2Bets.active.bets, ...userData.usersV2Bets.archive.bets]
            const userV2Parlays = [...userData.usersV2Bets.active.parlays, ...userData.usersV2Bets.archive.parlays]
            const userV2Teasers = [...userData.usersV2Bets.active.teasers, ...userData.usersV2Bets.archive.teasers]
            const missingBets = userData.betListBets.bets.filter(x => !userV2Bets.some(function (y) { return x.id === y.id }));
            const missingParlays = userData.betListBets.parlays.filter(x => !userV2Parlays.some(function (y) { return x.id === y.id }));
            const missingTeasers = userData.betListBets.teasers.filter(x => !userV2Teasers.some(function (y) { return x.id === y.id }));

            return {
                bets: missingBets,
                parlays: missingParlays,
                teasers: missingTeasers,
                betCount: missingBets.length,
                parlayCount: missingParlays.length,
                teaserCount: missingTeasers.length
                
            }

        }
        
    }

    const sortFunc = (by, order) => {
        setSortBy(by);
        setSortOrder(order);
        sortList(userSummary, by, order);
    };

    const sortList = (arr, by, order) => {
        const orderedList = arr.sort((a, b) => (a[by] > b[by] ? 1 : -1));
        order === "asc" ? setUserSummary(orderedList) : setUserSummary(orderedList.reverse());
    };

    const handleLoadUsers = async () => {
        setLoading(true)
        await Promise.all([loadUserList()]).then((response) => {
            const usersLoaded = response[0]
            setOptions(() => [...usersLoaded])
            setUserCount(() => usersLoaded.length)
            if (!isLoading && usersLoaded.length > 0) {
                setUserSummary(() => [])
                setBetsSummary(() => ({
                    totalBets: 0,
                    totalParlays: 0,
                    totalTeasers: 0,
                    totalInvalidBets: 0,
                    totalInvalidParlays: 0,
                    totalInvalidTeasers: 0,
                    totalMissingBets: 0,
                    totalMissingParlays: 0,
                    totalMissingTeasers: 0,
                    totalStubBets: 0,
                    totalStubParlays: 0,
                    totalStubTeasers: 0,
                    totalAllWays: 0,
                    totalUsers: 0,
                    totalUsersV2: 0,
                    totalCorrupted: 0,
                    totalUndeleted: 0,
                    totalFloating: 0
                }
                ))
                setUsersWithInvalidBets(() => [])
                setUsersWithMissingBets(() => [])
                setUsersWithCorruptedBets(() => [])
                setUsersWithUndeletedBets(() => [])
                setUsersWithStubBets(() => [])
                setUsersWithFloatingBets(() => [])
                setBetsSummary((old) => ({...old, totalUsers: usersLoaded.length}))
                if (usersLoaded.length > 0) {
                    processUserSummary(usersLoaded)
                }
                else {
                    setLoading(false)
                    updateFlash({
                        message: `Users list is empty`,
                        success: false,
                    });
                }
            }
            else {
                updateFlash({
                    message: `Error loading users`,
                    success: false,
                });
            }
        })
        
    }

    const handleTestUsers = () => {
        if (!isLoading) {
            setLoading(true)
            setUserSummary(() => [])
            setBetsSummary(() => ({
                totalBets: 0,
                totalParlays: 0,
                totalTeasers: 0,
                totalInvalidBets: 0,
                totalInvalidParlays: 0,
                totalInvalidTeasers: 0,
                totalMissingBets: 0,
                totalMissingParlays: 0,
                totalMissingTeasers: 0,
                totalStubBets: 0,
                totalStubParlays: 0,
                totalStubTeasers: 0,
                totalAllWays: 0,
                totalUsers: options.length,
                totalUsersV2: 0,
                totalCorrupted: 0,
                totalUndeleted: 0,
                totalFloating: 0
            }
            ))
            setUsersWithInvalidBets(() => [])
            setUsersWithMissingBets(() => [])
            setUsersWithCorruptedBets(() => [])
            setUsersWithUndeletedBets(() => [])
            setUsersWithStubBets(() => [])
            setUsersWithFloatingBets(() => [])
            processTestSummary()
           
        }
        
    }


    return (
        <LayoutPage
            subtitle={
                <>
                    <Breadcrumbs
                        location={location}
                        currentPage={
                            <div>
                                <div style={{ display: "flex", fontSize: "1em" }}>
                                    {/* <Dropdown options={options} value={defaultOption} placeholder="Select an option" /> */}

                                    <TextInput
                                        inputData={{
                                            title: "Enter user id",
                                            placeholder: "User ID",
                                            value: userId || "",
                                            type: "text",
                                        }}
                                        style={{ width: '50%' }}
                                        code="name"
                                        onChange={setUserId}
                                    />
                                    <button style={{ fontSize: "1em", marginLeft: ".5em" }}
                                        className="btn-primary"
                                        onClick={handleSubmit}
                                    >
                                        Search
                                    </button>

                                </div>
                            </div>

                        }
                    />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ display: "flex", fontSize: "1em", margin: "0.25em" }}>
                            <button style={{ fontSize: "1em"}}
                                className="btn-primary"
                                onClick={handleTestUsers}
                            >
                                Load Test User Summary
                            </button>
                        </div>
                        <div style={{ display: "flex", fontSize: "1em", margin: "0.25em" }}>
                            <button style={{ fontSize: "1em"}}
                                className="btn-primary"
                                onClick={handleLoadUsers}
                            >
                                Load User Summary
                            </button>
                        </div>
                    </div>
                </>

            }
        >
            {
                isLoading ? (
                    <Spinner />
                ) : userSummary.length === 0 ? <NoResults
                        label={(userId) ? null : "Enter a user id and press search or load user summary"}
                    /> :
                        (
                            <div className="UserPage">
                                <h1 className="adjusted">Users Summary</h1>
                                <BetsSummary 
                                    betsSummary={betsSummary} 
                                    usersWithMissingBets={usersWithMissingBets}
                                    usersWithStubBets={usersWithStubBets}
                                    usersWithInvalidBets={usersWithInvalidBets} 
                                    usersWithCorruptedBets={usersWithCorruptedBets}
                                    usersWithUndeletedBets={usersWithUndeletedBets}
                                    usersWithFloatingBets={usersWithFloatingBets}
                                    history={history}
                                />
                                
                            </div>) 
                        }
        </LayoutPage>
    );
};

export default UsersPage;

/*
*
*Removed Code for users table to try to get whole summary to load
*
*
<h1 className="adjusted">Summary of Bet Data</h1>
<div className="UserPage-table">
<UserTable
        list={userSummary}
        sortBy={sortBy}
        sortOrder={sortOrder}
        sortFunc={sortFunc}
        subject="users"
        headList={headList}
        pathBase="/users"
        idType="userId"
        history={history} 
    />
</div>
*/
