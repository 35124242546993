import React from "react";

export const Circle = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.25 9.75C20.25 15.1348 15.8848 19.5 10.5 19.5C5.11522 19.5 0.75 15.1348 0.75 9.75C0.75 4.36522 5.11522 0 10.5 0C15.8848 0 20.25 4.36522 20.25 9.75ZM2.25 9.75C2.25 14.3063 5.94365 18 10.5 18C15.0563 18 18.75 14.3063 18.75 9.75C18.75 5.19365 15.0563 1.5 10.5 1.5C5.94365 1.5 2.25 5.19365 2.25 9.75Z"
        fill="#999999"
      />
    </svg>
  );
};
