import React from "react";
import "./GridEvent.scss";
import moment from "moment";

export const GridEvent = ({
    e: {
        id,
        sport,
        league,
        startAt,
        updatedAt,
        homeTeam,
        homeTeamId,
        awayTeam,
        awayTeamId,
        jsonoddsId,
        sdioId,
        bovadaId,
        matchingId
    },
    noLabels,
    matchEvent,
}) => {
    return (
        <div className={`GridEvent ${noLabels ? "matchEvent" : ""}`}>
            <div>
                <span className="GridEvent-label">Grid ID</span>
                <span
                    className={`GridEvent-value${
                        matchEvent && matchEvent.id === id ? " isMatch" : ""
                    }`}
                >
                    {id}
                </span>
            </div>
            <div>
                <span className="GridEvent-label">Sport</span>
                <span
                    className={`GridEvent-value${
                        matchEvent && matchEvent.sport === sport
                            ? " isMatch"
                            : ""
                    }`}
                >
                    {sport}
                </span>
            </div>
            <div>
                <span className="GridEvent-label">League</span>
                <span
                    className={`GridEvent-value${
                        matchEvent && matchEvent.league === league
                            ? " isMatch"
                            : ""
                    }`}
                >
                    {league}
                </span>
            </div>
            <div>
                <span className="GridEvent-label">Away Team</span>
                <span
                    className={`GridEvent-value${
                        matchEvent && matchEvent.awayTeam === awayTeam
                            ? " isMatch"
                            : ""
                    }`}
                >
                    {awayTeam}
                </span>
            </div>
            <div>
                <span className="GridEvent-label">Away Team Id</span>
                <span
                    className={`GridEvent-value${
                        matchEvent && matchEvent.awayTeamId === awayTeamId
                            ? " isMatch"
                            : ""
                    }`}
                >
                    {awayTeamId}
                </span>
            </div>
            <div>
                <span className="GridEvent-label">Home Team</span>
                <span
                    className={`GridEvent-value${
                        matchEvent && matchEvent.homeTeam === homeTeam
                            ? " isMatch"
                            : ""
                    }`}
                >
                    {homeTeam}
                </span>
            </div>
            <div>
                <span className="GridEvent-label">Home Team Id</span>
                <span
                    className={`GridEvent-value${
                        matchEvent && matchEvent.homeTeamId === homeTeamId
                            ? " isMatch"
                            : ""
                    }`}
                >
                    {homeTeamId}
                </span>
            </div>
            <div>
                <span className="GridEvent-label">Event Date (UTC)</span>
                <span
                    className={`GridEvent-value${
                        matchEvent && matchEvent.startAt === startAt
                            ? " isMatch"
                            : ""
                    }`}
                >
                    {moment.utc(startAt).format('L h:mm A')}
                </span>
            </div>
            <div>
                <span className="GridEvent-label">Last Update</span>
                <span
                    className={`GridEvent-value${
                        matchEvent && matchEvent.updatedAt === updatedAt
                            ? " isMatch"
                            : ""
                    }`}
                >
                    {moment.unix(updatedAt).format("L h:mm A")}
                </span>
            </div>
            
            <div>
                <span className="GridEvent-label">SDio ID</span>
                <span
                    className={`GridEvent-value${
                        matchEvent && matchEvent.sdioId === sdioId
                            ? " isMatch"
                            : ""
                    }`}
                >
                    {sdioId || "-"}
                </span>
            </div>
            <div>
                <span className="GridEvent-label">Bovada ID</span>
                <span
                    className={`GridEvent-value${
                        matchEvent && matchEvent.bovadaId === bovadaId
                            ? " isMatch"
                            : ""
                    }`}
                >
                    {bovadaId || "-"}
                </span>
            </div>
            <div>
                <span className="GridEvent-label">JsonOdds ID</span>
                <span
                    className={`GridEvent-value${
                        matchEvent && matchEvent.jsonoddsId === jsonoddsId
                            ? " isMatch"
                            : ""
                    }`}
                >
                    {jsonoddsId || "-"}
                </span>
            </div>
            <div>
                <span className="GridEvent-label">Matching ID</span>
                <span
                    className={`GridEvent-value${
                        matchEvent && matchEvent.matchingId === matchingId
                            ? " isMatch"
                            : ""
                    }`}
                >
                    {matchingId || "-"}
                </span>
            </div>
        </div>
    );
};
