import React from "react";
import "./TeamData.scss";

export const TeamFields = ({ fields }) => {
    if (!fields || !fields.length) return <span />;

    return (
        <div className="row TeamData">
            <div
                className="col"
                style={{ padding: "0" }}
            >
                {fields.map((name, j) => {
                    return (
                        <div
                            key={`field-${name}-${j}`}
                        >
                            <div className="TeamData-segment Field">
                                <div>{name}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
