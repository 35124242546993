import React from "react";

export const Analytics = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 5.75C5.5 5.33579 5.16421 5 4.75 5C4.33579 5 4 5.33579 4 5.75V17.75C4 18.7165 4.7835 19.5 5.75 19.5H19.75C20.1642 19.5 20.5 19.1642 20.5 18.75C20.5 18.3358 20.1642 18 19.75 18H5.75C5.61193 18 5.5 17.8881 5.5 17.75V5.75ZM8.75 9.75C8.19772 9.75 7.75 10.1977 7.75 10.75V14.75C7.75 15.3023 8.19772 15.75 8.75 15.75C9.30228 15.75 9.75 15.3023 9.75 14.75V10.75C9.75 10.1977 9.30228 9.75 8.75 9.75ZM13.75 6.75C13.75 6.19772 13.3023 5.75 12.75 5.75C12.1977 5.75 11.75 6.19772 11.75 6.75V14.75C11.75 15.3023 12.1977 15.75 12.75 15.75C13.3023 15.75 13.75 15.3023 13.75 14.75V6.75ZM16.75 7.75C17.3023 7.75 17.75 8.19772 17.75 8.75V14.75C17.75 15.3023 17.3023 15.75 16.75 15.75C16.1977 15.75 15.75 15.3023 15.75 14.75V8.75C15.75 8.19772 16.1977 7.75 16.75 7.75Z"
        fill="#999999"
      />
    </svg>
  );
};
