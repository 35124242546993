import React from "react";

export const CircleChecked = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 19.5C15.3848 19.5 19.75 15.1348 19.75 9.75C19.75 4.36522 15.3848 0 10 0C4.61522 0 0.25 4.36522 0.25 9.75C0.25 15.1348 4.61522 19.5 10 19.5Z"
        fill="#F86D4C"
      />
      <path
        d="M5.56481 9.85591C5.2401 9.47805 4.67602 9.43977 4.30492 9.77039C3.93381 10.101 3.89621 10.6753 4.22093 11.0532L7.34592 14.6895C7.6927 15.0931 8.3054 15.1049 8.66698 14.7151L16.2563 6.5333C16.5948 6.16828 16.5787 5.59291 16.2202 5.24817C15.8617 4.90343 15.2966 4.91987 14.958 5.28489L8.04309 12.7397L5.56481 9.85591Z"
        fill="white"
      />
    </svg>
  );
};
