import React, { Component, createContext } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import firebaseConfig from "../firebaseConfig";

firebase.initializeApp(firebaseConfig);


/**
 * Auth Context
 * 
 */
export const AuthContext = createContext({
    auth: null,
    functions: null,
    storage: null,
    firestore: null,
    user: null,
    isLoading: true,
    setLoading: ()=>{},
    accountType: null,
    accountData: null
});

/**
 * Auth Provider
 * 
 */
class AuthProvider extends Component {
    state = {
        auth: firebase.auth(),
        functions: firebase.functions(),
        storage: firebase.storage().ref(),
        firestore: firebase.firestore(),
        user: null,
        isLoading: true,
        error: null,
        theKey: null,
        accountType: null
    };

    componentDidMount = () => {
        const env = process.env.NODE_ENV;
        if (env.match(/development/i) || window.location.hostname === "localhost") {
            try {
                console.log("Are we getting to firebase")
                this.state.functions.useFunctionsEmulator("http://localhost:5001");
            }
            catch(error) {
                console.log("Error connecting emulator:\n")
            }
        };
        

        this.state.auth.onAuthStateChanged((userAuth) => {
            // on logout
            if (!userAuth)
                this.setState({ user: userAuth, isLoading: false });
            else {
                const that = this;
                this.state.functions.httpsCallable('authorize')().then(function(result) {
                    if (result.data.success) {
                       const accountType = result.data.accountType
                        that.state.functions.httpsCallable('me')({noBets: true}).then(function(result) {
                            if (result.data.success) {
                                that.setState({ user: userAuth, isLoading: false, error: null, theKey: firebaseConfig.apiKey.split('_')[0], accountType: accountType, accountData: result.data.data });
                            }
                        })
                    }
                        
                    else {
                        that.state.functions.httpsCallable('pickemAuthorize')().then(function(result) {
                            if (result.data.success) {
                                const accountType = result.data.accountType
                                that.state.functions.httpsCallable('me')({noBets: true}).then(function(result) {
                                    if (result.data.success) {
                                        console.log("Account Data:", result.data)
                                        that.setState({ user: userAuth, isLoading: false, error: null, theKey: null, accountType: accountType, accountData: result.data.data });
                                    }
                                })
                            }
                            else {
                                that.state.auth.signOut();
                                that.setState({ isLoading: false, error: result.data.message, theKey: null, accountType: null, accountData: null });
                            }
                        })
                        
                    }
                })
            }
        });
    };

    setLoading = (value=true) => {
        this.setState({ isLoading: value })
    }

    clearError = () => {
        this.setState({ error: null })
    }

    render() {
        const { auth, functions, storage, firestore, user, isLoading, error, theKey, accountType, accountData } = this.state;

        return (
            <AuthContext.Provider value={{ 
                auth, 
                functions,
                storage,
                firestore,
                user, 
                isLoading,
                error,
                theKey,
                accountType,
                accountData,
                clearError: this.clearError.bind(this),
                setLoading: this.setLoading.bind(this) 
            }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}
export default AuthProvider;
