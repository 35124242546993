export function getParams(location) {
    let query_raw = new URLSearchParams(location.search).entries(),
        query = {};
    for (var pair of query_raw) query[pair[0]] = pair[1];
    return query;
}

export function priceTransform(price, currency = "$") {
    if (!price) return currency + "0.00";
    // replace non digits with empty char
    let p = ("" + price).replace(/\D+/g, "");
    switch (p.length) {
        case 0:
            return currency + "0.00";
        case 1:
            return currency + "0.0" + p;
        case 2:
            return currency + "0." + p;
    }
    // 3+ digits
    return currency + +p.slice(0, -2) + "." + p.slice(-2);
}

export function heightTransform(height) {
    if (!height) return `0"0'`;
    if (("" + height).indexOf('"') !== -1) return height;
    var feet = ~~(height / 12);

    return `${feet}"${height - feet * 12}'`;
}

export function heightRangeTransform(range) {
    if (!range || range.split("-").length < 2)
        return heightTransform(false) + " - " + heightTransform(false);

    var parts = range.split("-");

    return heightTransform(+parts[0]) + " - " + heightTransform(+parts[1]);
}

export function scaleToContainer(element, maxWidth, callback) {
    let elementBB = element.getBoundingClientRect(),
        // get container width
        availableWidth = elementBB.width,
        // devide available width by maxWidth to get scale ratio
        xScale = availableWidth / maxWidth,
        // count offset
        xOffset = (maxWidth - availableWidth) / 2,
        // get translate percentage
        xTranslate = -((xOffset * 100) / availableWidth) + "%";

    if (callback) callback(xScale, xTranslate, availableWidth);

    return { xScale, xTranslate, availableWidth };
}

export function setTextareaHeight(e) {
    let el = e.currentTarget;
    el.style.height = "1px";
    el.style.height = el.scrollHeight + "px";
}

export function getRandColor() {
    let r, g, b;
    r = Math.floor(Math.random() * 255);
    g = Math.floor(Math.random() * 255);
    b = Math.floor(Math.random() * 255);
    return `rgb(${r}, ${g}, ${b})`;
}

export function capitalize(s) {
    if (typeof s !== "string") return s;
    return s && s[0].toUpperCase() + s.slice(1);
}

export function pluck(array, key) {
    return array.map(item => item[key]);
}

export function serialize(obj) {
    return (
        "?" +
        Object.keys(obj)
            .reduce(function(a, k) {
                a.push(k + "=" + encodeURIComponent(obj[k]));
                return a;
            }, [])
            .join("&")
    );
}

// usage: sleep(sleepTime).then(() => function())
export function sleep(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export function getToken() {
    return document.head.querySelector('meta[name="csrf-token"]').content;
}

export const upper = value => value && value.toUpperCase();

/**
 * Filter Unique objects by id
 *
 * param array
 * return array
 */
export function filterUnique(arr) {
    return arr.filter(
        (item, index, self) => index === self.findIndex(i => i.id === item.id)
    );
}

export function simpleOptions(arr, values = null) {
    const vals = values ? values.split(",").map(i => i && i.trim()) : [];
    return arr.reduce((res, item, i) => {
        res.push({
            id: i + 1,
            name: capitalize(item),
            value: typeof item==='string' ? item.toLowerCase() : item,
            checked: vals.indexOf(item) !== -1
        });
        return res;
    }, []);
}

export function options(arr, values = null, lowerKey = true) {
    const vals = values ? values.map(i => i.id) : [];
    return arr.map((item, i) => {
        return {
            id: item.id,
            name: capitalize(item.name),
            value: (typeof item.name==='string' && lowerKey) ? item.name.toLowerCase() : item.name,
            checked: vals.indexOf(item.id) !== -1
        };
    });
}



export const delayedResponse = (value) => {
    const delay = 300;
    let timer = 0;
    let reject = null;
    const promise = new Promise((resolve, _reject) => {
        reject = _reject;
        timer = setTimeout(resolve, delay, value);
    });
    return {
        get promise() { return promise; },
        cancel() {
            if (timer) {
                clearTimeout(timer);
                timer = 0;
                reject(value);
                reject = null;
            }
        }
    };
}


export const getGameId = (game_name) => {
    const lower_case_game_name = game_name.toLowerCase();

    switch (lower_case_game_name) {
        case "football":
            return 1;
        case "basketball":
            return 2;
        case "baseball":
            return 3;
        case "soccer":
            return 4;
        case "hockey":
            return 5;
        case "mma":
            return 6;
        case "racing":
            return 7;

        default:
            return 9;
    }
};