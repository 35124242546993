import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { PickemLayoutPage } from "layouts/PickemLayoutPage";
import { PickemTable } from "components/common/Table/PickemTable";
import { Link } from "react-router-dom";
import { headList } from "./model";
//import { Breadcrumbs } from "components/common/Breadcrumbs";
//import { NoResults } from "components/common/NoResults";
import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import Checkbox from "components/common/Form/Checkbox/Checkbox";
import { Select } from "components/common/Form/Select/Select";
//import { Select } from "components/common/Form/Select/Select";
import Confirmation from "modals/Confirmation";
import { simpleOptions, options } from "utils/helpers";
import "./PickemSettingsPage.scss";
import { Icon } from "components/common/Icons/Icon";
//const CryptoJS = require("crypto-js");

// PLayer settings: true/false make auto picks for me, logic for default (choose by: random, home team, away team, favorite-home, favorite-away, underdog-home, underdog-away)
// picksetname

//League: non commission show but not change (see commissioner for changes)
//Commish: edit league name, deadline for picks, password, spread on off toggle

//Another section for members (see commissioner if not commish). Show: display name, pickset name, commish: remove button

/**
 * 
 * Time Deadline Options for Compeitions, duplicated in create pickem page
 * 
 */
 const deadlineOptions = simpleOptions([
    "5 minutes before each event start time",
    "5 minutes before first event start time",
    "1 Hour before each event start time",
    "None"
])

const PickemSettingsPage = ({
    history,
    location,
    openModal,
    closeModal,
    match: {
        params: {
            competitionId
        }
    }
    }) => {
    const { functions, accountData, user } = useContext(AuthContext);
    const { updateMeta, competitionData, updateCompetitionData } = useContext(StoreContext);
    const [isLoading, setIsLoading] = useState(true);
    const [competitionNameSetting, setCompetitionNameSetting] = useState(null)
    const [competitionDeadlineSetting, setCompetitionDeadlineSetting] = useState(null)
    const [competitionPassword, setCompetitionPassword] = useState(null)
    const [useSpread, setUseSpread] = useState(false)
    const [isCommissioner, setIsCommissioner] = useState(false)
    const [competitionSettings, setCompetitionSettings] = useState(null)
    const [playerData, setPlayerData] = useState(null)
    const [nameString, setNameString] = useState(null)
    const [competitionName, setCompetitionName] = useState(null)
    const [competitionDescription, setCompetitionDescription] = useState(null)

    useEffect(() => {
        updateMeta({
            title: "Settings",
        });
    }, []);

    const getCompetitionSettings = () => {
        functions.httpsCallable('pickemGetCompetitionData')({
            body: JSON.stringify({
                competitionId: competitionId,
            })
        }).then((result) => {
            console.log("Result of Competition:", result.data)
            if (result.data.success) {
                setCompetitionSettings(() => ({...result.data.data}))
            }
            
        })
    }

    useEffect(() => {
        if (competitionDeadlineSetting && competitionPassword && competitionNameSetting) {
            buildPlayerList()
        }
        
    }, [competitionDeadlineSetting, competitionPassword, competitionNameSetting, isCommissioner, competitionSettings])

    useEffect(() => {
        if (playerData) {
            setIsLoading(false)
        }
    }, [playerData])

    useEffect(() => {
        if (competitionSettings) {
            console.log(competitionSettings)
            console.log('Context Competition Data:', competitionData )
            setCompetitionDeadlineSetting(competitionSettings.competitionData.deadline)
            setCompetitionPassword(competitionSettings.competitionData.password)
            setCompetitionNameSetting(competitionSettings.competitionData.name)
            setUseSpread(competitionSettings.competitionData.useSpread)
            setIsCommissioner(competitionSettings.competitionData.isCommissioner)
            
        }
    }, [competitionSettings])

    useEffect(() => {
        getCompetitionSettings()
    }, [])

    useEffect(() => {
        let name = ""
        if (accountData.firstName && accountData.lastName) {
            name = `${accountData.firstName} ${accountData.lastName}`
        }
        else if (accountData.firstName) {
            name = `${accountData.firstName}`
        }
        else if (accountData.lastName) {
            name = `${accountData.lastName}`
        }
        else if (user.email) {
            name = `${user.email}`
        }
        setNameString(name)
        setCompetitionDescription(competitionData.competitionDescription)
        setCompetitionName(competitionData.competitionName)
    }, [competitionData])

    const buildPlayerList = () => {
        //TODO: hyperlink to players picks, display if non commissioner, 
        const players = []
        console.log("BPL: ", competitionSettings)
        for (const player of competitionSettings.competitorData) {
            let name = "-"
            if (player.firstName && player.lastName) {
                name = `${player.firstName} ${player.lastName}`
            }
            else if (player.firstName) {
                name = `${player.firstName}`
            }
            else if (player.lastName) {
                name = `${player.lastName}`
            }
            const compTools = isCommissioner ? (<button onClick={() => handleRemovePlayer(name, player.picksetName, player.id)} className="btn-secondary">
                <Icon name={"exit"} />
                </button>) : null

            const picksetName = (
                <Link to={{pathname: `/pickem-picks/${competitionId}`}} onClick={() => updateCompetitionData({name: name, picksUserId: player.id, isRandomUser: false})}>
                    {player.picksetName}
                </Link>
            )

            const playerObj = {
                name: name,
                picksetName: picksetName,
                competitionTools: compTools,
                id: player.id
            }

            players.push(playerObj)
        }
        if (competitionSettings.randomCompetitorData) {
            for (const random of competitionSettings.randomCompetitorData) {
                const picksetName = (
                    <Link to={{pathname: `/pickem-picks/${competitionId}`}} onClick={() => updateCompetitionData({name: random.name, picksUserId: random.name, isRandomUser: true})}>
                        {random.picksetName}
                    </Link>
                )
                const playerObj = {
                    name: random.name,
                    picksetName: picksetName
                }

                players.push(playerObj)
            }
        }
        
        setPlayerData(players)
    }

    const handleRemovePlayer = (name, picksetName, uid) => {
        confirm("DeleteCompetitor", name, picksetName, uid)
    }

    const handleUseSpreadChange = () => {
        if (isCommissioner) {
            setUseSpread(!useSpread)
        }
    }

    const handleSaveCompetitionSettings = () => {
        const updateObject = {
            name: competitionNameSetting,
            password: competitionPassword,
            useSpread: useSpread,
            deadline: competitionDeadlineSetting,

        }
        functions.httpsCallable("pickemCompetitionUpdate")({competitionId: competitionId, body: JSON.stringify(updateObject)}).then((result => {
            if (result.data.success) {
                history.push(`/pickem-settings/${competitionId}`)
            }
        }))
    }

    const confirm = (action, name, picksetName, uid) => {
        let message = '';
        let title = ''
        switch (action) {
            case 'DeleteCompetitor':
                title = `Remove ${name}`
                message = `Do you want to remove ${name} and their pickset ${picksetName} from ${competitionName}?`
                break
            case 'DeleteCompetition':
                title = `Delete ${competitionName}`
                message = "Do you want to delete this competition?"
                break
            default:
                title = "Error"
                message = "An Error Has Occurred"
                break
        }
        openModal({
            title: title,
            content: (
                <Confirmation
                    message={message}
                    closeModal={closeModal}
                    buttons={true}
                    onConfirm={() => {
                        closeModal();
                            switch (action) {
                                case 'DeleteCompetitor':
                                    deleteCompetitor(competitionId, uid)
                                    break
                                case 'DeleteCompetition':
                                    //deleteCompetition(compId)
                                    break
                            }
                    }}
                />
            ),
        });
    };

    const deleteCompetitor = (compId, uid) => {
        functions.httpsCallable('pickemCompetitionLeave')({
            competitionId: compId,
            userId: uid
        }).then((result) => {
            console.log("Result of Leave:", result.data)
            if (result.data.success) {
                const newCompetitorData = competitionSettings.competitorData.filter((c) => c.id !== uid)
                const newRandomCompetitorData = competitionSettings.randomCompetitorData.push({})
                setCompetitionSettings(() => ({
                    ...competitionSettings,
                    competitorData: [
                        ...newCompetitorData
                    ]
                }))
            }
            
        })
    }

    return (
        <PickemLayoutPage
            title="Settings"
            name={nameString} 
            competitionName={competitionName} 
            competitionDescription={competitionDescription}
        >
            <div className="PickemSettingsPage">
                {!isLoading && (competitionDeadlineSetting && competitionPassword && competitionNameSetting && playerData) ? 
                <div>
                    <h1>Player Settings</h1>
                    <div className="PickemSettingsPage-segment">
                        <div>Setting</div><div> true</div>
                        <div>Setting</div><div> false</div>
                    </div>
                    <h1>League Settings</h1>
                    <div className="col">
                    <TextInput
                        inputData={{
                            title: "Enter a League Name",
                            placeholder: "League Name",
                            value: competitionNameSetting || "",
                            type: "text",
                            readOnly: isCommissioner ? null : "readOnly"
                        }}
                        code="name"
                        onChange={isCommissioner ? setCompetitionNameSetting : null}
                    />
                    <TextInput
                        inputData={{
                            title: "Enter a League Password",
                            placeholder: "League Password",
                            value: competitionPassword || "",
                            type: "text",
                            readOnly: isCommissioner ? null : "readOnly"
                        }}
                        code="password"
                        onChange={isCommissioner ? setCompetitionPassword : null}
                    /> 
                </div>
                <div>
                    <Checkbox
                        inputData={{
                            id: 1000,
                            checked: useSpread,
                            name: "Should this league use spread to determine game outcomes?"
                        }}
                        onChange={handleUseSpreadChange}
                    />
                </div>
                <div className="col">
                    <p>How long prior to start should picks lock:</p>
                    {isCommissioner ? 
                        <Select
                        placeholder="Select Picks Deadline..."
                        title="Deadline"
                        options={deadlineOptions}
                        onChange={setCompetitionDeadlineSetting}
                        optionKey="value"
                        value={competitionDeadlineSetting}
                        code="deadline"
                    /> : 
                    <TextInput
                        inputData={{
                            title: "Enter a League Password",
                            placeholder: "League Password",
                            value: competitionDeadlineSetting || "",
                            type: "text",
                            readOnly: "readOnly"
                        }}
                        code="deadline"
                        onChange={null}
                    />
                    }
                    
                        {isCommissioner ? <button onClick={handleSaveCompetitionSettings} style={{ fontSize: "1em", marginLeft: "5em" }}
                            className="btn-primary"
                        >
                            Save
                        </button> : null }
                </div>
                <h1>Players</h1>
                <PickemTable
                    subject="players"
                    headList={headList}
                    list={playerData}
                    selected={[]}
                    /> 
            </div>
                : <Spinner></Spinner>}
            </div>
            
        </PickemLayoutPage>
    );
};

export default withDialog(PickemSettingsPage);
