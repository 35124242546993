export const menuItems = [
    { label: "Grid", to: "/events", icon: "calendar", exact: true },
    { label: "Event", to: "/events/search", icon: "search" },
    { label: "Conflicts", to: "/conflicts", icon: "tasks" },
    { label: "Leagues", to: "/leagues", icon: "tasks" },
    { label: "Teams", to: "/teams", icon: "tasks" },
    { label: "Testing", to: "/testing", icon: "tasks" },
    { label: "Users", to: "/users", icon: "search"},
    { label: "Pickem", to: "/pickem", icon: "tasks"}
];
