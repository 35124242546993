import React, { useState } from "react";
import "./MultiSelect.scss";
import Checkbox from "../Checkbox/Checkbox";
import { Icon } from "components/common/Icons/Icon";

const MultiSelect = ({
    onChange = () => {},
    options = [],
    className,
    placeholder,
    defaultValue,
    code,
    value,
    title
}) => {
    const [showDropDown, setShowDropDown] = useState(false);
    const [selectedId, setSelectedId] = useState("");

    const getSelectedOptions = options => {
        return options.filter(({ checked }) => checked);
    };

    const toggleDropDown = () => {
        setShowDropDown(prevVal => !prevVal);
    };

    const onCheckboxCangeInterceptor = (
      value,
      checked,
      option
    ) => {
      options.forEach(({ id }) => id === option.id && setSelectedId(id));
      onChange && onChange(value, checked);
    };

    return (
        <div className={`MultiSelect ${className || ""}`} title={title || null}>
            <input
                className="hidden"
                data-key={code}
                data-default={defaultValue || ""}
                value={value || ""}
                readOnly
            />
            <div className="MultiSelect-input" onClick={toggleDropDown}>
                {!getSelectedOptions(options).length && placeholder}
                {getSelectedOptions(options).map(({ name, value }) => (
                    <span
                        key={name}
                        className="tag MultiSelect-input-selected"
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            onChange(value, false);
                        }}
                    >
                        {name}
                        <Icon name="close"></Icon>
                    </span>
                ))}
                <span className="MultiSelect-input-icon">
                    <Icon name="chevron"></Icon>
                </span>
            </div>
            {showDropDown && (
                <div className="MultiSelect-dropDown">
                    <div
                        className="Options-container"
                    >
                        {options.map(item => (
                            <div
                                className="Options-checkbox"
                                key={item.id}
                            >
                                <Checkbox
                                  onChange={onCheckboxCangeInterceptor}
                                  inputData={item}
                                  focus={item.id === selectedId}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiSelect;
