import React from "react";

export const Proxy = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2429 4H3.73817C3.34069 4 3 4.32177 3 4.73817V7.89905C3 8.29653 3.32177 8.63722 3.73817 8.63722H20.2618C20.6593 8.63722 21 8.31546 21 7.89905V4.71924C20.9811 4.32177 20.6593 4 20.2429 4ZM5.40379 6.95268C5.04416 6.95268 4.74132 6.66877 4.74132 6.29022C4.74132 5.9306 5.02524 5.62776 5.40379 5.62776C5.76341 5.62776 6.06625 5.91167 6.06625 6.29022C6.04732 6.66877 5.76341 6.95268 5.40379 6.95268ZM7.71293 6.95268C7.35331 6.95268 7.05047 6.66877 7.05047 6.29022C7.05047 5.9306 7.33438 5.62776 7.71293 5.62776C8.09148 5.62776 8.37539 5.91167 8.37539 6.29022C8.35647 6.66877 8.07256 6.95268 7.71293 6.95268ZM19.2397 6.53628C19.2397 6.6877 19.1262 6.80126 18.9748 6.80126H9.90852C9.7571 6.80126 9.64353 6.6877 9.64353 6.53628V6.08202C9.64353 5.9306 9.7571 5.81703 9.90852 5.81703H18.9748C19.1262 5.81703 19.2397 5.9306 19.2397 6.08202V6.53628Z"
        fill="#999999"
      />
      <path
        d="M20.2429 9.90536H3.73817C3.34069 9.90536 3 10.2271 3 10.6435V13.8044C3 14.2019 3.32177 14.5426 3.73817 14.5426H20.2618C20.6593 14.5426 21 14.2208 21 13.8044V10.6246C20.9811 10.2271 20.6593 9.90536 20.2429 9.90536ZM5.40379 12.858C5.04416 12.858 4.74132 12.5741 4.74132 12.1956C4.74132 11.836 5.02524 11.5331 5.40379 11.5331C5.76341 11.5331 6.06625 11.817 6.06625 12.1956C6.04732 12.5741 5.76341 12.858 5.40379 12.858ZM7.71293 12.858C7.35331 12.858 7.05047 12.5741 7.05047 12.1956C7.05047 11.836 7.33438 11.5331 7.71293 11.5331C8.09148 11.5331 8.37539 11.817 8.37539 12.1956C8.35647 12.5741 8.07256 12.858 7.71293 12.858ZM19.2397 12.4416C19.2397 12.5931 19.1262 12.7066 18.9748 12.7066H9.90852C9.7571 12.7066 9.64353 12.5931 9.64353 12.4416V11.9874C9.64353 11.836 9.7571 11.7224 9.90852 11.7224H18.9748C19.1262 11.7224 19.2397 11.836 19.2397 11.9874V12.4416Z"
        fill="#999999"
      />
      <path
        d="M20.2429 15.8107H3.73817C3.34069 15.8107 3 16.1325 3 16.5489V19.7098C3 20.1073 3.32177 20.448 3.73817 20.448H20.2618C20.6593 20.448 21 20.1262 21 19.7098V16.5489C20.9811 16.1325 20.6593 15.8107 20.2429 15.8107ZM5.40379 18.7824C5.04416 18.7824 4.74132 18.4984 4.74132 18.1199C4.74132 17.7603 5.02524 17.4574 5.40379 17.4574C5.76341 17.4574 6.06625 17.7413 6.06625 18.1199C6.04732 18.4795 5.76341 18.7824 5.40379 18.7824ZM7.71293 18.7824C7.35331 18.7824 7.05047 18.4984 7.05047 18.1199C7.05047 17.7603 7.33438 17.4574 7.71293 17.4574C8.09148 17.4574 8.37539 17.7413 8.37539 18.1199C8.35647 18.4795 8.07256 18.7824 7.71293 18.7824ZM19.2397 18.347C19.2397 18.4984 19.1262 18.612 18.9748 18.612H9.90852C9.7571 18.612 9.64353 18.4984 9.64353 18.347V17.8928C9.64353 17.7413 9.7571 17.6278 9.90852 17.6278H18.9748C19.1262 17.6278 19.2397 17.7413 19.2397 17.8928V18.347Z"
        fill="#999999"
      />
    </svg>
  );
};
