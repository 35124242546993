import React from "react";

export const ConflictHeader = ({ record }) => {
    
    return (
        <div className="ConflictHeader">
            <h2>{record.name}</h2>
            <p>id: {record.event.id}</p>
            <br/>
            <h4>Event information:</h4>
        </div>
    )
}