import React from "react";

export const Cookout = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7338 6.75009C14.1744 7.57623 13.2276 8.12091 12.1525 8.12091C11.0774 8.12091 10.1303 7.57595 9.57121 6.7501H5C4.86193 6.7501 4.75 6.86203 4.75 7.0001V9.99677C4.75 10.1348 4.86193 10.2468 5 10.2468H5.16011H5.74017H6.32023H16.9524H16.9797L17.0069 10.2487C17.3973 10.2772 19.1493 9.86462 19.6436 6.92959C19.6503 6.88971 19.641 6.85493 19.6091 6.82009C19.5738 6.78136 19.5143 6.75 19.4396 6.75L14.7338 6.75009ZM14.3619 5.2501L19.4396 5.25C20.4189 5.24998 21.305 6.09658 21.1227 7.17867C20.9512 8.19729 20.6387 9.0132 20.25 9.65724V18C20.25 18.9665 19.4665 19.75 18.5 19.75H5C4.0335 19.75 3.25 18.9665 3.25 18V10.3475C3.25 10.3002 3.25438 10.2539 3.26275 10.2091C3.25433 10.1395 3.25 10.0686 3.25 9.99677V7.0001C3.25 6.0336 4.0335 5.2501 5 5.2501H9.79048C10.2261 5.2501 10.5735 5.5036 10.754 5.81463C11.0347 6.29834 11.5564 6.62091 12.1525 6.62091C12.7857 6.62091 13.3352 6.2567 13.6004 5.72293C13.739 5.44402 14.0275 5.25011 14.3619 5.2501H14.3619ZM16.9283 11.7468C17.431 11.7757 18.094 11.6255 18.75 11.2166V18C18.75 18.1381 18.6381 18.25 18.5 18.25H5C4.86193 18.25 4.75 18.1381 4.75 18V11.729C4.83165 11.7407 4.91512 11.7468 5 11.7468H5.16011H5.74017H6.32023H16.9283ZM14.3619 6.7501L14.3619 6.10127L14.3619 6.7501H14.3619ZM7.82426 17.0001H14C14.5523 17.0001 15 16.5524 15 16.0001V14.0001C15 13.4478 14.5523 13.0001 14 13.0001H13.7361C13.5808 13.0001 13.4277 13.0362 13.2889 13.1057L9.61978 14.9402C9.54028 14.98 9.45584 15.0089 9.36868 15.0264L7.66261 15.3676C7.27733 15.4446 7 15.7829 7 16.1758C7 16.6311 7.36904 17.0001 7.82426 17.0001Z"
        fill="white"
      />
    </svg>
  );
};
