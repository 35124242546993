import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { LayoutPage } from "layouts/LayoutPage";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { NoResults } from "components/common/NoResults";
import { EventHeader } from "components/EventPage/EventHeader";
import { EventOdds } from "components/EventPage/EventOdds";
import { EventScores } from "components/EventPage/EventScores";
import { EventMisc } from "components/EventPage/EventMisc";
import { Spinner } from "components/common/Spinner/Spinner";
import moment from "moment-timezone";
import { withDialog } from "components/common/withDialog/withDialog";
import GroupAction from "modals/GroupAction/GroupAction";
import "./EventPage.scss";

const EventPage = ({
    location,
    match: {
        params: { eventId },
    },
    openModal,
    closeModal
}) => {
    const { functions } = useContext(AuthContext);
    const { updateMeta, updateFlash, showSpinner } = useContext(StoreContext);
    const [event, setEvent] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const fetchEvent = () => {
        functions
            .httpsCallable("getGrid")({ ids: eventId })
            .then((res) => {
                setLoading(false);
                if (res.data && res.data.data && res.data.data.length)
                    setEvent(res.data.data[0]);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        updateMeta({
            title: "Event",
        });
        fetchEvent();
    }, []);

    useEffect(() => {
        if (!isLoading) fetchEvent();
    }, [eventId]);

    const submitFetchScores = (e) => {
        showSpinner(true);
        
        runProcess(fetchScores);
    }

    const fetchScores = (e) => {
        if (!event.startAt || event.startAt.indexOf("T") === -1) {
            updateFlash({
                success: false,
                message: `Invalid event's startAt value`,
            });
            showSpinner(false);
        }
        let date;
        if (event.sport === "soccer") {
            date = event.startAt.split("T")[0];
        } else {
            // convert to eastern timezone
            const dateWithZ =
                event.startAt.indexOf("Z") === -1
                    ? event.startAt + "Z"
                    : event.startAt;
            date = moment
                .utc(dateWithZ)
                .tz("America/New_York")
                .format("YYYY-MM-DD");
        }
        functions
            .httpsCallable("refetchScores")({
                ids: event.id,
                date,
            })
            .then((res) => {
                showSpinner(false);
                functions.httpsCallable("unlockProcess")();
                if (res.data.success === true) {
                    updateFlash({
                        message: "Scores data updated successfully",
                        success: true,
                    });
                    fetchEvent();
                } else updateFlash(res.data);
            })
            .catch((err) => {
                showSpinner(false);
                functions.httpsCallable("unlockProcess")();
                updateFlash({
                    success: false,
                    message: `Error occur while requesting event's scores`,
                });
                console.log(err);
            });
    };

    const showActions = (e) => {
        e.preventDefault();

        openModal({
            title: "Update Status",
            content: (
                <GroupAction
                    options={["postponed", "canceled", "scheduled", "error"]}
                    handleSubmit={handleSubmit}
                    selected={[eventId]}
                />
            ),
        });
    };

    const handleSubmit = (e, type) => {
        showSpinner(true);
        
        runProcess(() => {
            handleUpdateStatus(type);
        });
        
        closeModal();
    };

    const handleUpdateStatus = (type) => {
        functions
        .httpsCallable("updateStatus")({ 
            ids: eventId, 
            status: type 
        })
        .then((res) => {
            showSpinner(false);
            functions.httpsCallable("unlockProcess")();
            if (res.data.success===true) {
                updateFlash({
                    message: `Updated successfully`,
                    success: true,
                });
                fetchEvent();
                    
            } else 
                updateFlash(res.data);                
        })
        .catch((err) => {
            showSpinner(false);
            functions.httpsCallable("unlockProcess")();
            updateFlash({
                success: false,
                message: `Error occur while updating events`,
            });
            console.log(err);
        });
    }

    const runProcess = (callback) => {
        functions
        .httpsCallable("lockProcess")()
        .then((res) => {
            if (res.data.success===true) {                
                callback();
            } else {
                showSpinner(false);                
                updateFlash({
                    message: "Database is in use, try again in 20 seconds",
                    success: false,
                });
            } 
        })
        .catch((err) => {
            showSpinner(false);                
            functions.httpsCallable("unlockProcess")();
            updateFlash({
                success: false,
                message: `Error occur while locking process`,
            });
            console.log(err);
        });
    }

    return (
        <LayoutPage
            subtitle={
                <>
                    <Breadcrumbs location={location} currentPage={eventId} />
                    {!!event && (
                        <div style={{ display: "flex" }}>
                            <button
                                className="btn-secondary"
                                style={{ marginRight: "1rem" }}
                                onClick={showActions}
                                title={`Modify selected`}
                            >
                                Update Status
                            </button>
                            <button
                                className="btn-primary"
                                onClick={submitFetchScores}
                            >
                                Refetch Scores
                            </button>
                        </div>
                    )}
                </>
            }
        >
            {event ? (
                <div className="EventPage">
                    <EventHeader event={event} />
                    <EventOdds event={event} />
                    <EventScores event={event} />
                    <EventMisc event={event} />
                </div>
            ) : isLoading ? (
                <Spinner />
            ) : (
                <NoResults />
            )}
        </LayoutPage>
    );
};

export default withDialog(EventPage);
