export const headList = [
    { code: "rank", title: "Rank", mobileHide: false, isNumeric: false },
    { code: "name", title: "Name", mobileHide: false, isNumeric: false },
    { code: "picksetName", title: "Pickset Name", mobileHide: false, isNumeric: false },
    { code: "record", title: "Record", mobileHide: false, isNumeric: false },
    { code: "wins", title: "Wins", mobileHide: false, isNumeric: true },
    { code: "losses", title: "Losses", mobileHide: false, isNumeric: true },
    { code: "winPercent", title: "Win Percentage", mobileHide: false, isNumeric: false },
    { code: "picksRecord", title: "Picks Record", mobileHide: false, isNumeric: false },
    { code: "picksRight", title: "Picks Right", mobileHide: false, isNumeric: true  },
    { code: "picksWrong", title: "Picks Wrong", mobileHide: false, isNumeric: true },
    { code: "picksPush", title: "Picks Push", mobileHide: false, isNumeric: true },
    { code: "pickPercent", title: "Pick Percentage", mobileHide: false, isNumeric: false },
    { code: "tiebreakerRank", title: "Tiebreaker Rank", mobileHide: false, isNumeric: false },
    { code: "picksMadePercent", title: "Picks Made Percentage", mobileHide: false, isNumeric: false },
];