import React from "react";
import { NoResults } from "components/common/NoResults";
import "./EventScores.scss";

export const EventScores = ({ event, isComparison }) => {

    const keys = Object.keys(event).filter(k=>k.indexOf('score')!==-1).sort(); 

    const refs = {
        score_1q: "1st Quarter",
        score_2q: "2nd Quarter",
        score_3q: "3rd Quarter",
        score_4q: "4th Quarter",
        score_1h: "1st Half",
        score_2h: "2nd Half",
        score_1p: "1st Period",
        score_2p: "2nd Period",
        score_3p: "3rd Period",
        score_game: "Game"
    };

    let count = 0;

    return (
        <div className="row EventScores">
            <h1>Scores</h1>
            {keys.reduce((res, k, i) => {
                    const item = event[k];
                    if (!item) 
                        return res;                        
                    res.push(
                        <div key={`${event.id}-scores-${i}-${isComparison}`} className={`col${isComparison ? "" : " col-sm-6"}`}>
                            <h4>{refs[k] || 'OT'}</h4>                                        
                            {Object.keys(item).map((key, j) => {
                                const value = item[key];
                                return (
                                    <div key={`${event.id}-scores-${i}-${key}-${j}-${isComparison}`} className="EventScores-segment">
                                        <div>{key}</div>
                                        <div>{value!==null ? value.toString() : '-'}</div>                                            
                                    </div>
                                );
                            })}
                        </div>
                    )
                    count++;
                    return res;
                }, [])}
                {!count && <NoResults label="Empty" height="100px" />}
        </div>
    )
}


