import React from "react";
import { NoResults } from "components/common/NoResults";
import * as moment from "moment-timezone";
import "./EventMisc.scss";

export const EventMisc = ({ event, isComparison }) => {
    const {
        // exclude scores
        score_1q,
        score_2q,
        score_3q,
        score_4q,
        score_1h,
        score_2h,
        score_1p,
        score_2p,
        score_3p,
        score_game,
        // exclude odds
        odds_1q,
        odds_2q,
        odds_3q,
        odds_4q,
        odds_1h,
        odds_2h,
        odds_1p,
        odds_2p,
        odds_3p,
        odds_game,
        // exclude closing line odds
        closingOdds,

        ...otherData
    } = event;

    const keys = Object.keys(otherData);
    const firstColumnCount = ~~(keys.length / 2);
    const col1Keys = keys.slice(0, firstColumnCount);
    const col2Keys = keys.slice(firstColumnCount);

    return (
        <div className="row EventMisc">
            <h1>Other Data</h1>

            {Object.keys(otherData).length ? (
                <>
                    <RenderColumn keys={col1Keys} object={otherData} isComparison={isComparison} />
                    {!!col2Keys.length && (
                        <RenderColumn keys={col2Keys} object={otherData} isComparison={isComparison} />
                    )}
                </>
            ) : (
                <NoResults label="Empty" height="100px" />
            )}
        </div>
    );
};

const RenderColumn = ({ keys, object, isComparison }) => {
    return (
        <div
            className={`col${isComparison ? "" : " col-sm-6"}`}
            style={{ padding: "0" }}
        >
            {keys.map((key, j) => {
                const value = key === 'startAt' 
                    ? moment.utc(object[key]).format('L h:mm A')
                    : ['updatedAt', 'startAtTimestamp'].indexOf(key)!==-1 // timestamps
                    ? moment.unix(object[key]).format('L h:mm A')
                    : object[key];
                return (
                    <div key={`${object.id}-misc-${key}-${j}-${isComparison}`}>
                        <div className="EventMisc-segment">
                            <div>{key}</div>
                            <div>{value !== null ? value.toString() : "-"}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
