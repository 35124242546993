import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { LayoutPage } from "layouts/LayoutPage";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { NoResults } from "components/common/NoResults";
import { TeamHeader } from "components/TeamPage/TeamHeader";
import { TeamFields } from "components/TeamPage/TeamFields";
import { TeamData } from "components/TeamPage/TeamData";
import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import Confirmation from "modals/Confirmation";
import { getParams } from "utils/helpers";
import "./TeamPage.scss";

const TeamComparePage = ({ location, history, closeModal, openModal }) => {
    const query = location.search ? getParams(location) : { ids: "" };

    const { functions } = useContext(AuthContext);
    const [team1, setTeam1] = useState(null);
    const [team2, setTeam2] = useState(null);
    const { updateMeta, updateFlash, teams, fetchTeams, showSpinner } = useContext(StoreContext);
    const [isLoading, setLoading] = useState(teams.isFetching || false);
    
    const setTeams = () => {
        const ids = query.ids.split(',');
        if (!ids.length) return;

        const t1 = teams.list.find((item) => item.id === ids[0]);
        if (!t1) return;
        setTeam1(t1);

        if (ids.length > 1) {
            const t2 = teams.list.find((item) => item.id === ids[1]);
            if (!t2) return;
            setTeam2(t2);
        }
    };

    useEffect(() => {
        if (teams.isFetching) return;
            setTeams()
    }, [teams.isFetching]);

    useEffect(() => {
        updateMeta({
            title: "Team Compare/Merge",
        });
        setTeams();
    }, []);

    useEffect(() => {
        if (!isLoading) setTeams();
    }, [location.search]);

    const fields = [
        'id',
        'sport',
        'sportId',
        'league',
        'leagueId',
        'name',
        'shortName',
        'officialName',
        'otherName',
        'conference',
        'sdioId',
        'updatedAt',
        'createdAt'
    ]

    
    /**
     *  Confirm merge
     *
     */
    const mergeTeams = (e, fromId, toId) => {
        e.preventDefault();

        let message = `Do you want to remove this team and update mapping?`;

        openModal({
            title: "Confirmation",
            content: (
                <Confirmation
                    message={message}
                    closeModal={closeModal}
                    buttons={true}
                    onConfirm={(e) => {
                        showSpinner(true);
                        
                        runProcess(() => {
                            handleMerge(fromId, toId);
                        });

                        closeModal();
                    }}
                />
            ),
        });
    };

    const runProcess = (callback) => {
        functions
        .httpsCallable("lockProcess")()
        .then((res) => {
            if (res.data.success===true) {                
                callback();
            } else {
                showSpinner(false);                
                updateFlash({
                    message: "Database is in use, try again in 20 seconds",
                    success: false,
                });
            } 
        })
        .catch((err) => {
            showSpinner(false);                
            functions.httpsCallable("unlockProcess")();
            updateFlash({
                success: false,
                message: `Error occur while locking process`,
            });
            console.log(err);
        });
    }

    const handleMerge = (fromId, toId) => {
        functions
        .httpsCallable("mergeTeams")({ fromId, toId })
        .then((res) => {
            showSpinner(false);
            functions.httpsCallable("unlockProcess")();
            if (res.data.success===true) {
                updateFlash({
                    message: `Mapping updated successfully`,
                    success: true,
                });
                // refetch teams list
                fetchTeams();
                // move to teams page
                history.push(`/teams`)
            } else {
                updateFlash(res.data);                                   
            }
        })
        .catch((err) => {
            showSpinner(false);
            functions.httpsCallable("unlockProcess")();
            updateFlash({
                success: false,
                message: `Error occur while processing teams merge`,
            });
            console.log(err);
        });
    }


    return (
        <LayoutPage
            subtitle={
                <Breadcrumbs
                    location={location}
                    currentPage="Comparison"
                />
            }
        >
            {(team1 && team2) ? (
                <div className="TeamComparePage">
                    <div className="row" style={{display: "flex"}}>
                        <div className="TableGap">
                        </div>
                        <div className="Team1">
                            <TeamHeader team={team1} isComparison={1} />
                        </div>
                        <div className="Team2">
                            <TeamHeader team={team2} isComparison={2} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="TableGap">
                            <TeamFields fields={fields} isComparison={1} />
                        </div>
                        <div className="Team1">
                            <TeamData 
                                team={team1} 
                                fields={fields} 
                                isComparison={1} 
                                handleClick={e => mergeTeams(e, team1.id, team2.id)}
                                />
                        </div>
                        <div className="Team2">
                            <TeamData 
                                team={team2} 
                                fields={fields} 
                                isComparison={2} 
                                handleClick={e => mergeTeams(e, team2.id, team1.id)}
                                />
                        </div>
                    </div>
                </div>
            ) : isLoading ? <Spinner /> : <NoResults />}
            
        </LayoutPage>
    );
};

export default withDialog(TeamComparePage);

