import React from "react";

export const Tasks = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.25H19C20.5188 2.25 21.75 3.48122 21.75 5V15C21.75 16.5188 20.5188 17.75 19 17.75H17.75V19.1429C17.75 20.5827 16.5827 21.75 15.1429 21.75H6C3.92893 21.75 2.25 20.0711 2.25 18V8.85714C2.25 7.41726 3.41726 6.25 4.85714 6.25H6.25V5C6.25 3.48122 7.48122 2.25 9 2.25ZM9 17.75H16.25V19.1429C16.25 19.7543 15.7543 20.25 15.1429 20.25H6C4.75736 20.25 3.75 19.2426 3.75 18V8.85714C3.75 8.24568 4.24568 7.75 4.85714 7.75H6.25V15C6.25 16.5188 7.48122 17.75 9 17.75ZM9 3.75C8.30964 3.75 7.75 4.30964 7.75 5V15C7.75 15.6904 8.30964 16.25 9 16.25H19C19.6904 16.25 20.25 15.6904 20.25 15V5C20.25 4.30964 19.6904 3.75 19 3.75H9ZM11.5 11.25H16.5C16.9142 11.25 17.25 11.5858 17.25 12C17.25 12.4142 16.9142 12.75 16.5 12.75H11.5C11.0858 12.75 10.75 12.4142 10.75 12C10.75 11.5858 11.0858 11.25 11.5 11.25ZM11.5 7.25H16.5C16.9142 7.25 17.25 7.58579 17.25 8C17.25 8.41421 16.9142 8.75 16.5 8.75H11.5C11.0858 8.75 10.75 8.41421 10.75 8C10.75 7.58579 11.0858 7.25 11.5 7.25Z"
        fill="white"
      />
    </svg>
  );
};
