import React, {useState} from "react";
import { Select } from "components/common/Form/Select/Select";
import {simpleOptions} from "utils/helpers";
import { withDialog } from "components/common/withDialog/withDialog";
import Confirmation from "modals/Confirmation";
import "./GroupAction.scss";

const GroupAction = ({ handleSubmit, selected, openModal, closeModal, options }) => {
    const [type, setType] = useState("");

    const optionsList = simpleOptions(options);

    /**
     *  Confirm func
     *
     */
    const confirm = (e) => {
        e.preventDefault();

        let message = `Do you want to ${type.toLowerCase()} ${selected.length} event${
            selected.length !== 1 ? "s" : ""
        }?`;

        openModal({
            title: "Confirmation",
            content: (
                <Confirmation
                    message={message}
                    closeModal={closeModal}
                    buttons={type}
                    onConfirm={e => {
                        closeModal();
                        handleSubmit(e, type)
                    }}
                />
            ),
        });
    };

    return (
        <div className="row GroupAction">
            <div className="col">
                <Select
                    placeholder="Select action type" 
                    title="Action type"
                    options={optionsList}
                    onChange={setType}
                    optionKey="value"
                    value={type}
                    code="type"
                />
            </div>
            <div className="col">
                <button className="btn-primary Search-button" onClick={confirm}>Submit</button>
            </div>
        </div>
    )
}

export default withDialog(GroupAction);
