export const tableModal = [
    { code: "id", title: "Grid ID", mobileHide: false, isNumeric: false },
    { code: "sport", title: "Sport", mobileHide: false, isNumeric: false },
    { code: "league", title: "League", mobileHide: false, isNumeric: false, transform: 'uppercase' },
    {
        code: "awayTeam",
        title: "Away Team",
        mobileHide: false,
        isNumeric: false,
    },
    {
        code: "homeTeam",
        title: "Home Team",
        mobileHide: false,
        isNumeric: false,
    },
    {
        code: "startAt",
        title: "Event Date",
        type: "dateString",
        format: "L h:mm A",
        mobileHide: false,
        isNumeric: false,
    },
];