const firebaseConfig = {
  apiKey: "AIzaSyC_7OgoXjlBbNcOkjnzX4rgTdw-IxSHgVo",
  authDomain: "heatr-firebaseproject.firebaseapp.com",
  databaseURL: "https://heatr-firebaseproject.firebaseio.com",
  projectId: "heatr-firebaseproject",
  storageBucket: "heatr-firebaseproject.appspot.com",
  messagingSenderId: "885834334878",
  appId: "1:885834334878:web:56a5a94369ced6ad14718f",
  measurementId: "G-TYE4JM7CM1"
};

export default firebaseConfig;
