import React from "react";
import "./PickemHeader.scss";

export const PickemHeader = ({ title, subtitle, name, competitionName, competitionDescription, isDifferentUser }) => {

  return (
    <div className="PickemHeader">
      <div className="PickemHeader-title">
        {title}
        {subtitle || name ? <div className="PickemHeader-subtitle">{subtitle ? subtitle : <div style={{ display: "flex", fontSize: "1em", margin: 10 }}></div>}
        {name && <div className={`PickemHeader-nameview ${isDifferentUser ? "PickemHeader-disabledName" : null}`}>
          <div className="PickemHeader-name">{name}</div>
          <div className="PickemHeader-competitionName">{competitionName}</div>
          <div className="PickemHeader-competitionDescription">{competitionDescription}</div>
        </div>}</div> : null}
        
        
      </div>
    </div>
  );
};
