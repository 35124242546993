import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { PickemLayoutPage } from "layouts/PickemLayoutPage";
import { PickemTable } from "components/common/Table/PickemTable";
import { headList } from "./model";
//import { Breadcrumbs } from "components/common/Breadcrumbs";
//import { NoResults } from "components/common/NoResults";
import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import Checkbox from "components/common/Form/Checkbox/Checkbox";
import { Select } from "components/common/Form/Select/Select";
//import { Select } from "components/common/Form/Select/Select";
//import Confirmation from "modals/Confirmation";
import { simpleOptions, options } from "utils/helpers";
import "./PickemSchedulePage.scss";
//const CryptoJS = require("crypto-js");

//GetPickset -> know competitor, competition, currentWeek, regular weeks + postseason weeks to build the page

const PickemSchedulePage = ({
    history,
    location,
    match: {
        params: {
            competitionId
        }

    }
    }) => {
    const { functions, accountData, user } = useContext(AuthContext);
    const { updateMeta, competitionData } = useContext(StoreContext);
    const [isLoading, setIsLoading] = useState(true);
    const [scheduleData, setScheduleData] = useState({});
    const [nameString, setNameString] = useState(null)
    const [competitionName, setCompetitionName] = useState(null)
    const [competitionDescription, setCompetitionDescription] = useState(null)

    useEffect(() => {
        updateMeta({
            title: "Schedule",
        });
    }, []);

    useEffect(() => {
        let name = ""
        if (accountData.firstName && accountData.lastName) {
            name = `${accountData.firstName} ${accountData.lastName}`
        }
        else if (accountData.firstName) {
            name = `${accountData.firstName}`
        }
        else if (accountData.lastName) {
            name = `${accountData.lastName}`
        }
        else if (user.email) {
            name = `${user.email}`
        }
        
        setNameString(name)
        setCompetitionDescription(competitionData.competitionDescription)
        setCompetitionName(competitionData.competitionName)
    }, [competitionData])

    useEffect(() => {
        getSchedule()
    }, [])

    const getSchedule = (weekNumber) => {
        functions.httpsCallable('pickemGetSchedulePage')({
            body: JSON.stringify({
                competitionId: competitionId,
            })
        }).then((result) => {
            setIsLoading(false)
            console.log("Schedule Result:", result.data)
            if (result.data.success) {
                setIsLoading(false)
                setScheduleData(() => ({...result.data.data}))
            }
        })
    }

    const createSchedule = () => {
        const schedulePage = []
        if (scheduleData.matchupSchedule) {
            for (const matchup of Object.keys(scheduleData.matchupSchedule)) {
                const weekName = matchup.split("_")
                const header = (
                    <h1>{weekName[0]} {weekName[1].replace(/^0+/, '')}</h1>
                )
                const matchups = scheduleData.matchupSchedule[matchup].map((item) => {
                    return (
                        <div className="PickemSchedulePage-segment">
                            <div>{item.homeCompetitor}</div><div> {item.homeScore}</div>
                            <div>{item.awayCompetitor}</div><div> {item.awayScore}</div>
                        </div>
                    )
                })
                schedulePage.push(header)
                schedulePage.push(matchups)
            }
        }

        return schedulePage
    }

    return (
        <PickemLayoutPage
            title="Schedule"
            name={nameString} 
            competitionName={competitionName} 
            competitionDescription={competitionDescription}
        >
            <div className="PickemSchedulePage">
                {!isLoading ? createSchedule() : <Spinner></Spinner>}
            </div>
            
        </PickemLayoutPage>
    );
};

export default withDialog(PickemSchedulePage);
