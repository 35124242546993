import React from "react";
import "./EventHeader.scss";
import moment from "moment";

export const EventHeader = ({
    event: {
        id,
        sport,
        league,
        homeTeam,
        awayTeam,
        startAt,
        updatedAt,
        status,
        isHidden
    },
    isComparison,
}) => {
    const date = moment(startAt.indexOf('Z')===-1?startAt+'Z':startAt).tz('America/New_York');
    const eventStatus = isHidden ? "error" : status;
    return (
        <div className={`EventHeader${isComparison ? " isComparison" : ""}`}>
            {!!status && ["postponed", "canceled", "fake", "suspended", "error"].indexOf(eventStatus)!==-1 && (
                <div className="EventHeader-status">
                    <span>{eventStatus}</span>
                </div>
            )}
            <div className="EventHeader-top">
                <div>{league}</div>
                <div>
                    {date.format("ddd M/DD/YYYY")}
                </div>
                <div>{date.format("h:mm A ")}EDT</div>
            </div>
            <div className="EventHeader-bottom">
                <h3>{awayTeam}</h3>
                <div className="EventHeader-vsMarker" />
                <h3>{homeTeam}</h3>
            </div>
        </div>
    );
};
