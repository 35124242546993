import React from "react";

export const Dublicate = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 18.125H4.16667C2.90101 18.125 1.875 17.099 1.875 15.8333V7.5C1.875 6.23435 2.90101 5.20833 4.16667 5.20833H5.20833V4.04762C5.20833 2.84771 6.18105 1.875 7.38095 1.875H15C16.7259 1.875 18.125 3.27411 18.125 5V12.619C18.125 13.819 17.1523 14.7917 15.9524 14.7917H14.7917V15.8333C14.7917 17.099 13.7657 18.125 12.5 18.125ZM14.7917 13.5417H15.9524C16.4619 13.5417 16.875 13.1286 16.875 12.619V5C16.875 3.96447 16.0355 3.125 15 3.125H7.38095C6.8714 3.125 6.45833 3.53807 6.45833 4.04762V5.20833H12.5C13.7657 5.20833 14.7917 6.23435 14.7917 7.5V13.5417ZM12.5 16.875C13.0753 16.875 13.5417 16.4086 13.5417 15.8333V7.5C13.5417 6.9247 13.0753 6.45833 12.5 6.45833H4.16667C3.59137 6.45833 3.125 6.9247 3.125 7.5V15.8333C3.125 16.4086 3.59137 16.875 4.16667 16.875H12.5ZM8.95833 9.58333V11.0417H10.4167C10.7618 11.0417 11.0417 11.3215 11.0417 11.6667C11.0417 12.0118 10.7618 12.2917 10.4167 12.2917H8.95833V13.75C8.95833 14.0952 8.67851 14.375 8.33333 14.375C7.98816 14.375 7.70833 14.0952 7.70833 13.75V12.2917H6.25C5.90482 12.2917 5.625 12.0118 5.625 11.6667C5.625 11.3215 5.90482 11.0417 6.25 11.0417H7.70833V9.58333C7.70833 9.23816 7.98816 8.95833 8.33333 8.95833C8.67851 8.95833 8.95833 9.23816 8.95833 9.58333Z"
        fill="white"
      />
    </svg>
  );
};
