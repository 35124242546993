import React from "react";

export const Support = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C8.50034 3.75 6.25 6.50844 6.25 10.5V12V14C6.25 14.4142 5.91421 14.75 5.5 14.75H4.5C2.98122 14.75 1.75 13.5188 1.75 12V11C1.75 9.48122 2.98122 8.25 4.5 8.25H4.97828C5.73541 4.67973 8.32593 2.25 12 2.25C15.6741 2.25 18.2646 4.67973 19.0217 8.25H19.5C21.0188 8.25 22.25 9.48122 22.25 11V12C22.25 13.5188 21.0188 14.75 19.5 14.75H19.1909C18.921 17.4275 17.7108 19.0779 15.2486 20.0901C15.201 21.5672 13.9886 22.75 12.5 22.75C10.9812 22.75 9.75 21.5188 9.75 20C9.75 18.4812 10.9812 17.25 12.5 17.25C13.515 17.25 14.4016 17.7999 14.8781 18.6181C16.9666 17.6967 17.75 16.2504 17.75 13.5V10.5C17.75 6.50844 15.4997 3.75 12 3.75ZM4.75 12V10.5V9.75H4.5C3.80964 9.75 3.25 10.3096 3.25 11V12C3.25 12.6904 3.80964 13.25 4.5 13.25H4.75V12ZM19.5 13.25H19.25V10.5V9.75H19.5C20.1904 9.75 20.75 10.3096 20.75 11V12C20.75 12.6904 20.1904 13.25 19.5 13.25ZM12.5 21.25C11.8096 21.25 11.25 20.6904 11.25 20C11.25 19.3096 11.8096 18.75 12.5 18.75C13.1904 18.75 13.75 19.3096 13.75 20C13.75 20.6904 13.1904 21.25 12.5 21.25Z"
        fill="#999999"
      />
    </svg>
  );
};
