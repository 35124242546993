import React from "react";

export const CreditCardLogo = () => {
  return (
    <svg
      width="39"
      height="23"
      viewBox="0 0 39 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11.4948" cy="11.4948" r="11.4948" fill="#C32B2B" />
      <circle cx="26.9811" cy="11.4953" r="11.4948" fill="#D3923C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2381 19.9909C21.5433 17.8887 22.9898 14.8609 22.9898 11.4952C22.9898 8.12955 21.5433 5.10172 19.2381 2.99951C16.9328 5.10172 15.4863 8.12955 15.4863 11.4952C15.4863 14.8609 16.9328 17.8887 19.2381 19.9909Z"
        fill="#D3603C"
      />
    </svg>
  );
};
