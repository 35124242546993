import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "store/StoreProvider";
import { headList } from "./model";
import { LayoutPage } from "layouts/LayoutPage";
import { NoResults } from "components/common/NoResults";
import { Table } from "components/common/Table/Table";
import { Spinner } from "components/common/Spinner/Spinner";
import { simpleOptions, getParams, serialize } from "utils/helpers";
import { Select } from "components/common/Form/Select/Select";
import "./LeagueListPage.scss";

/**
 * Generate sports options
 *
 */
const sportsOptions = simpleOptions([
    "all",
    "football",
    "basketball",
    "hockey",
    "baseball",
    "soccer",
    "MMA",
]);

/**
 *  Leagues List
 *
 */
const LeagueListPage = ({ history, location }) => {
    const { updateMeta, leagues } = useContext(StoreContext);
    const [list, setList] = useState(leagues.list);
    const [isLoading, setLoading] = useState(leagues.isFetching || false);
    const [sortBy, setSortBy] = useState("index");
    const [sortOrder, setSortOrder] = useState("asc");

    const query = location.search 
        ? getParams(location) 
        : {
            sport: "all"
        };

    const sport = query.sport || "all";

    const handleSportChange = (newValue) => {
        if (newValue === sport)
            return;
        history.push(serialize({ sport: newValue }));
    };

    const sortFunc = (by, order) => {
        setSortBy(by);
        setSortOrder(order);
        sortList(list, by, order);
    };

    const sortList = (arr, by, order) => {
        const orderedList = arr.sort((a, b) => (a[by] > b[by] ? 1 : -1));
        order === "asc" ? setList(orderedList) : setList(orderedList.reverse());
    };

    useEffect(() => {
        updateMeta({
            title: "Leagues",
        });
        if (leagues.list.length)
            sortList(leagues.list, sortBy, sortOrder);
    }, []);

    useEffect(() => {
        sortList(leagues.list, sortBy, sortOrder);
    }, [leagues.list]);

    useEffect(() => {
        setLoading(leagues.isFetching);
    }, [leagues.isFetching]);

    const gotoLeague = (e, obj) => {
        e.preventDefault();
        history.push(`leagues/${obj.id || "create"}`);
    };

    return (
        <LayoutPage
            title={(
                <>
                    Leagues
                    <button className="btn-primary" onClick={e => gotoLeague(e, {})}>Create</button>                
                </>
            )}
        >
            <div className="row FetchSettings">
                <div className="col col-sm-6 col-md-5" style={{float: "right"}}>
                    <Select
                        placeholder="Select sport"
                        title="Sport"
                        options={sportsOptions}
                        onChange={handleSportChange}
                        optionKey="value"
                        value={sport}
                        code="sport"
                    />
                </div>
            </div>
            
            {/* list table */}
            {!isLoading && list && list.length && sport ? (
                <Table
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    sortFunc={sortFunc}
                    subject="league"
                    headList={headList}
                    list={sport==="all" ? list : list.filter(item => item.sport===sport)}
                    pathBase="/leagues"
                    idType="id"
                    history={history}
                    isCustomClick={true}
                    handleCustomClick={gotoLeague}
                    selected={[]}
                />
            ) : (
                !isLoading && (!list || !list.length) && <NoResults />
            )}

            {isLoading && <Spinner />}
        </LayoutPage>
    );
};

export default LeagueListPage;