import React from "react";
import "./Spinner.scss";

export const Spinner = ({ color, message }) => (
    <div className="SpinnerWrap">
        <div>
            <div className="spinner"></div>
            {!!message && (
                <div className="spinner-message">{message}</div>
            )}
        </div>
    </div>
)