import React, { useState, useContext } from "react";
import { BlankPage } from "layouts/BlankPage";
import { AuthContext } from "store/AuthProvider";
//import { Text} from "components/common/Form/TextInput/TextInput";
import "./SelectPathPage.scss";

const SelectPathPage = ({superPathSelect}) => {

    return (
        <BlankPage>
            <div className="SelectPathPage">
                <div className="row SelectPathPage-selectView">
                    <div className="col">
                        <div className="LoginPage-logo">
                            <img src="/images/icon.png" />
                        </div>
                            <button
                                onClick={() => superPathSelect("pickem")}
                                type="submit"
                                value="pickem"
                                className={`btn-primary LoginPage-btn`}
                            >
                                Pickem
                            </button>
                    </div>
                    <div className="col">
                        <div className="LoginPage-logo">
                            <img src="/images/icon.png" />
                        </div>
                            <button
                                onClick={() => superPathSelect("dashboard")}
                                type="submit"
                                value="dashboard"
                                className={`btn-primary LoginPage-btn`}
                            >
                                Dashboard
                            </button>
                    </div>
                    
                    
                </div>
            </div>
        </BlankPage>
    );
};

export default SelectPathPage;
