export const headList = [
        { code: "id", title: "ID", mobileHide: true, isNumeric: false },
        { code: "sport", title: "Sport", mobileHide: true, isNumeric: false },
        { code: "league", title: "League", mobileHide: true, isNumeric: false },
        {
            code: "awayTeam",
            title: "Away Team",
            mobileHide: false,
            isNumeric: false,
        },
        {
            code: "homeTeam",
            title: "Home Team",
            mobileHide: false,
            isNumeric: false,
        },        {
            code: "startAt",
            title: "Start",
            type: "dateString",
            format: "L h:mm A",
            mobileHide: false,
            isNumeric: false,
        }
    ];