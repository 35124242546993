import React from "react";

export const CheckWhite = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3.25H18C19.5188 3.25 20.75 4.48122 20.75 6V18C20.75 19.5188 19.5188 20.75 18 20.75H6C4.48122 20.75 3.25 19.5188 3.25 18V6C3.25 4.48122 4.48122 3.25 6 3.25Z"
        fill="white"
      />
      <path
        d="M6.75257 11.8415C6.38889 11.4259 5.75713 11.3837 5.34149 11.7474C4.92586 12.1111 4.88374 12.7429 5.24742 13.1585L8.74742 17.1585C9.13581 17.6024 9.82203 17.6154 10.227 17.1866L18.727 8.18663C19.1062 7.78511 19.0881 7.1522 18.6866 6.77299C18.2851 6.39378 17.6522 6.41186 17.273 6.81338L9.52824 15.0137L6.75257 11.8415Z"
        fill="#23211F"
      />
    </svg>
  );
};
