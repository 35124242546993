import React, { useState } from "react";
import "./Select.scss";
import Checkbox from "../Checkbox/Checkbox";
import { Icon } from "components/common/Icons/Icon";

export const Select = ({
    onChange = () => {},
    options = [],
    className,
    placeholder,
    optionKey,
    value,
    defaultValue,
    code,
    title
}) => {
    const [showDropDown, setShowDropDown] = useState(false);
    const [selectedId, setSelectedId] = useState(value);

    const getSelectedOption = options => {
        if (!options || !options.length) return;

        const selected = options.find(o => o[optionKey] === selectedId);
        return selected ? selected.name : null;
    };

    const onCheckboxCangeInterceptor = value => {
        onChange && onChange(value);
        setSelectedId(value);
        setShowDropDown(false);
    };

    const toggleDropDown = () => {
        setShowDropDown(() => !showDropDown);
    };

    const currentValue = getSelectedOption(options);

    return (
        <div className={`Select ${className || ""}`} title={title || null}>
            <input
                className="hidden"
                data-key={code}
                data-default={defaultValue || ""}
                value={value || ""}
                readOnly
            />
            <div
                className={`Select-input${currentValue ? " has-value" : ""}`}
                onClick={toggleDropDown}
            >
                {currentValue || placeholder}
                <span className="Select-input-icon btn-empty">
                    <Icon name="chevron"></Icon>
                </span>
            </div>
            {showDropDown && (
                <div className="Select-dropDown">
                    <div
                        className="Options-container"
                    >
                        {options.map(item => (
                            <div
                                className="Options-checkbox"
                                key={item[optionKey]}
                            >
                                <Checkbox
                                    className="simple-select"
                                    inputData={{
                                        ...item,
                                        checked: item[optionKey] === selectedId
                                    }}
                                    onChange={e =>
                                        onCheckboxCangeInterceptor(
                                            item[optionKey]
                                        )
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
