import React from "react";
import "./EventOdds.scss";
import { NoResults } from "components/common/NoResults";

export const EventOdds = ({ event, isComparison }) => {

    const keys = [
        "odds_1q",
        "odds_2q",
        "odds_3q",
        "odds_4q",
        "odds_1h",
        "odds_2h",
        "odds_1p",
        "odds_2p",
        "odds_3p",
        "odds_game"
    ];

    const refs = {
        odds_1q: "1st Quarter",
        odds_2q: "2nd Quarter",
        odds_3q: "3rd Quarter",
        odds_4q: "4th Quarter",
        odds_1h: "1st Half",
        odds_2h: "2nd Half",
        odds_1p: "1st Period",
        odds_2p: "2nd Period",
        odds_3p: "3rd Period",
        odds_game: "Game"
    };

    const RenderOdds = ({ d, id, label }) => {
        if (!d) {
            return <NoResults label="Empty" height="100px" />
        }
        return (
            <div className="row">
            {
                keys.reduce((res, k, i) => {
                    const item = d[k];
                    if (!item) 
                        return res;                        
                    res.push(
                        <div key={`${event.id}-${k}-${isComparison}`} className="col">
                            <h4>{refs[k]}</h4>                                        
                            {Object.keys(item).map((key, j) => {
                                return (
                                    <div key={`${id}-${label}-odds-${i}-${key}-${j}-${isComparison}`} className="EventOdds-segment">
                                        <div>{key}</div>
                                        <div>{item[key]}</div>                                            
                                    </div>
                                );
                            })}
                        </div>
                    )
                    return res;
                }, [])
            }
            </div> 
        )
    }

    return (
        <div className="row EventOdds">
            <h1 className={isComparison?"":"adjusted"}>Odds</h1>

            <div className={`col${isComparison ? "" : " col-sm-6"}`}>
                <h3>Current</h3>
                <RenderOdds id={event.id} d={event} label="reg" />
            </div>

            <div className={`col${isComparison ? "" : " col-sm-6"}`}>
                <h3>Closing Line</h3>
                <RenderOdds id={event.id} d={event.closingOdds} label="closing" />
            </div>
        </div>
    )
}


