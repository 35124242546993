import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { headList } from "./model";
import { LayoutPage } from "layouts/LayoutPage";
import { withDialog } from "components/common/withDialog/withDialog";
import { NoResults } from "components/common/NoResults";
import { Spinner } from "components/common/Spinner/Spinner";
import Confirmation from "modals/Confirmation";
import { Table, SelectModeButton } from "components/common/Table/Table";
import { Icon } from "components/common/Icons/Icon";

/**
 *  Conflicts List
 *
 */
const ConflictListPage = ({ history, openModal, closeModal }) => {
    const { functions } = useContext(AuthContext);
    const { updateMeta, updateFlash, showSpinner } = useContext(StoreContext);
    const [list, setList] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [selected, setSelected] = useState([]);
    const [isSelectMode, toggleSelectMode] = useState(false);
    const [sortBy, setSortBy] = useState("id");
    const [sortOrder, setSortOrder] = useState("asc");

    const sortFunc = (by, order) => {
        setSortBy(by);
        setSortOrder(order);
        sortList(list, by, order);
    };

    const sortList = (arr, by, order) => {
        const orderedList = arr.sort((a, b) => (a[by] > b[by] ? 1 : -1));
        order === "asc" ? setList(orderedList) : setList(orderedList.reverse());
    };

    useEffect(() => {
        updateMeta({
            title: "Conflicts",
        });
        setLoading(true);
        functions
            .httpsCallable("getConflicts")()
            .then((res) => {
                setLoading(false);
                sortList(res.data.data, sortBy, sortOrder);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    }, []);

    const handleSelect = (ids, toggle) => {
        if (toggle) {
            const index = selected.indexOf(ids);
            index === -1
                ? setSelected(selected.concat(ids))
                : setSelected(
                      [].concat(
                          selected.slice(0, index),
                          selected.slice(index + 1)
                      )
                  );
            return;
        } else {
            if (!ids.length) {
                setSelected([]);
                return;
            }
            const newRawSelected = selected.concat(ids);
            // filter out duplicates
            setSelected(
                newRawSelected.filter(
                    (elem, pos, arr) => arr.indexOf(elem) == pos
                )
            );
        }
    };

    let numSelected = selected.length;

    const Subtitle = () => {
        return isSelectMode && numSelected ? (
            <div className="TableSubtitle">
                <div>
                    <span onClick={(e) => handleSelect([])}>
                        <Icon name="close" />
                    </span>{" "}
                    {selected.length} conflict(s) selected
                </div>
                <button
                    className="btn-secondary"
                    style={{ marginRight: "1em" }}
                    onClick={(e) =>
                        confirm(
                            e,
                            "canceled",
                            `Do you want to mark ${selected.length} event(s) as canceled (hide from app)?`,
                            `${selected.length} event(s) set as canceled`
                        )
                    }
                    title={`Try to remove event(s) or set as canceled(hide from app)`}
                >
                    Canceled Event(s)
                </button>
                <button
                    className="btn-secondary"
                    onClick={(e) =>
                        confirm(
                            e,
                            "ignore",
                            `Do you want to ignore ${selected.length} conflict(s) and clear from the list?`,
                            `Cleared ${selected.length} conflict(s)`
                        )
                    }
                    title={`Ignore selected conflict(s) and remove from the list`}
                >
                    Ignore Conflict(s)
                </button>
            </div>
        ) : null;
    };

    /**
     *  Confirm Resolve conflict(s)
     *
     */
    const confirm = (e, type, actionMessage, successMessage) => {
        e.preventDefault();

        openModal({
            title: "Confirmation",
            content: (
                <Confirmation
                    message={actionMessage}
                    closeModal={closeModal}
                    buttons={true}
                    onConfirm={(e) => {
                        showSpinner(true);

                        runProcess(() => {
                            functions
                            .httpsCallable("resolveConflicts")({
                                type: type,
                                ids: selected.join(","),
                            })
                            .then((res) => {
                                showSpinner(false);
                                functions.httpsCallable("unlockProcess")();
                                if (res.data.success === true) {
                                    updateFlash({
                                        message: successMessage,
                                        success: true,
                                    });
                                    setSelected([]);
                                    toggleSelectMode(false);
                                } else updateFlash(res.data);
                                console.log('log', res.data.log);
                            })
                            .catch((err) => {
                                showSpinner(false);
                                functions.httpsCallable("unlockProcess")();
                                updateFlash({
                                    success: false,
                                    message: `Error occur while resolving conflicts`,
                                });
                                console.log(err);
                            });
                        });

                        closeModal();
                    }}
                />
            ),
        });
    };


    const runProcess = (callback) => {
        functions
        .httpsCallable("lockProcess")()
        .then((res) => {
            if (res.data.success===true) {                
                callback();
            } else {
                showSpinner(false);                
                updateFlash({
                    message: "Database is in use, try again in 20 seconds",
                    success: false,
                });
            } 
        })
        .catch((err) => {
            showSpinner(false);                
            functions.httpsCallable("unlockProcess")();
            updateFlash({
                success: false,
                message: `Error occur while locking process`,
            });
            console.log(err);
        });
    }

    return (
        <LayoutPage
            title={isSelectMode && numSelected ? null : "Conflicts"}
            subtitle={<Subtitle />}
        >
            {/* list control bar */}
            <div className="control-bar">
                <span></span>
                {list && !!list.length && (
                    <SelectModeButton
                        action={() => toggleSelectMode(!isSelectMode)}
                        isActive={isSelectMode}
                    />
                )}
            </div>

            {/* list table */}
            {!isLoading && list && list.length ? (
                <Table
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    sortFunc={sortFunc}
                    subject="conflict"
                    headList={headList}
                    isSelectMode={isSelectMode}
                    selected={selected}
                    list={list}
                    selectFunc={handleSelect}
                    pathBase="/conflicts"
                    idType="id"
                    history={history}
                />
            ) : (
                !isLoading && (!list || !list.length) && <NoResults />
            )}

            {isLoading && <Spinner />}
        </LayoutPage>
    );
};

export default withDialog(ConflictListPage);
