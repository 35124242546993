export const headList = [
    { code: "idCode", title: "League ID", mobileHide: false, isNumeric: false },
    { code: "name", title: "Display Name", mobileHide: false, isNumeric: false },
    { code: "shortName", title: "Short Name", mobileHide: false, isNumeric: false },
    { code: "sportId", title: "Sport ID", mobileHide: false, isNumeric: false },
    { code: "sport", title: "Sport", mobileHide: false, isNumeric: false },
    { code: "Odds", title: "Odds Providers", mobileHide: true, isNumeric: false, transformClass: "uppercase", type:"withTag", tagGroup: "odds" },
    { code: "Scores", title: "Score Providers", mobileHide: true, isNumeric: false, transformClass: "uppercase", type:"withTag", tagGroup: "scores" },
    {
        code: "version",
        title: "App Version",
        mobileHide: false,
        isNumeric: false,
    },
];
