import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { LayoutPage } from "layouts/LayoutPage";
import { withDialog } from "components/common/withDialog/withDialog";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { DuplicateSearch } from "modals/DuplicateSearch/DuplicateSearch";
import { NoResults } from "components/common/NoResults";
import Confirmation from "modals/Confirmation"
import { GridEvent } from "components/ConflictPage/GridEvent";
import { ConflictHeader } from "components/ConflictPage/ConflictHeader";
import { MergeControl } from "components/ConflictPage/MergeControl";
import { Spinner } from "components/common/Spinner/Spinner";
import * as moment from "moment-timezone";
import { Select } from "components/common/Form/Select/Select";
import "./ConflictPage.scss";


const optionsList = [
    {
        id: 1,
        name: "Ignore conflict",
        value: "ignore",
        checked: false
    },
    {
        id: 2,
        name: "Mark event as canceled",
        value: "canceled",
        checked: false
    },
    {
        id: 3,
        name: "Merge with duplicate / real event",
        value: "merge",
        checked: false
    }
]

const ConflictPage = ({
    openModal,
    closeModal,
    history,
    location,
    match: {
        params: { conflictId },
    },
}) => {
    const { functions } = useContext(AuthContext);
    const { updateMeta, updateFlash, showSpinner } = useContext(StoreContext);
    const [conflict, setConflict] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [type, setType] = useState("merge");

    const fetchConflict = () => {
        setLoading(true);
        functions.httpsCallable('getConflictById')({ conflictId })
            .then((res) => {
                setLoading(false);
                setConflict(res.data.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        updateMeta({
            title: "Conflict Record",
        });
        fetchConflict();
    }, []);

    useEffect(() => {
        if (!isLoading) fetchConflict();
    }, [conflictId]);

    const confirm = (e, params, message) => {
        e.preventDefault();
        openModal({
            title: "Confirmation",
            content: (
                <Confirmation
                    message={message}
                    closeModal={closeModal}
                    buttons={true}
                    onConfirm={() => {                    
                        showSpinner(true);
                        
                        runProcess(() => {
                            functions
                            .httpsCallable("resolveConflicts")(params)
                            .then((res) => {
                                showSpinner(false);
                                functions.httpsCallable("unlockProcess")();
                                if (res.data.success === true) {
                                    updateFlash({
                                        message: "Conflict resolved successfully",
                                        success: true,
                                    });
                                    history.push(`/conflicts`);                        
                                } else updateFlash(res.data);
                                console.log('log', res.data.log);
                            })
                            .catch((err) => {
                                showSpinner(false);
                                functions.httpsCallable("unlockProcess")();
                                updateFlash({
                                    success: false,
                                    message: `Error occur while resolving conflict`,
                                });
                                console.log(err);
                            });
                        });
                                                
                        closeModal();
                    }}
                />
            ),
        });
    }

    const runProcess = (callback) => {
        functions
        .httpsCallable("lockProcess")()
        .then((res) => {
            if (res.data.success===true) {                
                callback();
            } else {
                showSpinner(false);                
                updateFlash({
                    message: "Database is in use, try again in 20 seconds",
                    success: false,
                });
            } 
        })
        .catch((err) => {
            showSpinner(false);                
            functions.httpsCallable("unlockProcess")();
            updateFlash({
                success: false,
                message: `Error occur while locking process`,
            });
            console.log(err);
        });
    }

    const showList = (conflict) => {
        const options = {
            size: "md",
        };
        openModal(
            {
                title: "Duplicate search for",
                subtitle: conflict.event ? (
                    <>
                        <br/>
                        <h4>{conflict.name}</h4>
                        <p>{moment(conflict.event.startAt).subtract(5, 'hours').format('L h:mm A')} ET</p>
                    </>
                ) : null,
                content: (
                    <DuplicateSearch
                        functions={functions}
                        event={conflict.event}
                        closeModal={closeModal}
                        buttons={true}
                        selected={selected}
                        onSelect={(newSelected) => {
                            setSelected(newSelected);
                            closeModal();
                        }}
                    />
                ),
            },
            options
        );
    };

    return (
        <LayoutPage
            subtitle={
                <Breadcrumbs
                    location={location}
                    currentPage="Conflict Record"
                />
            }
        >
            {conflict ? (
                <>
                    <div className="row">
                        <div className="col col-sm-6">
                            <ConflictHeader record={conflict} />
                        </div>
                        <div className="col col-sm-6">
                            <div className="row">
                                <div className="col">
                                    <span>Select how you want to resolve this conflict</span>
                                    <Select
                                        placeholder="Select action type" 
                                        title="Select how you want to resolve this conflict"
                                        options={optionsList}
                                        onChange={setType}
                                        optionKey="value"
                                        value={type}
                                        code="type"
                                    />                                    
                                </div>
                                {type!=="merge" && (
                                    <div className="col">
                                        <button 
                                            className="btn-primary Search-button" 
                                            onClick={e=> {
                                                confirm(
                                                    e, 
                                                    { type, ids: conflict.id },
                                                    `Do you want to${type==='ignore'? " ignore this conflict?" : " mark this event as canceled?"}`
                                                )
                                            }}
                                        >
                                            Submit
                                        </button>
                                    </div>  
                                )}
                            </div>                            
                        </div>
                    </div>
                    <div className="row ConflictHolder">
                        <div className={`col ConflictLeft${
                                selected ? " merging" : ""
                            }`}>
                            <GridEvent e={conflict.event} />
                            {!!(type==="merge" && selected && conflict.event) && (
                                <MergeControl  
                                    currentEventId={conflict.event.id}
                                    secondEventId={selected.id}    
                                    handleClick={(e, correct, wrong) => {
                                        confirm(
                                            e, 
                                            { 
                                                type: "merge", 
                                                id: conflict.id, 
                                                correct, 
                                                wrong 
                                            },
                                            `Merge events, where${correct===conflict.event.id ? " left " : " right "}side is the correct event`
                                        )
                                    }} 
                                />
                            )}
                        </div>
                        {type==="merge" && (
                            <div
                                className={`col ConflictRight clickable${
                                    !selected ? " PlaceHolder" : " merging"
                                }`}
                                onClick={e => showList(conflict)}
                            >
                                {selected ? (
                                    <GridEvent
                                        matchEvent={conflict.event}
                                        noLabels={true}
                                        e={selected}
                                    />
                                ) : (
                                    <div>Click to search duplicate / real event</div>
                                )}
                            </div>
                        )}
                    </div>
                </>
            ) : (
                !isLoading && !conflict && <NoResults />
            )}

            {isLoading && <Spinner />}
        </LayoutPage>
    );
};

export default withDialog(ConflictPage);

