import React, { Component } from "react";
import "./Confirmation.scss";

class Confirmation extends Component {

    render() {
        const {
            closeModal,
            onConfirm,
            message,
            buttons,
            children
        } = this.props;

        return (
            <div className="Confirmation">
                <div className="Confirmation-content">
                    <p>{message}</p>

                    {children}
                </div>

                {buttons ? (
                    <div className="Confirmation-buttons">
                        <button onClick={closeModal} id="cancelBtn">Cancel</button>
                        <button className="btn-primary" onClick={onConfirm} id="confirmBtn">Confirm</button>
                    </div>
                ) : null}
            </div>
        );
    }
}

/**
 * Set Default Props
 *
 */
Confirmation.defaultProps = {
    buttons: true,
    children: null
};

export default Confirmation;
