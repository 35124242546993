import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from "store/StoreProvider";
import "./FlashMessage.scss";

export const FlashMessage = () => {
    const {
        flash: { message, success },
        updateFlash,
    } = useContext(StoreContext);
    const [show, setShow] = useState(false);
    const [isHideMessage, hideMessage] = useState(false);

    const delay = 3000;

    useEffect(() => {
        if (show) {
            window.clearTimeout(window.flashTimer);
            hideMessage(true);
            setShow(false);                      
        }
        message && showMessage();
    }, [message]);

    const showMessage = () => {
        window.flashTimer = window.setTimeout(() => {
            setShow(true);
            hideMessage(false);
            window.clearTimeout(window.flashTimer);
            window.flashTimer = window.setTimeout(function () {
                window.clearTimeout(window.flashTimer);
                setShow(false);
                hideMessage(true);
                updateFlash(null);
            }, delay);
        }, 500);
    };

    const classList = ["FlashMessage"];

    if (success === true) classList.push("success");
    else if (success === false) classList.push("error");

    show ? classList.push("active") : classList.push("isHidden");

    return (
        <div className={classList.join(" ")}>
            <div>{isHideMessage ? "" : message}</div>
        </div>
    );
};
