import React from "react";
import { Icon } from "components/common/Icons/Icon";
import "./MergeControl.scss";

export const MergeControl = ({ handleClick, currentEventId, secondEventId }) => {
    return (
        <div className="MergeControl">
            <div className="MergeControl-rightArrow" onClick={e => handleClick(e, secondEventId, currentEventId)}>
                <Icon name="chevron" />
            </div>
            <div className="MergeControl-leftArrow" onClick={e => handleClick(e, currentEventId, secondEventId)}>
                <Icon name="chevron" />
            </div>
        </div>
    )
}